import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack } from "@mui/system";
import { CheckOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import { Tile } from "_global/components/Containers";
import {
  LogoutButton,
  PageLayout,
  SendButton,
  StyledLink,
  StyledSuccessMessage,
} from "./VerifyEmailPage.elements";
import { useResendVerifyEmail } from "features/Auth/hooks/useResendVerifyEmail";
import { WAYFINDER_SUPPORT_EMAIL } from "../constants";

const TITLE = "Verify your email";
const DESCRIPTION = "Let's get synced up";

export const VerifyEmailPage = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState(false);

  const fetchMostRecentToken = React.useCallback(async () => {
    if (!user) return;
    setLoading(true);
    try {
      await getAccessTokenSilently({
        cacheMode: "off", // this is the important part -- we need to fetch from the auth server and not the cache
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user, getAccessTokenSilently]);

  React.useEffect(() => {
    // on page reload, we need to fetch the most recent auth0 token to unblock the user
    fetchMostRecentToken();
  }, []);

  const {
    isSuccess,
    isFetching,
    refetch: sendRequest,
  } = useResendVerifyEmail();

  if (!user) return null;

  if (loading)
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "600px", color: "#C9C9C9" }}
      >
        <CircularProgress color="inherit" />
      </Stack>
    );

  const email = user?.email || "";

  return (
    <PageLayout.Container>
      <PageLayout.Header title={TITLE} description={DESCRIPTION} />
      <PageLayout.MainContentContainer>
        <Tile
          flexGrow={1}
          mt="20px"
          gap="30px"
          alignItems="center"
          textAlign="center"
          justifyContent="space-evenly"
        >
          {/* MAIN TEXT */}
          <Stack gap="20px">
            <Box>
              {"We've sent an email to "}
              <Box sx={{ fontWeight: 400 }} component="span">
                {email}
              </Box>
              {
                " to verify your identity and activate your account. The link in your email will expire in 24 hours."
              }
            </Box>
            <Box>
              If you don't see it, you may need to check your spam folder.
            </Box>
          </Stack>
          {/* BUTTON AND SUCCESS MESSAGE */}
          <Stack gap="20px">
            <SendButton
              label={isSuccess ? "Send Again" : "Resend Email"}
              onClick={sendRequest}
              disabled={isFetching}
            />
            {isSuccess && (
              <StyledSuccessMessage>
                {!isFetching && (
                  <>
                    <CheckOutlined fontSize="small" /> A new verification email
                    was sent.
                  </>
                )}
              </StyledSuccessMessage>
            )}
          </Stack>
          {/* TROUBLESHOOTING SECTION */}
          <Stack gap="15px">
            <Box display="flex" gap="14px">
              <Box>Trouble Verifying?</Box>
              <StyledLink
                href={`mailto:${WAYFINDER_SUPPORT_EMAIL}?subject=Verify%20Email%20Issue`}
              >
                Contact Us
              </StyledLink>
            </Box>
            <LogoutButton />
          </Stack>
        </Tile>
      </PageLayout.MainContentContainer>
    </PageLayout.Container>
  );
};
