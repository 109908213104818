/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled } from "@mui/system";

export const List = styled("ul")(({ theme }) => ({
  listStyle: "none",
  padding: 0,
  margin: 0,
}));

export const ListItem = styled("li")(({ theme }) => ({
  padding: 0,
  margin: 0,
}));
