import * as React from "react";
import { Link } from "react-router-dom";
import { Box, styled } from "@mui/system";

import { ReactComponent as ArrowRightIcon } from "_global/assets/icons/arrow-right-link-icon.svg";
import { Image } from "_global/components/Images";
import type { CTALinkProps } from "./types";

type ThumbnailProps = {
  src: string;
  alt: string;
};

const Thumbnail = (props: ThumbnailProps) => (
  <Image {...props} width="50px" height="50px" borderRadius="50%" />
);

const StyledLinkContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  fontSize: "16px",
  fontWeight: 300,
  letterSpacing: "0.5px",
  "&:hover": {
    fontSize: "15.5px",
    letterSpacing: "-0.15px",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "& svg": {
      opacity: 1
    },
  },
  "a": {
    textDecoration: "none",
    color: "unset",
  },
  "svg": {
    opacity: 0,
    marginBottom: "-7px",
    fill: theme.palette.primary.main,
    transition: "opacity 35ms ease-out",
  },
}));

type LinkInnerProps = Pick<CTALinkProps, "text" | "thumbnailSrc" | "thumbnailAlt">;

const LinkInnerContent = ({ text, thumbnailSrc, thumbnailAlt }: LinkInnerProps) => (
  <Box display="flex" alignItems="center" gap="22px">
    <Box minWidth="min-content">
      <Thumbnail src={thumbnailSrc} alt={thumbnailAlt || text} />
    </Box>
    <Box display="flex" alignItems="center" gap="7px">
      <Box component="span">
        {text}
      </Box>
      <ArrowRightIcon height="22px" width="22px" />
    </Box>
  </Box>
);

export const CTALink = ({
  to,
  text,
  isExternalLink = false,
  thumbnailSrc,
  thumbnailAlt,
}: CTALinkProps) => {
  const content = React.useMemo(() => (
    <LinkInnerContent
      text={text}
      thumbnailSrc={thumbnailSrc}
      thumbnailAlt={thumbnailAlt}
    />
  ), [text, thumbnailSrc, thumbnailAlt]);

  if (!to) return content;

  return (
    <StyledLinkContainer>
      {isExternalLink ? (
        <a href={to} aria-label={text} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      ) : (
        <Link to={to} aria-label={text}>
          {content}
        </Link>
      )}
    </StyledLinkContainer>
  );
};
