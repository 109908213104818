import type { ToastDataMap } from "_global/components/Toast/types";

export const TOAST_DATA: ToastDataMap = {
  fetchDesignsErr: {
    id: "fetch-designs-err",
    message: "Error fetching saved designs.",
  },
  fetchDesignErr: {
    id: "fetch-design-err",
    message: "An error occurred while fetching selected design.",
  },
  updateDesignErr: {
    message: "Could not update design.",
  },
  updateDesignSuccess: {
    message: "This design has been updated.",
  },
  createDesignErr: {
    message: "Could not save design.",
  },
  createDesignSuccess: {
    message: "Your new design has been saved.",
  },
  createDesignFromCacheSuccess: {
    message: "We saved your recent changes in your Saved Designs list.",
    variant: "info",
  },
  undoDeleteDesignSuccess: {
    message: "Your design has been restored.",
  },
  deleteDesignErr: {
    message: "Could not delete design.",
  },
};
