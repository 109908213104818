import { Avatar } from "_global/components/Images";
import { StyledCard } from "./TeamCard.elements";
import { ContactChipList } from "./ContactChipList";
import type { TeamMember } from "../../types";

const getEnv = () => {
  if (process.env.REACT_APP_ENV === "production") {
    return "https://wayfinder.pahlischhomes.com";
  }
  return window.location.origin;
};

export type TeamCardProps = TeamMember;

export const TeamCard = ({
  jobTitle,
  profileImageUrl,
  phone,
  email,
  sms,
  firstName,
  lastName,
}: TeamMember) => {
  const fullName = `${firstName} ${lastName}`;
  const imgEnv = getEnv();
  return (
    <StyledCard.Container>
      <StyledCard.TopRow>
        <Avatar
          src={`${imgEnv}/assets/team/${profileImageUrl}`}
          alt={`${fullName} avatar`}
          width="72px"
          height="72px"
        />
        <StyledCard.NameContainer>
          <StyledCard.Name>{fullName}</StyledCard.Name>
          <StyledCard.Role>{jobTitle}</StyledCard.Role>
        </StyledCard.NameContainer>
      </StyledCard.TopRow>
      <ContactChipList sms={sms} phone={phone} email={email} />
    </StyledCard.Container>
  );
};
