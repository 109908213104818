import * as React from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import type { TButtonLinkProps } from "./types";

type CTAButtonProps = TButtonLinkProps;

const CTAButton: React.FC<CTAButtonProps> = ({ text, icon, ...props }) => (
  <PrimaryButton
    light
    shadow={false}
    alignLeft
    size="medium"
    label={text}
    icon={icon}
    {...props}
  />
);

export const ButtonLink = ({
  to,
  text,
  icon,
  isNotLink = false,
  isExternalLink = false,
  onClick, 
}: TButtonLinkProps) => {

  const actionProps = React.useMemo(() => ({
    onClick,
    "aria-label": text,
  }), [onClick, text]);

  const renderAsButton = React.useMemo(() => (!to || isNotLink), [to, isNotLink]);

  const button = React.useMemo(() => {
    const buttonProps = renderAsButton ? actionProps : { tabIndex: -1 };

    return <CTAButton icon={icon} text={text} {...buttonProps} />;
  }, [text, onClick]);

  if (!to || renderAsButton) return button;

  const linkStyles = {
    textDecoration: "unset",
    color: "unset",
    borderRadius: "24px",
  };

  if (isExternalLink) {
    return (
      <a
        href={to}
        style={linkStyles}
        target="_blank"
        rel="noopener noreferrer"
        {...actionProps}
      >
        {button}
      </a>
    );
  }

  return (
    <Link
      to={to}
      style={linkStyles}
      {...actionProps}
    >
      {button}
    </Link>
  );
};
