import * as React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useLocalStorage } from "_global/hooks/useLocalStorage";
import { BannerListRenderer } from "_global/components/Banner/BannerListRenderer";
import { routes } from "features/Navigation/util/routes";
import { shouldUserSeeNotificationPrefsWarning } from "features/Account/utils";
import type { BannerData } from "_global/components/Banner/types";

const getLocalStorageKey = (bannerId: string, userId?: number) => `${bannerId}-banner-dismissed_${userId || 0}`;

const MISMATCH_BANNER_ID = "hasContractMismatch";
const NOTIFICATION_PREFS_BANNER_ID = "needsNotificationPrefs";

const getContractMismatchBanner = (navigate: NavigateFunction, onClose: () => void): BannerData => ({
  id: MISMATCH_BANNER_ID,
  variant: "info",
  title: "Don't see your in-contract home?",
  dismissable: true,
  onClose,
  action: {
    label: "Request support in your user settings",
    onClick: () => navigate(routes.profile.path),
  },
});

const getNotificationPrefsBanner = (navigate: NavigateFunction, onClose: () => void): BannerData => ({
  id: NOTIFICATION_PREFS_BANNER_ID,
  variant: "warning",
  title: "Update your Notification Preferences to get project updates.",
  dismissable: true,
  onClose,
  action: {
    label: "Update Preferences",
    onClick: () => navigate(routes.profile.subroutes.notifications.path),
  },
});

export const DashboardBannerList: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();

  const [
    mismatchBannerLocalStorageKey,
    notificationPrefsBannerLocalStorageKey,
  ] = React.useMemo(() => [
    getLocalStorageKey(MISMATCH_BANNER_ID, user?.id),
    getLocalStorageKey(NOTIFICATION_PREFS_BANNER_ID, user?.id),
  ], [user?.id]);

  const [isMismatchBannerDismissed, setIsMismatchBannerDismissed] = useLocalStorage(mismatchBannerLocalStorageKey, false);
  const [isNotificationPrefsBannerDismissed, setIsNotificationPrefsBannerDismissed] = useLocalStorage(notificationPrefsBannerLocalStorageKey, false);
  
  const onDismissMismatchBanner = () => setIsMismatchBannerDismissed(true);
  const onDismissNotificationPrefsBanner = () => setIsNotificationPrefsBannerDismissed(true);

  const mismatchBanner = React.useMemo(() => {
    const shouldShow = user?.hasContractMismatch && !isMismatchBannerDismissed;
    if (shouldShow) return getContractMismatchBanner(navigate, onDismissMismatchBanner);
    return null;
  }, [user?.hasContractMismatch, isMismatchBannerDismissed]);
  
  const notificationPrefsBanner = React.useMemo(() => {
    const shouldShow = shouldUserSeeNotificationPrefsWarning(user) && !isNotificationPrefsBannerDismissed;
    if (shouldShow) return getNotificationPrefsBanner(navigate, onDismissNotificationPrefsBanner);
    return null;
  }, [user, isNotificationPrefsBannerDismissed]);

  const bannerList: BannerData[] = React.useMemo(() => {
    let list = [] as BannerData[];
    if (mismatchBanner) list = [ ...list, mismatchBanner ];
    if (notificationPrefsBanner) list = [ ...list, notificationPrefsBanner ];
    return list;
  }, [mismatchBanner, notificationPrefsBanner]);

  return (
    <BannerListRenderer bannerList={bannerList} />
  );
};
