import * as React from "react";
import { useWindowResize } from "_global/hooks/useWindowResize";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useTimelinePhaseState } from "./hooks/useTimelinePhaseState";
import {
  ContractedHomesController,
  type TContractedHomesViewProps,
} from "features/ContractedHomes/controllers/ContractedHomesController";

import { TimelineCardList } from "./components/TimelineCardList";
import { TimelinePageSkeleton, TimelinePageContainer } from "./TimelinePage.elements";
import { SherpaContentDetails, SherpaContentBottomDrawerModal } from "./components/SherpaContentDetails";

import { PHASE_LIST_FOR_NONCONTRACTED_BUYER } from "./constants";
import type { TPhaseResponseDTO } from "features/ContractedHomes/types";

type TimelinePageMainContentProps = {
  constructionPhases: TPhaseResponseDTO;
};

const TimelinePageMainContent: React.FC<TimelinePageMainContentProps> = ({
  constructionPhases,
}) => {
  const { isMobile } = useWindowResize();

  const {
    activePhase,
    activePhaseMobile,
    setActivePhase,
    clearActivePhase,
  } = useTimelinePhaseState({ constructionPhases });

  const shouldShowDesktopDetails = !isMobile && !!activePhase;
  const shouldShowMobileDetails = isMobile && !!activePhaseMobile;

  return (
    <TimelinePageContainer>
      <TimelineCardList
        activePhase={activePhase}
        setActivePhase={setActivePhase}
        constructionPhases={constructionPhases}
      />
      {shouldShowDesktopDetails && (
        <SherpaContentDetails selectedPhase={activePhase} />
      )}
      {shouldShowMobileDetails && (
        <SherpaContentBottomDrawerModal
          selectedPhase={activePhaseMobile}
          onClose={clearActivePhase}
        />
      )}
    </TimelinePageContainer>
  );
};

const TimelinePageApiContent = ({
  isLoading,
  isError,
  contractedHome,
}: TContractedHomesViewProps) => {
  const constructionPhases = React.useMemo(() => (contractedHome?.constructionPhases || []), [contractedHome]);

  const didErrOut = isError && !constructionPhases.length;
  const hasNoHomeData = !isLoading && !constructionPhases.length;
  const shouldRenderStaticContent = didErrOut || hasNoHomeData;
  
  const phaseList = shouldRenderStaticContent ? PHASE_LIST_FOR_NONCONTRACTED_BUYER : constructionPhases;

  if (isLoading) return <TimelinePageSkeleton />;

  return (
    <TimelinePageMainContent constructionPhases={phaseList} />
  );
};

export const TimelinePage = () => {
  const { user } = useGlobalContext();
  if (!user) return null;
  const shouldShowContractedHomeData = user.isContractedInHubspot;

  // show api content for contracted buyers
  if (shouldShowContractedHomeData) return (
    <ContractedHomesController component={TimelinePageApiContent} />
  );

  // show static content for non-contracted buyers
  return <TimelinePageMainContent constructionPhases={PHASE_LIST_FOR_NONCONTRACTED_BUYER} />;
};
