import * as React from "react";
import { NavLink } from "react-router-dom";
import { Box, styled } from "@mui/system";

import { SIDEBAR_WIDTH } from "../../../util/constants";
import type { NavRoute } from "../../../util/routes";
import { NavigationSidebarContext } from "../../../contexts/NavigationSidebarContext";

const StyledNavLink = styled(NavLink)(({ theme }) => {
  const { palette } = theme;
  const rounded = `${theme.shape.borderRadius}px`;
  const borderOffset = 4;

  return {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: palette.v2.neutral[100],
    fontWeight: 300,
    letterSpacing: ".5px",
    fontSize: "16px",
    height: "80px",
    textDecoration: "none",
    fontFamily: "Open Sans, Arial, sans-serif",
    "&.active": {
      backgroundColor: palette.sidebar.active.main,
      color: palette.sidebar.active.contrastText,
      fontWeight: 600,
      borderColor: palette.sidebar.active.dark,
      borderWidth: `0 0 0 ${borderOffset}px`,
      borderStyle: "solid",
      borderRadius: `0 ${rounded} ${rounded} 0`,
      width: "102%",
      boxShadow: palette.sidebar.active.shadow,
      letterSpacing: "0px",
      ".iconContainer": {
        marginLeft: `-${borderOffset}px`,
      },
      "svg": {
        fill: palette.sidebar.active.contrastText,
      },
    },
    "&:hover&:not(.active)": {
      color: palette.sidebar.active.contrastText,
      fontWeight: 600,
      letterSpacing: "0px",
    },
    ".iconContainer": {
      width: SIDEBAR_WIDTH.collapsed,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "svg": {
      fill: palette.sidebar.contrastText,
      fontSize: "28px",
    },
  };
});

export const PrimaryNavItem: React.FC<NavRoute> = ({
  path,
  label,
  icon,
}) => {
  const {
    expanded,
    transitionStatus,
  } = React.useContext(NavigationSidebarContext);

  const Icon = icon;
  const iconContainer = React.useMemo(() => (
    <Box className="iconContainer">
      <Icon fontSize="medium" />
    </Box>
  ), [Icon]);

  const shouldShowExpandedNavItem = React.useMemo(() => (
    expanded && transitionStatus.didCompleteExpand
  ), [expanded, transitionStatus]);

  const { text, title } = React.useMemo((): Record<string, string> => ({
    text: shouldShowExpandedNavItem ? label : "",
    title: shouldShowExpandedNavItem ? "" : `Go to ${label}`
  }), [shouldShowExpandedNavItem, label]);

  return (
    <StyledNavLink to={path} title={title}>
      {iconContainer}
      {text}
    </StyledNavLink>
  );
};
