import { NewspaperOutlined } from "@mui/icons-material";
import { SubscribeToNewsletterCTACard } from "features/Notifications/Marketing";
import { SectionTile } from "_global/components/Containers";

const TITLE = "Newsletter Enrollment";
const DESCRIPTION = "Click below for helpful news and information.";

export const NewsletterSection = () => (
  <SectionTile.Container>
    <SectionTile.Header
      title={TITLE}
      icon={NewspaperOutlined}
      description={DESCRIPTION}
    />
    <SubscribeToNewsletterCTACard />
  </SectionTile.Container>
);
