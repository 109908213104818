import { styled } from "@mui/system";
import { switchClasses } from "@mui/base";
import { themeFonts } from "_global/theme/typography";


type LabelProps = {
  disabled?: boolean;
};

const options = {
  shouldForwardProp: (prop: string) => prop !== "disabled",
};

export const StyledLabel = styled("label", options)<LabelProps>(({ theme, disabled }) => ({
  ...themeFonts.textLg,
  color: theme.palette.v2.neutral[disabled ? 60 : 90],
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  cursor: disabled? "not-allowed" : "pointer",
  maxWidth: "max-content",
}));

export const StyledRoot = styled("span")(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px 10px 10px 0;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: ${theme.palette.v2.neutral[40]};
    border-radius: 16px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
    border-radius: 16px;
    background-color: ${theme.palette.v2.neutral[10]};
    position: relative;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  &.${switchClasses.focusVisible} .${switchClasses.thumb} {
    background-color: ${theme.palette.v2.neutral[10]};
    box-shadow: 0 0 1px 8px rgba(90, 11, 142, 0.25);
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 20px;
      top: 4px;
      background-color: ${theme.palette.v2.neutral[10]};
    }

    .${switchClasses.track} {
      background: ${theme.palette.v2.primary.main};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `,
);
