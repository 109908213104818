import * as React from "react";
import { SherpaContentTile } from "./SherpaContentSection.elements";
import { useContractedBuyerDashboardContext } from "features/Dashboard/contexts/ContractedBuyerDashboardContext";

export const SherpaContentSection: React.FC = () => {
  const { activePhase } = useContractedBuyerDashboardContext();

  if (!activePhase) return (
    <SherpaContentTile.LoadingSkeleton />
  );

  return (
    <SherpaContentTile.Container>
      <SherpaContentTile.Title>
        {activePhase.title}
      </SherpaContentTile.Title>
      {!!activePhase.videoEmbedSrc && (
        <SherpaContentTile.VideoIFrame
          height="320px"
          src={activePhase.videoEmbedSrc}
          title={activePhase.title}
        />
      )}
      {!!activePhase.heroImgUrl && (
        <SherpaContentTile.HeroImage
          src={activePhase.heroImgUrl}
          alt={activePhase.title}
        />
      )}
      <SherpaContentTile.Description>
        {activePhase.description}
      </SherpaContentTile.Description>
    </SherpaContentTile.Container>
  );
};
