import * as React from "react";
import { Stack, Box, styled } from "@mui/system";
import { Button as BaseButton } from "@mui/base";

import { Typography } from "_global/components/Typography/Typography";
import { themeFonts } from "_global/theme/typography";
import { visuallyHidden } from "@mui/utils";
import { BrandLogo } from "_global/components/Logo";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { useLogout } from "features/Auth/hooks/useLogout";

type ContainerProps = {
  children: React.ReactNode;
};

const StyledOuterContainer = styled(Stack)(() => ({
  alignItems: "center",
  height: "100%",
  width: "100%",
  maxWidth: "100%",
  maxHeight: "100%",
  flexGrow: 1,
  overflow: "auto",
  // backgroundColor: "#a7f0ae",
}));

const StyledLogoHeader = styled(Stack)(() => ({
  flexShrink: 0,
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "16px",
  // backgroundColor: "#b783ff",
}));

const Content = styled(Stack)(() => ({
  flexGrow: 1,
  gap: "10px",
  alignItems: "center",
  width: "100%",
  maxWidth: "100%",
  paddingTop: "16px",
  paddingBottom: "5px",
  // backgroundColor: "#ff8383",
}));

type ContentContainerProps = {
  hasOverflow: boolean;
};

const containerOptions = {
  shouldForwardProp: (prop: string) => prop !== "hasOverflow",
};

const StyledContentContainer = styled(Stack, containerOptions)<ContentContainerProps>(({ theme, hasOverflow }) => ({
  borderRadius: hasOverflow ? "4px" : "none",
  border: hasOverflow ? `0.5px solid ${theme.palette.v2.neutral[30]}` : "none",
  background: hasOverflow ? "linear-gradient(355deg, #fcfcfc 0%, #ffffff 100%)" : "none",
  padding: "0px 4px 4px",
  [theme.breakpoints.up("sm")]: {
    padding: "0px 8px 4px",
  },
}));

const ScrollableContent = ({ children }: ContainerProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    if (ref.current) {
      setHasOverflow(ref.current.scrollHeight > ref.current.clientHeight);
      ref.current.scrollTop = 0;
    }
  }, []);
  
  return (
    <StyledContentContainer
      ref={ref}
      flexGrow={1}
      overflow="auto"
      hasOverflow={hasOverflow}
    >
      <Content >{children}</Content>
    </StyledContentContainer>
  );
};

type PageHeaderProps = {
  title: string;
  description?: string;
};

const StyledPageDescription = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  ...themeFonts.textLg,
}));

const StyledContainer = styled(Stack)(() => ({
  textAlign: "center",
  width: "100%",
  maxWidth: "400px",
  flexShrink: 0,
  gap: "8px",
}));

export const Header: React.FC<PageHeaderProps> = ({
  title,
  description,
}) => (
  <StyledContainer>
    <Typography.H3 semanticTag="h2">{title}</Typography.H3>
    {description && (
      <StyledPageDescription>{description}</StyledPageDescription>
    )}
  </StyledContainer>
);

const LogoHeader = () => (
  <StyledLogoHeader>
    <h1 style={visuallyHidden}>Pahlisch Wayfinder Onboarding</h1>
    <Box aria-hidden="true" sx={{ width: "170px", minWidth: "170px" }}>
      <BrandLogo.Main />
    </Box>
  </StyledLogoHeader>
);

const OuterContainer: React.FC<ContainerProps> = ({ children }) => (
  <StyledOuterContainer>
    <LogoHeader />
    <ScrollableContent>
      {children}
    </ScrollableContent>
  </StyledOuterContainer>
);

const MainContentContainer = styled(Stack)(() => ({
  flexGrow: 1,
  gap: "20px",
  justifyContent: "center",
  width: "100%",
  maxWidth: "480px",
  ...themeFonts.textLg,
}));

const StyledLogoutButton = styled(BaseButton)(({ theme }) => ({
  ...themeFonts.textLg,
  fontSize: "16px",
  margin: "0px auto 0px",
  maxWidth: "max-content",
  backgroundColor: "transparent",
  borderRadius: "8px",
  color: theme.palette.v2.colorTextLink,
  border: "1px solid transparent",
  fontWeight: 500,
  letterSpacing: ".4px",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.v2.primary.hover,
    border: `0.5px solid ${theme.palette.v2.primary.border}`,
  },
}));

export const LogoutButton: React.FC = () => {
  const { handleLogout } = useLogout();
  return (
    <StyledLogoutButton onClick={handleLogout}>
      Sign out
    </StyledLogoutButton>
  );
};

export const PageLayout = {
  Container: OuterContainer,
  Header,
  MainContentContainer,
};

type SendButtonProps = {
  onClick: () => void;
  disabled: boolean;
  label: string;
};

export const SendButton: React.FC<SendButtonProps> = ({
  onClick,
  disabled,
  label,
}) => (
  <Box minWidth="300px">
    <PrimaryButton
      light
      fullWidth
      label={label}
      onClick={onClick}
      disabled={disabled}
    />
  </Box>
);

export const StyledSuccessMessage = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  minHeight: "24px",
  gap: "8px",
  textAlign: "left",
  color: theme.palette.v2.success.main,
  ...themeFonts.textSmSemibold,
}));

export const StyledLink = styled("a")(({ theme }) => ({
  display: "inline-flex",
  color: theme.palette.v2.primary.main,
  fontWeight: 700,
  textDecoration: "unset",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
    textUnderlineOffset: "2px",
  },
}));
