import * as React from "react";
import { Box, Stack } from "@mui/system";

type ProgressDotsProps = {
  totalSteps: number;
  currentStep: number;
};

export const StepperProgressDots: React.FC<ProgressDotsProps> = ({
  totalSteps,
  currentStep,
}) => {
  const arr: string[] = React.useMemo(() => (
    Array.from(Array(totalSteps)).map((_, i) => `${i}`)
  ), [totalSteps]);

  return (
    <Stack direction="row" gap="8px">
      {arr.map((step, i) => {
        const active = i + 1 === currentStep;
        return (
          <Box
            key={step}
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: ({ palette }) => (
                active ? palette.v2.primary.main : palette.v2.neutral[50]
              ),
            }}
          />
        );
      })}
    </Stack>
  );
};
