import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useMatch } from "react-router-dom";
import { FullPageLoadingSpinner } from "_global/components/Progress/LoadingSpinners";
import { VerifyEmailView } from "views/VerifyEmailView";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useVR360Cache } from "features/DesignStudio/hooks/useVR360Cache";
import { routes } from "features/Navigation/util/routes";
import { useWayfinderUserController } from "features/Account/hooks/useWayfinderUserController";
import { ErrorDefault } from "ErrorDefault";

type WrapperProps = {
  children: React.ReactNode;
};

export const RootPathRedirectController: React.FC<WrapperProps> = ({
  children,
}) => {
  const { isAuthenticated, isLoading, user: auth0User } = useAuth0();

  const { user } = useGlobalContext();
  const { isError: hasFetchUserError, error } = useWayfinderUserController();

  const isRootPath = useMatch(routes.root.path);
  const isLogoutPath = useMatch(routes.logout.path);
  const isDesignStudioPath = useMatch(routes.designStudio.path); // vr360 login redirects to design studio

  const wayfinderUserIsLoading = isAuthenticated && !user;
  const isOnboarded = user && user.isOnboarded;
  const isVerifiedEmail = auth0User?.email_verified ?? false;

  const vr360Cache = useVR360Cache();
  const vr360UserMustOnboard =
    !isOnboarded && isDesignStudioPath && !vr360Cache.hasUnsavedChanges;

  const shouldShowVerifyEmailView =
    !!auth0User && !isVerifiedEmail && !isLogoutPath;
  const shouldShowLoadingSpinner =
    isLoading || (wayfinderUserIsLoading && !isLogoutPath);

  const shouldRedirectToLogin = !isAuthenticated && isRootPath;
  const shouldRedirectToOnboarding =
    isAuthenticated && !isOnboarded && (isRootPath || vr360UserMustOnboard);
  const shouldRedirectToDashboard =
    isAuthenticated && isOnboarded && isRootPath;

  const redirectTo = React.useMemo(() => {
    if (shouldRedirectToLogin) return routes.login.path;
    if (shouldRedirectToOnboarding) return routes.onboarding.path;
    if (shouldRedirectToDashboard) return routes.dashboard.path;
    return null;
  }, [
    shouldRedirectToLogin,
    shouldRedirectToOnboarding,
    shouldRedirectToDashboard,
  ]);

  if (hasFetchUserError) {
    return <ErrorDefault error={error} />;
  }

  // this will run on any path, not just root path
  if (shouldShowVerifyEmailView) {
    return <VerifyEmailView />;
  }

  if (shouldShowLoadingSpinner) {
    return <FullPageLoadingSpinner />;
  }

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return <>{children}</>;
};
