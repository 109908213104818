import * as React from "react";
import { Stack, Box, styled } from "@mui/system";

import { TextInput, TextArea } from "_global/components/Inputs";
import { LoadingSkeleton } from "_global/components/Skeleton/LoadingSkeleton";
import { ThemeButton as Button } from "_global/components/Buttons/ThemeButton";

import { SPACING_Y } from "./constants";
import type { TextInputProps } from "_global/components/Inputs/TextInput/TextInput";
import type { TextAreaProps } from "_global/components/Inputs/TextInput/TextArea";

const StyledInputContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "48%",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

type StyledTextInputProps = TextInputProps & {
  skeleton?: boolean;
};

export const StyledTextInput = React.forwardRef(function CustomStyledProfileInput(
  props: StyledTextInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { skeleton, ...inputProps } = props;

  if (skeleton) return (
    <StyledInputContainer>
      <LoadingSkeleton width="100%" height={64} borderRadius="8px" />
    </StyledInputContainer>
  );

  return (
    <StyledInputContainer>
      <TextInput
        {...inputProps}
        tileVariant={props.tileVariant || "tile"}
        ref={ref}
      />
    </StyledInputContainer>
  );
});

type StyledTextAreaProps = TextAreaProps & {
  skeleton?: boolean;
};

export const StyledTextArea = React.forwardRef(function CustomStyledProfileTextArea(
  props: StyledTextAreaProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { skeleton, ...inputProps } = props;

  if (skeleton) return (
    <StyledInputContainer>
      <LoadingSkeleton width="100%" height={64} borderRadius="8px" />
    </StyledInputContainer>
  );

  return (
    <StyledInputContainer minWidth="100%">
      <TextArea
        {...inputProps}
        tileVariant={props.tileVariant || "tile"}
        ref={ref}
      />
    </StyledInputContainer>
  );
});

export const InputRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
  gap: "2%",
  rowGap: SPACING_Y,
  minWidth: "100%",
  flexWrap: "wrap",
}));

export const RowStack = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "column",
  rowGap: SPACING_Y,
}));

export const Divider = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.v2.neutral[50],
  marginBottom: SPACING_Y,
}));

type SavedButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  alignSelf: "flex-end",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "33%",
  },
}));

export const SaveButton: React.FC<SavedButtonProps> = ({
  onClick,
  disabled,
}) => {
  return (
    <StyledButtonContainer>
      <Button
        fullWidth
        type="submit"
        variant="success"
        label="Save Preferences"
        onClick={onClick}
        disabled={disabled}
      />
    </StyledButtonContainer>
  );
};
