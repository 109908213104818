import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import { Typography } from "_global/components/Typography/Typography";
import { themeFonts } from "_global/theme/typography";

type PageHeaderProps = {
  title: string;
  description?: string;
};

const StyledPageDescription = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  ...themeFonts.textLg,
}));

const StyledContainer = styled(Stack)(() => ({
  textAlign: "center",
  width: "100%",
  maxWidth: "400px",
  flexShrink: 0,
  gap: "8px",
}));

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
}) => (
  <StyledContainer>
    <Typography.H3 semanticTag="h2">{title}</Typography.H3>
    {description && (
      <StyledPageDescription>{description}</StyledPageDescription>
    )}
  </StyledContainer>
);
