import { styled, Stack } from "@mui/system";
import { TimelineCardSkeleton } from "./TimelineCard.elements";
import { PHASE_LIST_FOR_NONCONTRACTED_BUYER as PHASE_LIST } from "../constants";
import { CAROUSEL_ITEM_CLASSNAME } from "_global/components/Carousel";

export const ListContainer = styled(Stack)(({ theme }) => ({
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    alignItems: "center",
  },
}));

export const CarouselListContainer = styled(Stack)(() => ({
  padding: "0 6px",
  flexDirection: "row",
  gap: "18px",
  [`& .${CAROUSEL_ITEM_CLASSNAME}`]: {
    // fadeIn animation masks the scroll into view transition for smoother UX
    animation: "fadeIn 0.3s ease-in-out forwards",
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
  },
}));

export const LoadingListSkeleton = () => (
  <ListContainer>
    {PHASE_LIST.map(({ name }) => (
      <TimelineCardSkeleton key={name} />
    ))}
  </ListContainer>
);

export const TimelineCarouselListSkeleton = () => (
  <CarouselListContainer>
    {PHASE_LIST.map(({ name }) => (
      <TimelineCardSkeleton key={name} carousel />
    ))}
  </CarouselListContainer>
);
