import { Pages } from ".";
import type { THomebuyerJourneyStage, UserInfoFormValues } from "../types";
import type { TOptions } from "_global/components/Inputs/types";

export const STEPPER_PAGE_IDS = {
  userInfo: "userInfo",
  notifications: "notifications",
  newsletter: "newsletter",
  nextSteps: "nextSteps",
} as const;

export const STEPPER_PAGES = [
  {
    id: STEPPER_PAGE_IDS.userInfo,
    title: "Tell Us More About You",
    component: Pages.UserInfoForm,
    buttonText: "Continue",
  },
  {
    id: STEPPER_PAGE_IDS.notifications,
    title: "Stay Informed",
    description: "We send ongoing updates during the construction of your home. How should we stay in contact with you?",
    component: Pages.NotificationsOptInPage,
    buttonText: "Continue",
  },
  {
    id: STEPPER_PAGE_IDS.newsletter,
    title: "Keep Up With Us",
    description: "Click below for helpful news and information.",
    component: Pages.SubscribeToNewsletterPage,
    buttonText: "Complete Registration",
  },
  {
    id: STEPPER_PAGE_IDS.nextSteps,
    title: "Next Steps",
    description: "Congratulations, you now have access to Pahlisch Wayfinder. Click the options below to start exploring.",
    component: Pages.NextSteps,
    buttonText: "Go to Dashboard",
  },
];

type HomeBuyerStageKeys = "browsing" | "preparingForContract" | "inContract";

export const HOMEBUYER_JOURNEY_STAGE_IDS: Record<HomeBuyerStageKeys, THomebuyerJourneyStage> = {
  browsing: "browsing",
  preparingForContract: "preparing for contract",
  inContract: "in contract",
};

const { browsing, preparingForContract, inContract } = HOMEBUYER_JOURNEY_STAGE_IDS;

export const HOMEBUYER_JOURNEY_STAGES_OPTIONS: TOptions[] = [
  {
    label: "I'm gathering information",
    value: browsing,
  },
  {
    label: "I'm making an offer on a Pahlisch home",
    value: preparingForContract,
  },
  {
    label: "I'm under contract with Pahlisch Homes",
    value: inContract,
  },
];

export const FIELD_KEYS: Record<keyof UserInfoFormValues, keyof UserInfoFormValues> = {
  firstName: "firstName",
  lastName: "lastName",
  homebuyerJourneyStage: "homebuyerJourneyStage",
} as const;
