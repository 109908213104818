import { styled, Box } from "@mui/system";

const PageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: "100%";
  width: "100%";
`;

const options = {
  shouldForwardProp: (prop: string) => ![ "width" ].includes(prop),
};

type SizeProps = {
  width?: string | number;
};

export const LoadingSpinnerCSS = styled(Box, options)<SizeProps>(({ theme, width = "50px" }) => {
  const { palette } = theme;

  return {
    ".circle": {
      position: "relative",
      display: "inline-block",
      width: width,
      height: width,
      maxWidth: "200px",
      maxHeight: "200px",
    },
    ".circle .inner": {
      position: "absolute",
      width: "100%",
      height: "100%",
      border: "double 2px transparent",
      borderRadius: "50%",
      backgroundImage: `
        linear-gradient(${palette.white}, ${palette.white}),
        radial-gradient(circle at 100%, ${palette.primary.dark}, ${palette.white}, ${palette.white})`,
      backgroundOrigin: "border-box",
      backgroundClip: "padding-box, border-box",
      animation: "spin 1.5s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "50%": {
        transform: "rotate(180deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
    
  };
});

const SimpleSpinner = styled(Box, options )<SizeProps>(({ width = "50px" }) => {
  return {
    animation: "spin 1.5s linear infinite",
    transform: "translateZ(0)",
    borderTop: "5px solid #d2d1d1",
    borderRight: "5px solid #d2d1d1",
    borderBottom: "5px solid #d2d1d1",
    borderLeft: "5px solid #d8d8d8",
    background: "transparent",
    width: width,
    height: width,
    borderRadius: "50%",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "50%": {
        transform: "rotate(180deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  };
});


export const LoadingSpinner: React.FC<SizeProps> = ({ width }) => {
  return (
    <Container role="status" aria-busy="true">
      <SimpleSpinner aria-label="Loading..." width={width}>
        <Box className="circle">
          <Box className="inner" />
        </Box>
      </SimpleSpinner>
    </Container>
  );
};

export const FullPageLoadingSpinner = () => {
  return (
    <PageContainer role="status" aria-busy="true">
      <LoadingSpinnerCSS aria-label="Loading..." width="33vw">
        <Box className="circle">
          <Box className="inner" />
        </Box>
      </LoadingSpinnerCSS>
    </PageContainer>
  );
};
