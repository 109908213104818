import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Stack, styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { ReactComponent as ArrowRightIcon } from "_global/assets/icons/arrow-right-link-icon.svg";

import { routes } from "features/Navigation/util/routes";
import { pahlischHomesForSaleUrl } from "features/SavedHomesListing/constants";
import { CAROUSEL_ITEM_CLASSNAME } from "_global/components/Carousel";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  minWidth: "min-content",
  maxWidth: "max-content",
  color: theme.palette.grey[900],
  display: "flex",
  alignItems: "center",
  fontSize: "15px",
  fontWeight: 600,
  whiteSpace: "nowrap",
  "&:hover": {
    color: theme.palette.primary.shade[0],
    "& svg": {
      fill: theme.palette.primary.shade[0],
    },
  },
  "svg": {
    marginLeft: theme.spacing(1),
    fill: theme.palette.grey[900],
  },
}));

/** Internal Links **/

const VIEW_ALL_SAVED_HOMES = "View All Saved Homes";

const savedHomesPath = routes.profile.subroutes.savedHomes.path;

export const ViewAllLink = () => (
  <StyledLink to={savedHomesPath} aria-label={VIEW_ALL_SAVED_HOMES}>
    <span>View All</span><ArrowRightIcon height="22px" width="22px" />
  </StyledLink>
);

const ViewAllSavedHomesButton = () => {
  const navigate = useNavigate();

  return (
    <PrimaryButton
      fullWidth
      label={VIEW_ALL_SAVED_HOMES}
      onClick={() => navigate(savedHomesPath)}
    />
  );
};

/** External Links **/

const VIEW_ALL_FOR_SALE = "View All Homes for Sale";

const ViewAllHomesForSaleButton = () => {
  return (
    <a
      href={pahlischHomesForSaleUrl}
      aria-label={VIEW_ALL_FOR_SALE}
      target="_blank"
      style={{ textDecoration: "none", width: "100%" }}
    >
      <PrimaryButton fullWidth tabIndex={-1} label={VIEW_ALL_FOR_SALE} />
    </a>
  );
};

/** Carousel CTA Card **/
export const CarouselCTACard = () => (
  <Box
    className={CAROUSEL_ITEM_CLASSNAME}
    sx={{
      width: "380px",
      height: "99.5%",
      backgroundColor: theme => theme.palette.white,
      padding: theme => theme.spacing(1),
      borderRadius: "16px",
    }}
  >
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{
      height: "100%",
      backgroundColor: theme => theme.palette.v2.primary.surface,
      borderRadius: "12px",
      padding: theme => theme.spacing(5),
    }}
  >
    <ViewAllSavedHomesButton />
  </Stack>
  </Box>
);

/** State UI & UI Containers **/

const NonCarouselContainer = ({ children }: { children?: React.ReactNode }) => (
  <Stack alignItems="center" justifyContent="center" width="100%" paddingY="20px">
    {children}
  </Stack>
);

export const EmptyState = () => (
  <NonCarouselContainer>
    <ViewAllHomesForSaleButton />
  </NonCarouselContainer>
);

export const LoadingState = () => (
  <NonCarouselContainer>
    <Box sx={{ display: "flex", color: "#C9C9C9" }}>
      <CircularProgress color="inherit" />
    </Box>
  </NonCarouselContainer>
);
