import * as React from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { theme } from "theme";
import { AuthProvider } from "features/Auth/AuthProvider";
import { ToastListRenderer } from "_global/components/Toast/ToastListRenderer";
import { RootPathRedirectController } from "features/Auth/components/RootPathRedirectController";
import { WayfinderUserControllerContextProvider } from "features/Account/contexts/WayfinderUserControllerContext";
import { initializeGA } from "./utils/google-analytics/google-analytics";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorDefault } from "./ErrorDefault";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
    },
  },
});

function App() {
  React.useEffect(() => {
    initializeGA();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastListRenderer />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <WayfinderUserControllerContextProvider>
            <RootPathRedirectController>
              <ErrorBoundary FallbackComponent={ErrorDefault}>
                <Outlet />
              </ErrorBoundary>
            </RootPathRedirectController>
          </WayfinderUserControllerContextProvider>
          <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
