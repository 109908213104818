import * as React from "react";
import { Stack, Box, styled } from "@mui/system";
import { Button as BaseButton } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";

import { EXIT_CLASS } from "../constants";
import { themeFonts } from "_global/theme/typography";
import type { ToastVariants } from "../types";
import type { ToastActionProps } from "./Toast";

const DISMISS_BUTTON_CLASS = "dismiss-btn";
const ACTION_BUTTON_CLASS = "action-btn";

const options = {
  shouldForwardProp: (prop: string) => !["variant", "isStaticPosition","animated"].includes(prop),
};

type StyledToastProps = {
  isStaticPosition?: boolean;
  variant: ToastVariants;
  animated?: boolean;
};

const KEYFRAMES_NAME = "toastInRight";

export const toastAnimation = {
  animation: `${KEYFRAMES_NAME} .2s cubic-bezier(.4,0,.2,1)`,
  transition: "transform .2s cubic-bezier(.4,0,.2,1)",
  keyframes: {
    [`@keyframes ${KEYFRAMES_NAME}`]: {
      "from": {
        transform: "translateX(100%)",
      },
      "to": {
        transform: "translateX(0)",
      },
    },
  }
};

export const StyledToast = styled("div", options)<StyledToastProps>(({
  theme,
  variant,
  animated = true,
  isStaticPosition,
}) => {
  const { palette } = theme;

  const variantColors = React.useMemo(() => ({
    backgroundColor: palette.v2[variant].surface,
    borderColor: palette.v2[variant].main,
    "& > svg": {
      color: palette.v2[variant].main,
    },
  }), [palette, variant]);

  const fontStyles = themeFonts.textMdSemibold;

  const actionFontStyles = {
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.14px",
    fontFamily: "Poppins, Arial, sans-serif",
    lineHeight: "20px",
  };

  const BUTTON_PADDING = 6;

  return {
    top: "24px",
    right: "24px",
    opacity: 1,
    position: isStaticPosition ? "static" : "fixed",
    zIndex: isStaticPosition ? 1: 9999,
    animation: animated ? toastAnimation.animation : "none",
    transition: animated ? toastAnimation.transition : "none",
    ...toastAnimation.keyframes,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1,2),
    gap: theme.spacing(2.25),
    minHeight: "56px",
    minWidth: "340px",
    width: "max-content",
    maxWidth: "550px",
    color: palette.v2.neutral[100],
    borderRadius: "8px",
    boxShadow: palette.v2.shadow1,
    borderWidth: "1px",
    borderStyle: "solid",
    ...fontStyles,
    ...variantColors,
    "& button": {
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      transition: "all .1s cubic-bezier(.4,0,.2,1)",
      padding: `${BUTTON_PADDING}px`,
    },
    [`.${DISMISS_BUTTON_CLASS}`]: {
      borderRadius: "4px",
      border: ".5px solid transparent",
      color: palette.v2.neutral[100],
      marginRight: `-${BUTTON_PADDING}px`,
      "&:hover": {
        borderColor: variantColors.backgroundColor,
        boxShadow: `0px 0px 2px 0px ${palette.v2.colorTextLink}`,
        color: palette.v2.colorTextLink,
      },
    },
    [`.${ACTION_BUTTON_CLASS}`]: {
      border: "none",
      color: palette.v2.colorTextLink,
      ...actionFontStyles,
      "&:hover": {
        textDecoration: "underline",
        textUnderlineOffset: "2px",
      },
    },
    [`&.${EXIT_CLASS}`]: {
      opacity: 0,
      transform: "translateX(100%)",
      transition: "all .2s cubic-bezier(.4,0,.2,1)",
    },
    [theme.breakpoints.down("sm")]: {
      right: 0,
      gap: theme.spacing(1.5),
      width: `calc(100vw - ${theme.spacing(1)})`,
      maxWidth: `calc(100vw - ${theme.spacing(1)})`,
    },
  };
});

type ContainerProps = {
  children?: React.ReactNode;
};

const Divider = () => (
  <Box
    sx={{
      width: "1px",
      height: "100%",
      minHeight: "22px",
      backgroundColor: theme => theme.palette.v2.neutral[80],
    }}
  />
);

export const ActionsContainer: React.FC<ContainerProps> = ({ children }) => (
  <Stack
    flexDirection="row"
    flexGrow={1}
    display="inline-flex"
    alignItems="center"
    justifyContent="flex-end"
    gap="6px"
    divider={<Divider />}
  >
    {children}
  </Stack>
);

export const ActionButton: React.FC<ToastActionProps> = ({
  label,
  onClick,
}) => (
  <BaseButton
    className={ACTION_BUTTON_CLASS}
    onClick={onClick}
  >
    {label}
  </BaseButton>
);

type ButtonProps = {
  onClick: () => void;
};

export const DismissButton: React.FC<ButtonProps> = ({ onClick }) => (
  <BaseButton
    aria-label="Dismiss this notification."
    className={DISMISS_BUTTON_CLASS}
    onClick={onClick}
  >
    <CloseIcon />
  </BaseButton>
);
