import * as React from "react";
import { Stack } from "@mui/system";
import { NotificationPrefsSection } from "features/Account/Profile/Notifications/NotificationPrefsSection";
import { NewsletterSection } from "features/Account/Profile/Notifications/NewsletterSection";
import { useGlobalContext } from "_global/contexts/GlobalContext";

export const Notifications: React.FC = () => {
  const { user } = useGlobalContext();

  const showContractedView = user?.isContractedInHubspot;

  return (
    <Stack gap="32px">
      {showContractedView && <NotificationPrefsSection />}
      <NewsletterSection />
    </Stack>
  );
};
