import * as React from "react";
import {
  useTimelinePhaseState,
  type UseTimelinePhaseStateReturnType,
} from "features/Timeline/hooks/useTimelinePhaseState";
import type { TPhaseResponseDTO } from "features/ContractedHomes/types";

type TContractedBuyerDashboardContext =  Pick<UseTimelinePhaseStateReturnType, "activePhase" | "setActivePhase"> & {
  setConstructionPhases: (args: TPhaseResponseDTO) => void;
};

export const ContractedBuyerDashboardContext = React.createContext<TContractedBuyerDashboardContext>({
  activePhase: null,
  setActivePhase: () => undefined,
  setConstructionPhases: () => undefined,
});

type ProviderProps = {
  children: React.ReactNode;
};

export const useContractedBuyerDashboardContext = () => React.useContext(ContractedBuyerDashboardContext);

export const ContractedBuyerDashboardContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const [constructionPhases, setConstructionPhases] = React.useState<TPhaseResponseDTO | null>(null);
  const {
    activePhase,
    setActivePhase,
  } = useTimelinePhaseState({ constructionPhases });

  const handleSetConstructionPhases = React.useCallback((args: TPhaseResponseDTO) => {
    setConstructionPhases(args);
  }, []);

  return (
    <ContractedBuyerDashboardContext.Provider
      value={{
        activePhase,
        setActivePhase,
        setConstructionPhases: handleSetConstructionPhases,
      }}
    >
      {children}
    </ContractedBuyerDashboardContext.Provider>
  );
};
