import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "_global/constants";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import type { User, MutationCallbackOptions } from "_global/types";

export type UpdateMarketingMutationFunc = (
  options?: MutationCallbackOptions,
) => void;

interface IUpdateMarketingMutationDTO {
  id: User["id"];
  email: string;
}

export const useUpdateMarketing = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { user } = useGlobalContext();
  const { showErrorToast } = useToastRenderer();

  const updateMarketingStatus = async () => {
    if (!user) throw new Error("No user found.");

    const dto: IUpdateMarketingMutationDTO = {
      id: user.id,
      email: user.email,
    };

    return await apiClient.post("/marketing", dto);
  };

  const {
    mutate,
    isError,
    isSuccess,
    isPending: isLoading,
  } = useMutation({
    mutationFn: updateMarketingStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.marketing] });
    },
    onError: (err) => {
      console.error("useUpdateMarketing: onError", err);
      showErrorToast({ message: "Could not update subscription status." });
    },
  });

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
  };
};
