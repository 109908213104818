import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import type { TMarketingDTO } from "../types";
import { QUERY_KEYS } from "_global/constants";

type UseGetMarketingOptions = {
  enabled?: boolean;
};

export const useGetMarketing = (options?: UseGetMarketingOptions) => {
  const { enabled = true } = options || {};
  const client = useApiClient();

  const { data, isStale, isFetching, isLoading, isError } = useQuery<
    TMarketingDTO,
    Error
  >({
    enabled,
    queryKey: [QUERY_KEYS.marketing, "get"],
    queryFn: async () => {
      const response = await client.get("/marketing");
      return response.data;
    },
  });

  return {
    data,
    isStale,
    isLoading,
    isFetching,
    isError,
  };
};
