import { Box, styled } from "@mui/system";

const StyledPriceChip = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "12px",
  color: theme.palette.black,
  backgroundColor: theme.palette.info.light,
  borderRadius: "13px",
  padding: theme.spacing(0.5, 1.5),
  maxWidth: "min-content",
}));

export const PriceChip = ({ price }: { price: number }) => (
  <StyledPriceChip>${price.toLocaleString()}</StyledPriceChip>
);
