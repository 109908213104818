import * as React from "react";
import { Stack, styled } from "@mui/system";

const OuterContainer = styled(Stack)(() => ({
  alignItems: "center",
  height: "100%",
  width: "100%",
  maxWidth: "100%",
  maxHeight: "100%",
  flexGrow: 1,
  overflow: "auto",
  // backgroundColor: "#a7f0ae",
}));

const Header = styled(Stack)(() => ({
  flexShrink: 0,
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "16px",
  // backgroundColor: "#b783ff",
}));

const Content = styled(Stack)(() => ({
  flexGrow: 1,
  gap: "10px",
  alignItems: "center",
  width: "100%",
  maxWidth: "100%",
  paddingTop: "16px",
  paddingBottom: "5px",
  // backgroundColor: "#ff8383",
}));

type ContentContainerProps = {
  hasOverflow: boolean;
};

const containerOptions = {
  shouldForwardProp: (prop: string) => prop !== "hasOverflow",
};

const StyledContentContainer = styled(Stack, containerOptions)<ContentContainerProps>(({ theme, hasOverflow }) => ({
  borderRadius: hasOverflow ? "4px" : "none",
  border: hasOverflow ? `0.5px solid ${theme.palette.v2.neutral[30]}` : "none",
  background: hasOverflow ? "linear-gradient(355deg, #fcfcfc 0%, #ffffff 100%)" : "none",
  padding: "0px 4px 4px",
  [theme.breakpoints.up("sm")]: {
    padding: "0px 8px 4px",
  },
}));

type ScrollableContentProps = {
  children: React.ReactNode;
  page?: number;
};

const ScrollableContent = ({ children, page = 0 }: ScrollableContentProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    if (ref.current) {
      setHasOverflow(ref.current.scrollHeight > ref.current.clientHeight);
      ref.current.scrollTop = 0;
    }
  }, [page]);
  
  return (
    <StyledContentContainer
      ref={ref}
      flexGrow={1}
      overflow="auto"
      hasOverflow={hasOverflow}
    >
      <Content >{children}</Content>
    </StyledContentContainer>
  );
};

const Footer = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: "16px",
  maxWidth: "100%",
  flexShrink: 0,
  alignItems: "center",
  padding: "5px 10% 0px",
  [theme.breakpoints.up("sm")]: {
    padding: "5px 20% 0px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "5px 30% 0px",
  },
  // backgroundColor: "#ffda83",
}));

export const StepperLayout = {
  Container: OuterContainer,
  Header,
  ScrollableContent,
  Footer,
};
