import { createTheme, Theme } from "@mui/system";
import { breakpoints } from "./_global/theme/breakpoints";
import {
  black, blue, grey, red, white, orange, green,
} from "./_global/theme/colors";
import { themePalette } from "./_global/theme/colors.figma";

// TODO:
// original palette schema is being deprecated
// 'themePalette' will be the basis of the new palette schema
// I am leaving the original palette schema in place for now
// so that we can migrate to the new schema in a controlled way
// new components should use the new schema, it will be on 'theme.palette.v2' until we can complete transition

const primary = {
  light: blue[100],
  main: blue[500],
  dark: blue[900],
  contrastText: white,
  shade: {
    0: blue[700],
    1: blue[400],
    2: blue[300],
    3: blue[200],
    4: blue[100],
    5: grey[75],
  },
};

const secondary = {
  light: blue[50],
  main: blue[100],
  contrastText: blue[500],
};

export const theme: Theme = createTheme({
  palette: {
    primary,
    secondary,
    action: {
      disabled: {
        main: grey[300],
        contrastText: white,
      },
    },
    shadow: {
      0: "none",
      1: "0px 0px 2px 0px #0000001a, 0px 2px 4px 0px #0000001a",
      2: "0px 0px 3px 1px #0000001a, 0px 3px 5px 0px #0000001a",
      3: "0px 3px 3px -2px #00000030, 0px 3px 4px 0px #00000024",
      4: "2px 4px 5px -1px #00000047, 1px 4px 7px -3px #0000001c",
    },
    alert: {
      main: red[500],
      contrastText: white,
    },
    info: {
      light: blue[225],
    },
    warning: {
      main: orange[400],
    },
    success: {
      main: green[500],
    },
    sidebar: {
      main: grey[50],
      contrastText: black,
      active: {
        light: primary.main,
        main: secondary.light,
        contrastText: primary.dark,
        shadow: "-2px 2px 4px 0px #0000000a, 1px 1px 2px 0px #00000014",
      },
    },
    bottomBar: {
      main: grey[85],
      contrastText: grey[700],
      active: {
        light: primary.main,
        main: secondary.light,
        contrastText: primary.dark,
      },
    },
    grey,
    white,
    black,
    v2: themePalette, // TODO: remove this once we have migrated to the new palette schema
  },
  typography: {
    fontFamily: "Open Sans, Arial, sans-serif",
  },
  shape: {
    borderRadius: 20,
  },
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
});
