import * as React from "react";
import { Box } from "@mui/system";
import { ReactComponent as LogoutIcon } from "_global/assets/icons/nav/logoutIcon.svg";
import { useLogout } from "features/Auth/hooks/useLogout";
import { StyledLinkContainer } from "./DrawerNav.elements";

export const LogoutButton: React.FC = () => {
  const { handleLogout } = useLogout();

  return (
    <StyledLinkContainer
      onClick={handleLogout}
      component="button"
      aria-label="Logout"
      style={{ backgroundColor: "unset" }}
    >
      <Box className="iconContainer">
        <LogoutIcon />
      </Box>
      Logout
    </StyledLinkContainer>
  );
};
