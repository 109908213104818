import { isBlank } from "_global/form/validation";
import { FIELD_KEYS, HOMEBUYER_JOURNEY_STAGE_IDS } from "./pages/constants";
import type { User } from "_global/types";
import type { FieldConfig, FormConfig } from "_global/form/hooks/types";
import type { THomebuyerJourneyStage } from "./types";

export const getUserInfoFormConfig = (user: User | null): FormConfig => [
  {
    id: FIELD_KEYS.firstName,
    initialValue: user?.firstName || "",
    validations: [
      (value: string) => isBlank(value) ? "First name is required." : "",
    ],
  } as FieldConfig<string>,
  {
    id: FIELD_KEYS.lastName, 
    initialValue: user?.lastName || "",
    validations: [
      (value: string) => isBlank(value) ? "Last name is required." : "",
    ],
  } as FieldConfig<string>,
  {
    id: FIELD_KEYS.homebuyerJourneyStage,
    initialValue: HOMEBUYER_JOURNEY_STAGE_IDS.browsing,
    validations: [
      (value: THomebuyerJourneyStage): string => !value ? "Please select an option." : "",
    ],
  } as FieldConfig<THomebuyerJourneyStage>,
] as FormConfig;

export const getShouldShowNotificationsPage = (homebuyerJourneyStage: THomebuyerJourneyStage) => (
  [
    HOMEBUYER_JOURNEY_STAGE_IDS.inContract,
    HOMEBUYER_JOURNEY_STAGE_IDS.preparingForContract,
  ].includes(homebuyerJourneyStage)
);
