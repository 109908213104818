import { UserInfoForm } from "./UserInfoForm";
import { NotificationsOptInPage } from "./NotificationsOptInPage";
import { SubscribeToNewsletterPage } from "./SubscribeToNewsletterPage";
import { NextSteps } from "./NextSteps";

export const Pages = {
  UserInfoForm,
  NotificationsOptInPage,
  SubscribeToNewsletterPage,
  NextSteps,
};
