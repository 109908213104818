import * as React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { routes } from "features/Navigation/util/routes";

type ProtectedRouteProps = {
  component: React.FC;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
}) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    // isLoading state is handled by parent component, this is just a fallback
    return <span>Loading...</span>;
  }

  if (!isAuthenticated) {
    const redirectTo = routes.login.path;
    return <Navigate to={redirectTo} />;
  }

  const Component = component;

  return <Component />;
};
