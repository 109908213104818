import { SyncAltOutlined } from "@mui/icons-material";
import { SectionTile } from "_global/components/Containers";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { MatchingSupportForm } from "./MatchingSupportForm";
import { TasksController } from "features/Tasks/controllers/TasksController";

const TITLE = "Matching Support";
const DESCRIPTION = "Do you use a different email address to speak to Pahlisch? Help us match you with your details by adding the email address you use to speak with your Pahlisch agent here.";

export const MatchingSupportSection = () => {
  const { user } = useGlobalContext();
  const shouldShowMatchingSupport = !!user && user.hasContractMismatch;
  if (!shouldShowMatchingSupport) return null;

  return (
    <SectionTile.Container variant="info">
      <SectionTile.Header
        title={TITLE}
        icon={SyncAltOutlined}
        iconColorVariant="info"
        description={DESCRIPTION}
      />
      <TasksController component={MatchingSupportForm} />
    </SectionTile.Container>
  );
};
