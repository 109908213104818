import * as React from "react";
import { BottomDrawerModal } from "_global/components/Drawers/BottomDrawer";
import { Box, Stack, styled } from "@mui/system";
import { EditOutlined, LaunchOutlined, Delete as DeleteIcon, } from "@mui/icons-material";
import {
  Menu,
  menuItemClasses,
  MenuItem,
  Button as BaseButton,
} from "@mui/base";

import { themeFonts } from "_global/theme/typography";
import { TSavedDesign } from "features/DesignStudio/types";
import { getLoadDesignHref } from "./utils";
import { formatDateTimeDisplay } from "../../utils";

export const SAVED_DESIGNS_DRAWER_ID = "saved-designs-options-drawer";

type Props = {
  open: boolean;
  onClose: () => void;
  savedDesign: TSavedDesign;
  onDelete: () => void;
  onClickEditName: () => void;
};

export const SavedDesignsListBottomDrawer: React.FC<Props> = ({
  open,
  onClose,
  savedDesign,
  onDelete,
  onClickEditName,
}) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  const loadDesignHref = getLoadDesignHref(savedDesign.id);

  return (
    <BottomDrawerModal
      id={SAVED_DESIGNS_DRAWER_ID}
      title="Options"
      hideTitle
      open={open}
      onClose={onClose}
    >
      <Stack gap="28px" py="20px">
        <StyledTitle>Options</StyledTitle>
        <StyledDesignTitle>
          {savedDesign.name} | <StyledDate>Last updated: {formatDateTimeDisplay(savedDesign.updated_at)}</StyledDate>
        </StyledDesignTitle>
        <Menu slots={{ listbox: Listbox }}>
          {/* Edit Name */}
          <MenuItem
            label="Edit design name"
            tabIndex={0}
            slots={{ root: MenuItemRoot }}
            onClick={onClickEditName}
          >
            <EditOutlined />
            <Box>Edit design name</Box>
          </MenuItem>
          {/* Load Design -- slot props make it render as an <a href="..." /> anchor tag */}
          <MenuItem
            label="Load design in Design Studio"
            tabIndex={0}
            slots={{ root: MenuItemRoot }} 
            slotProps={{
              root: () => ({
                href: loadDesignHref,
                target: "_blank",
              })
            }}
            onClick={onClose}
          >
            <LaunchOutlined />
            <Box>Load</Box>
          </MenuItem>
          {/* Delete Design */}
          <MenuItem
            label="Delete design"
            tabIndex={0}
            slots={{ root: MenuItemRoot }}
            onClick={handleDelete}
          >
            <DeleteIcon />
            <Box>Delete</Box>
          </MenuItem>
        </Menu>
      </Stack>
    </BottomDrawerModal>
  );
};

const StyledTitle = styled(Box)(({ theme }) => ({
  ...themeFonts.textLgSemibold,
  color: theme.palette.v2.neutral[90],
}));

const StyledDesignTitle = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "block",
  alignItems: "center",
  gap: "4px",
  ...themeFonts.textMdSemibold,
  letterSpacing: "0.25px",
  color: theme.palette.black,
  overflow: "hidden",
  textOverflow: "ellipsis",
  minWidth: "0px",
  whiteSpace: "nowrap",
}));

const StyledDate = styled("span")(() => ({
  ...themeFonts.textSmSemibold,
}));

const Listbox = styled("div")(() => ({
  boxSizing: "border-box",
  minWidth: "200px",
  overflow: "auto",
  padding: "0",
  margin: "0",
}));

const MenuItemRoot = styled(BaseButton)(({ theme }) => ({
  color: theme.palette.v2.neutral[90],
  ...themeFonts.textLgSemibold,
  padding: "24px",
  margin: "0px -18px",
  display: "flex",
  alignItems: "center",
  lineHeight: 1,
  gap: "24px",
  cursor: "pointer",
  backgroundColor: "transparent",
  width: "100%",
  border: "none",
  textDecoration: "unset",
  borderBottom: `1px solid ${theme.palette.v2.neutral[40]}`,
  ":last-child": {
    borderBottom: "none",
  },
  [`&.${menuItemClasses.focusVisible}`]: {
    outline: `2px solid ${theme.palette.v2.primary.border}`,
    outlineOffset: "-20px",
    backgroundColor: theme.palette.v2.neutral[10],
    color: theme.palette.v2.neutral[90],
  },

  [`&.${menuItemClasses.disabled}`]: {
    cursor: "not-allowed",
    color: theme.palette.v2.neutral[60],
  },

  [`&:hover:not(.${menuItemClasses.disabled})`]: {
    backgroundColor: theme.palette.v2.primary.surface,
    color: theme.palette.v2.primary.pressed,
  },
}));
