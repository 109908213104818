import Grid from "@mui/system/Unstable_Grid/Grid";
import type { GridTypeMap } from "@mui/system";

const DEBUG_LAYOUT_MODE = false;
const DEBUG_COLORS = ["#6c6cf8", "#a2ff6d", "#009dff", "#ffa200", "#ff0022", "#ff00ff", "#739d83", "#a469a7", "#00ff00"];
const getDebugLayoutStyles = (hexIdx = 7) => 
  DEBUG_LAYOUT_MODE ? { backgroundColor: DEBUG_COLORS[hexIdx] || "#00ff00" } : {};

const MAX_COLS = 12;
const COLSPAN_7 = 7;
const COLSPAN_5 = 5;

type ContainerProps = {
  children: React.ReactNode;
};

export const GridContainer: React.FC<ContainerProps> = ({ children }) => (
  <Grid
    container
    columns={{
      xs: 1,
      lg: MAX_COLS
    }}
    width="100%"
    columnSpacing={{ xs: 0, lg: 3 }}
    rowSpacing={{ xs: 10 }}
    sx={getDebugLayoutStyles(0)}
  >
    {children}
  </Grid>
);

type GridItemCoreProps = GridTypeMap["props"] & {
  children: React.ReactNode;
  debugId?: number;
};

const GridItemCore: React.FC<GridItemCoreProps> = ({
  children,
  debugId,
  sx,
  ...gridProps
}) => {
  const debugStyles = getDebugLayoutStyles(debugId);
  const _sx = !DEBUG_LAYOUT_MODE ? sx : { ...sx, ...debugStyles };

  return (
    <Grid sx={_sx} {...gridProps}>
      {children}
    </Grid>
);
};

type GridItemProps = GridItemCoreProps & {
  fullRow?: boolean;
  large?: boolean;
  small?: boolean;
};

const getColSpanPreset = (large?: boolean, small?: boolean) => {
  if (large) return COLSPAN_7;
  if (small) return COLSPAN_5;
  return MAX_COLS;
};

const GridItem: React.FC<GridItemProps> = ({
  fullRow, // if true, will span all columns in all breakpoints
  large, // if true, will span 7 of 12 columns in large breakpoint
  small, // if true, will span 5 of 12 columns in large breakpoint
  ...gridProps
}) => (
  <GridItemCore
    xs={MAX_COLS}
    lg={fullRow ? MAX_COLS : getColSpanPreset(large, small)}
    {...gridProps} // can override xs/lg here also
  />
);

export const PageLayoutGrid = {
  GridContainer,
  GridItem,
};
