import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { TOAST_DATA } from "../constants";
import { transformSavedHomeData } from "../utils";
import type { SavedHomeDTO, TSavedHome } from "../types";
import { QUERY_KEYS } from "_global/constants";

type UseGetSavedHomesOptions = {
  enabled?: boolean;
};

export const useGetSavedHomes = (options?: UseGetSavedHomesOptions) => {
  const { enabled = true } = options || {};
  const { showErrorToast } = useToastRenderer();

  const client = useApiClient();

  const { data, isLoading, isError } = useQuery<SavedHomeDTO[], Error>({
    enabled,
    queryKey: [QUERY_KEYS.savedHomes, "all"],
    queryFn: async () => {
      const response = await client.get<SavedHomeDTO[]>("/saved-homes");
      return response.data;
    },
  });

  React.useEffect(() => {
    if (isError) showErrorToast(TOAST_DATA.fetchError);
  }, [isError]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const transformed: TSavedHome[] | null =
    data && data.map(transformSavedHomeData);

  return {
    isError,
    isLoading,
    data: transformed || null,
  };
};
