import { styled } from "@mui/system";
import { Tile } from "_global/components/Containers";

export const StyledTile = styled(Tile)(({ theme }) => ({
  justifyContent: "space-between",
  gap: "8px",
  width: "100%",
  minWidth: "100%",
  padding: "0px",
  [theme.breakpoints.up("sm")]: {
    padding: "18px 16px 6px",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "12px",
  },
}));
