import * as React from "react";
import { Box, styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import { Button as BaseButton } from "@mui/base";
import { themeFonts } from "_global/theme/typography";
import { ExpandedOptionsBottomDrawer, EXPANDED_OPTIONS_DRAWER_ID } from "./ExpandedOptionsBottomDrawer";
import type { VR360ComponentProps } from "features/DesignStudio/VR360IframeApp";

const BUTTON_LABEL = "Options";

export const ExpandedOptionsButton: React.FC<VR360ComponentProps> = (props) => {
  const [showDrawer, setShowDrawer] = React.useState(false);

  const openDrawer = () => setShowDrawer(true);
  const closeDrawer = () => setShowDrawer(false);

  return (
    <>
      <StyledButton
        onClick={openDrawer}
        aria-expanded={showDrawer}
        aria-haspopup="true"
        aria-controls={EXPANDED_OPTIONS_DRAWER_ID}
        aria-label="See expanded options"
        disabled={props.isLoading}
      >
        {props.isLoading ? <LoadingSpinner /> : <MenuIcon />}
        {BUTTON_LABEL}
      </StyledButton>
      <ExpandedOptionsBottomDrawer
        open={showDrawer}
        onClose={closeDrawer}
        {...props}
      />
    </>
  );
};

const LoadingSpinner = () => (
  <Box color="#CCC" display="flex" alignItems="center">
    <CircularProgress color="inherit" size="22px" thickness={8}   />
  </Box>
);

const StyledButton = styled(BaseButton)(({ theme }) => ({
  ...themeFonts.textMdSemibold,
  display: "inline-flex",
  padding: "8px",
  margin: "-8px",
  outline: "none",
  border: "none",
  color: theme.palette.v2.neutral[90],
  backgroundColor: "transparent",
  borderRadius: "8px",
  gap: "8px",
  alignItems: "center",
  cursor: "pointer",
  "&:hover&:not(:disabled)": {
    color: theme.palette.v2.colorTextLink,
  },
  "&:disabled": {
    color: theme.palette.v2.neutral[60],
    cursor: "not-allowed",
  },
}));
