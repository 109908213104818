import { ThemedTemplateMap } from "./types";

// Recent changes in Figma come with a new color system.
// These variables correspond with the current output of the Figma color system.
// TODO: this is hardcoded, but we can use the Figma API at some point to pull the colors from the Figma file

export const colors = {
  neutral: {
    10: "#FFFFFFFF",
    15: "#FCFCFCFF",
    20: "#F5F5F5FF",
    30: "#EDEDEDFF",
    40: "#D5D5D5FF",
    50: "#C2C2C2FF",
    60: "#A7A4A1FF",
    70: "#757575FF",
    80: "#616161FF",
    85: "#4C4A4AFF",
    90: "#222222FF",
    100: "#0A0A0AFF",
  },
  primary: {
    main: "#0085ADFF",
    surface: "#E6F4F9FF",
    border: "#10B3E4FF",
    hover: "#0B718FFF",
    pressed: "#00495FFF",
    focus: "#0085AD33",
    surfaceHover: "#C6E9F3FF",
  },
  secondary: {
    main: "#134958FF",
    surface: "#9DBEC7FF",
    border: "#28839CFF",
    hover: "#126379FF",
    pressed: "#093643FF",
    focus: "#13495833",
    surfaceHover: "#8DB3BDFF",
  },
  specific: {
    offWhite: "#EFEDEAFF",
    lightBlue1: "#EFF3F1FF",
    lightSeaGreen: "#E6F0F1FF",
    lightBlue2: "#E2F3F4FF",
    lightBlue3: "#C1DEE2FF",
    midBlue1: "#77B5C3FF",
    midBlue2: "#4E99AAFF",
  },
  danger: {
    main: "#E00000FF",
    surface: "#FFECECFF",
    border: "#FF8181FF",
    hover: "#A20000FF",
    pressed: "#630000FF",
    focus: "#E0000033",
    surfaceHover: "#FFD2D2FF",
  },
  warning: {
    main: "#F8B021FF",
    surface: "#FFF2D9FF",
    border: "#FFC759FF",
    hover: "#E59D0EFF",
    pressed: "#654F03FF",
    focus: "#F8B02133",
    surfaceHover: "#FFE9C0FF",
  },
  success: {
    main: "#287E2BFF",
    surface: "#EBF5F0FF",
    border: "#B3DAC7FF",
    hover: "#006836FF",
    pressed: "#005029FF",
    focus: "#287E2B33",
    surfaceHover: "#D4ECE0FF",
  },
  info: {
    main: "#0B5CD7FF",
    surface: "#ECF2FCFF",
    border: "#B6CFF3FF",
    hover: "#044DBAFF",
    pressed: "#043C8FFF",
    focus: "#0B5CD733",
    surfaceHover: "#DDEAFFFF",
  },
  special: {
    main: "#7F00E2FF",
    surface: "#F1DFFFFF",
    border: "#BAA7FFFF",
    hover: "#6500B4FF",
    pressed: "#440079FF",
    focus: "#7F00E233",
    surfaceHover: "#ebd1ffff",
  },
  overlayBlue: "#134958E6",
  activeCard: "#FFFFFFFF",
  infoSurfaceHover: "#DDEAFFFF",
  primarySurfaceHover: "#C6E9F3FF",
  lightTile: "#F5F5F5FF",
};

const aliases = {
  colorTextLink: colors.primary.main,
  titleText: colors.secondary.main,
  bodyText: colors.neutral[90],
  tile: colors.neutral[20],
};

const colorStyles = {
  darkGradient: "linear-gradient(90deg, linear-gradient(90deg, 0% rgba(116, 149, 172, 1), 100% rgba(46, 59, 68, 1))",
};

const shadows = {
  shadow1: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  shadow2: "0px 4px 2px 0px rgba(0, 0, 0, 0.20)",
  inputShadow: "1px 2px 2px 0px rgba(0, 0, 0, 0.12) inset",
  card: "0px 5px 10px 0px rgba(203, 202, 227, 0.3)",
  callout: "0px 20px 30px 0px rgba(203, 202, 227, 0.3)",
  appCard1: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
  appCard2: "0px 2px 8px 1px rgba(0, 0, 0, 0.12)",
  appCard3: "0px 4px 8px 2px rgba(0, 0, 0, 0.16)",
  navBarDropShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.1)",
};

export const themePalette: ThemePalette = {
  ...aliases,
  ...colorStyles,
  ...shadows,
  ...colors,
} as const;

export type ThemePalette = ThemedTemplateMap & {
  neutral: {
    10: string;
    20: string;
    30: string;
    40: string;
    50: string;
    60: string;
    70: string;
    80: string;
    90: string;
    100: string;
  };
  specific: {
    offWhite: string;
    lightBlue1: string;
    lightSeaGreen: string;
    lightBlue2: string;
    lightBlue3: string;
    midBlue1: string;
    midBlue2: string;
  };
  overlayBlue: string;
  activeCard: string;
  infoSurfaceHover: string;
  primarySurfaceHover: string;
  lightTile: string;
  colorTextLink: string;
  titleText: string;
  bodyText: string;
  tile: string;
  darkGradient: string;
  shadow1: string;
  shadow2: string;
  inputShadow: string;
  card: string;
  callout: string;
  appCard1: string;
  appCard2: string;
  appCard3: string;
  navBarDropShadow: string;
};
