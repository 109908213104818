import { Typography } from "_global/components/Typography/Typography";
import { CardListGrid, GridItem, SoldHomesListCTACard } from "./CardLists.elements";
import { HomeCard } from "../HomeCard";
import type { TSavedHome } from "../../types";
import type { DeleteHomeMutationVariables } from "features/SavedHomesListing/hooks/useDeleteSavedHomes";

export type TCardListProps = {
  savedHomes: TSavedHome[];
  deleteSavedHome?: (args: DeleteHomeMutationVariables) => void;
};

const HomeCardList = ({
  savedHomes,
  deleteSavedHome = () => undefined,
}: TCardListProps) => (
  <>
    {savedHomes.map((home) => (
      <GridItem key={home.id}>
        <HomeCard
          key={home.id}
          onDelete={() => deleteSavedHome({ id: home.id, wpId: home.wpId })}
          {...home}
        />
      </GridItem>
    ))}
  </> 
);

const ActiveSavedHomeList = (props: TCardListProps) => {
  if (!props.savedHomes.length) return (
    <Typography.Body>You have no active saved homes.</Typography.Body>
  );

  return (
    <CardListGrid>
      <HomeCardList {...props} />
    </CardListGrid>
  );
};

const InactiveSavedHomeList = (props: TCardListProps) => {
  return (
    <CardListGrid>
      <SoldHomesListCTACard />
      <HomeCardList {...props} />
    </CardListGrid>
  );
};

export const CardList = {
  ActiveHomes: ActiveSavedHomeList,
  InactiveHomes: InactiveSavedHomeList,
};
