import { useApiClient } from "_global/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { TOAST_DATA } from "./constants";
import { QUERY_KEYS } from "_global/constants";
import { getDefaultDesignName } from "./utils";
import type { TSavedDesign } from "../types";
import type { MutationCallbackOptions } from "_global/types";

interface CreateDesignDTO {
  name?: TSavedDesign["name"];
  configId: TSavedDesign["configId"];
}

interface CreateDesignVariables extends CreateDesignDTO {
  isUndoDelete?: boolean;
  isSavedFromCache?: boolean;
}

export type TCreateSavedDesignMutationFunc = (
  variables: CreateDesignVariables,
  options?: MutationCallbackOptions,
) => void;

export const useCreateSavedDesign = (renderToast = true) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { showSuccessToast, showToast, showErrorToast } = useToastRenderer();

  const createSavedDesign = async ({
    configId,
    name,
  }: CreateDesignVariables) => {
    const dto: CreateDesignDTO = {
      configId,
      name: name || getDefaultDesignName(),
    };
    return await apiClient.post("/saved-designs", dto);
  };

  const {
    data,
    isPending: isLoading,
    isSuccess,
    isError,
    mutate,
  } = useMutation({
    mutationFn: createSavedDesign,
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.savedDesigns] });

      if (renderToast) {
        const { isUndoDelete, isSavedFromCache } = variables;
        if (isSavedFromCache) {
          return showToast(TOAST_DATA.createDesignFromCacheSuccess);
        }

        const toastData = isUndoDelete
          ? TOAST_DATA.undoDeleteDesignSuccess
          : TOAST_DATA.createDesignSuccess;
        return showSuccessToast(toastData);
      }
    },
    onError: (err) => {
      console.error("useCreateSavedDesign: onError", err);
      if (renderToast) showErrorToast(TOAST_DATA.createDesignErr);
    },
  });

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};
