import { Stack } from "@mui/system";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { DashboardBannerList } from "features/Dashboard/BannerList/BannerList";
import { ProspectiveBuyerDashboard } from "features/Dashboard/ProspectiveBuyerDashboard/ProspectiveBuyerDashboard";
import { ContractedBuyerDashboard } from "features/Dashboard/ContractedBuyerDashboard/ContractedBuyerDashboard";

export const Dashboard = () => {
  const { user } = useGlobalContext();
  if (!user) return null;
  const shouldShowContractedView = user.isContractedInHubspot;

  return (
    <>
      <DashboardBannerList />
      <Stack gap="80px">
        {shouldShowContractedView && <ContractedBuyerDashboard />}
        {!shouldShowContractedView && <ProspectiveBuyerDashboard />}
      </Stack>
    </>
  );
};
