import * as React from "react";
import { styled } from "@mui/system";
import { Button as BaseButton  } from "@mui/base";
import type { SvgIconComponent } from "@mui/icons-material";
import type {ButtonProps as BaseButtonProps} from "@mui/base";

import { Badge } from "../Badges/Badge";
import { themeFonts } from "_global/theme/typography";
import type { ThemedTemplateVariants } from "_global/theme/types";

export type ButtonSize = "small" | "medium" | "large";

export enum ButtonColorMode {
  LIGHT = "light",
  DARK = "dark",
};

const options = {
  shouldForwardProp: (prop: string) => ![
    "variant", "size","colorMode","fullWidth","alignLeft","showBorder","shadow",
  ].includes(prop),
};

type StyledButtonProps = {
  size: ButtonSize;
  shadow?: boolean;
  fullWidth?: boolean;
  alignLeft?: boolean;
  colorMode?: ButtonColorMode;
  variant: ThemedTemplateVariants;
} & React.ComponentProps<typeof BaseButton>;

export const StyledButton = styled(BaseButton, options)<StyledButtonProps>(({
  theme,
  size,
  shadow,
  fullWidth,
  colorMode = ButtonColorMode.DARK,
  variant,
  alignLeft = false,
  showBorder,
}) => {
  const { palette } = theme;
  const isDark = colorMode === ButtonColorMode.DARK;

  const colors = React.useMemo(() => {
    if (isDark) return {
      text: palette.v2.neutral[10],
      textHover: palette.v2.neutral[10],
      textPressed: palette.v2.neutral[10],
      textDisabled: palette.v2.neutral[70],

      bg: palette.v2[variant].main,
      bgHover: palette.v2[variant].hover,
      bgPressed: palette.v2[variant].pressed,
      bgDisabled: palette.v2.neutral[40],

      border: showBorder ? palette.v2[variant].border : "transparent",
      borderHover: showBorder ? palette.v2[variant].focus : "transparent",
      borderPressed: showBorder ? palette.v2[variant].focus : "transparent",
      borderDisabled: showBorder ? palette.v2.neutral[50] : "transparent",
      focusOutline: palette.v2[variant].border,
    };
  
    return {
      text: palette.v2[variant].main,
      textHover: palette.v2[variant].hover,
      textPressed: palette.v2[variant].pressed,
      textDisabled: palette.v2.neutral[70],

      bg: palette.v2[variant].surface,
      bgHover: palette.v2[variant].surfaceHover,
      bgPressed: palette.v2[variant].surface,
      bgDisabled: palette.v2.neutral[40],

      border: showBorder ? palette.v2[variant].main : "transparent",
      borderHover: showBorder ? palette.v2[variant].hover : "transparent",
      borderPressed: showBorder ? palette.v2[variant].pressed : "transparent",
      borderDisabled: showBorder ? palette.v2.neutral[50] : "transparent",
      focusOutline: palette.v2[variant].border,
    };
  }, [palette, variant, isDark]);

  const fontStyles = React.useMemo(() => {
    const sizes = {
      small: themeFonts.textMdSemibold,
      medium: themeFonts.textLgBold,
      large: themeFonts.textLgBold,
    };
    return sizes[size];
  }, [size]);

  const padding = React.useMemo(() => {
    const sizes = {
      small: "2px 16px",
      medium: "6px 16px",
      large: "10px 16px",
    };
    return sizes[size];
  }, [size]);

  return {
    minWidth: "min-content",
    width: fullWidth ? "100%" : "max-content",
    maxWidth: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: alignLeft ? "flex-start" : "center",
    textAlign: alignLeft ? "left" : "center",
    gap: "8px",
    backgroundColor: colors.bg,
    borderRadius: "36px",
    border: `1.5px solid ${colors.border}`,
    padding,
    color: colors.text,
    cursor: "pointer",
    ...fontStyles,
    "&:hover&:not(:disabled)": {
      color: colors.textHover,
      borderColor: colors.borderHover,
      backgroundColor: colors.bgHover,
    },
    "&:active&:not(:disabled)": {       
      color: colors.textPressed,
      borderColor: colors.borderPressed,
      backgroundColor: colors.bgPressed,
    },
    "&:focus-visible": {
      outline: `3px solid ${colors.focusOutline}`,
    },
    "&:disabled": {
      cursor: "not-allowed",
      color: colors.textDisabled,
      borderColor: colors.borderDisabled,
      backgroundColor: colors.bgDisabled,
      ".badgeContainer": {
        opacity: .75,
      },
    },
    "&:not(:hover, :active, :disabled, :focus)": {
      boxShadow: shadow ? palette.v2.shadow1 : "none",
    },
    // Icon
    "svg": {
      fontSize: "16px",
    },
    // Badge
    ".badgeContainer": {
      marginRight: theme.spacing(1),
      height: "100%",
      minWidth: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

type CoreButtonProps = Pick<BaseButtonProps, "disabled" | "tabIndex" | "type">;

export type TButtonProps = {
  label: string;
  shadow?: boolean;
  fullWidth?: boolean;
  size?: ButtonSize;
  light?: boolean;
  variant?: ThemedTemplateVariants;
  alignLeft?: boolean;
  showBorder?: boolean;
  icon?: SvgIconComponent | (() => JSX.Element);
  badgeCount?: number | null;
  onClick?: () => void;
} & CoreButtonProps;

export const ThemeButton: React.FC<TButtonProps> = ({
  label,
  type = "button",
  shadow = true,
  fullWidth = false,
  size = "large",
  icon,
  badgeCount = null,
  light = false,
  variant = "primary",
  disabled = false,
  alignLeft,
  tabIndex,
  showBorder,
  onClick,
}) => {

  const withIcon: React.ReactNode | null = React.useMemo(() => {
    if (!icon) return null;
    const Icon = icon;
    return <Icon fontSize="inherit" />;
  }, [icon]);

  const withBadge: React.ReactNode | null = React.useMemo(() => {
    if (typeof badgeCount !== "number") return null;
    return (
      <div className="badgeContainer">
        <Badge badgeContent={badgeCount} color="alert" right="0px" />
      </div>
    );
  }, [badgeCount]);

  const tabIndexProp = React.useMemo(() => {
    if (disabled) return { tabIndex: -1 };
    if (typeof tabIndex === "number") return { tabIndex };
    return {};
  }, [disabled, tabIndex]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (type === "submit") {
      e.preventDefault(); // don't refresh the page
    }
    onClick && onClick();
  };

  return (
    <StyledButton
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
      shadow={shadow}
      colorMode={light ? ButtonColorMode.LIGHT : ButtonColorMode.DARK}
      showBorder={showBorder}
      disabled={disabled}
      alignLeft={alignLeft}
      onClick={handleClick}
      {...tabIndexProp}
    >
      {withBadge}{withIcon}{label}
    </StyledButton>
  );
};
