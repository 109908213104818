import * as React from "react";
import { Box,styled } from "@mui/system";
import { themeFonts } from "_global/theme/typography";
import {
  READ_ONLY_CLASS, DISABLED_CLASS, ACTIVE_LABEL_CLASS, ICON_LEFT_CLASS, ICON_LEFT_LABEL_CLASS,
} from "../constants";
import { getTileBackgroundColor } from "_global/components/Containers/Tile/utils";
import type { TTileVariants } from "_global/components/Containers/Tile/Tile";

type StyledRootProps= {
  error?: boolean;
  backgroundColor?: string;
  tileVariant?: TTileVariants;
  textArea?: boolean;
};

const options = {
  shouldForwardProp: (prop: string) => !["backgroundColor", "error", "tileVariant","textArea"].includes(prop),
};

export const StyledInputRoot = styled(Box, options)<StyledRootProps>(({
  theme,
  error,
  backgroundColor = "#FFF",
  tileVariant,
  textArea,
}) => {
  const innerTextStyles = themeFonts.textLg;
  const { palette } = theme;
  const errorColor = palette.v2.danger.main;

  const bgColor = React.useMemo(() => {
    if (tileVariant) return getTileBackgroundColor({ variant: tileVariant, theme });
    return backgroundColor;
  }, [tileVariant, theme, backgroundColor]);

  // darker tiles need higher contrast
  const disabledContrastColor = ["tile", "info"].includes(tileVariant || "") ? palette.v2.neutral[80] : palette.v2.neutral[60];

  const colors = React.useMemo(() => ({
    text: palette.v2.neutral[100],
    textDisabled: disabledContrastColor,
    borderHover: error ? errorColor : palette.v2.neutral[100],
    borderFocus: error ? errorColor : palette.v2.primary.main,
    borderDefault: error ? errorColor : disabledContrastColor,
    iconFocus: error ?  palette.v2.neutral[100] : palette.v2.primary.main,
    labelActive: error ? errorColor : palette.v2.neutral[100],
  }), [error]);

  const labelInactiveStyles = {
    left: "12px",
    ...(textArea && { top: "12px" }),
    overflow: "visible",
    whiteSpace: "nowrap",
    ...innerTextStyles,
  };

  const labelActiveStyles = {
    zIndex: 1,
    top: "-1px",
    left: "18px",
    transform: "translate(0%, -50%)",
    pointerEvents: "none",
    backgroundColor: bgColor,
    padding: "0px 4px",
    color: colors.labelActive,
    ...themeFonts.textSm,
  };

  return {
    backgroundColor: bgColor,
    position: "relative",
    borderRadius: "4px",
    border: `1px solid ${colors.borderDefault}`,
    display: "flex",
    gap: "4px",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "260px",
    padding: textArea ? "0px" : theme.spacing(0, 2),
    color: colors.text,
    ...innerTextStyles,
    "& label": {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      transition: "all 0.2s linear 0ms",
      pointerEvents: "none",
    },
    "&:not(:focus-within)": {
      [`& label:not(.${ACTIVE_LABEL_CLASS})`]: {
        ...labelInactiveStyles,
        [`&.${ICON_LEFT_LABEL_CLASS}`]: {
          left: "40px",
        },
      },
    },
    [`&:focus-within label, label.${ACTIVE_LABEL_CLASS}`]: {
      ...labelActiveStyles,
    },
    "&:focus-within": {
      borderColor: colors.borderFocus,
      outline: `.5px solid ${colors.borderFocus}`,
      "& svg": {
        color: colors.iconFocus,
      },
    },
    [`&:hover&:not(:focus-within)&:not(.${DISABLED_CLASS})`]: {
      borderColor: colors.borderHover,
    },
    [`&.${DISABLED_CLASS}`]: {
      cursor: "not-allowed",
      color: colors.textDisabled,
      "& label": {
        pointerEvents: "none",
        color: colors.textDisabled,
      },
      "& svg": {
        color: colors.textDisabled,
      },
    },
    [`&.${READ_ONLY_CLASS}`]: {
      borderColor: "transparent",
      backgroundColor: palette.v2.neutral[30],
    },
    [`& svg.${ICON_LEFT_CLASS}`]: {
      transform: "translate(-25%, 0%)",
    },
  };
});


export const StyledInputElement = styled("input")(() => ({
  ...themeFonts.textLg,
  flexGrow: 1,
  color: "inherit",
  background: "inherit",
  border: "none",
  borderRadius: "inherit",
  padding: "16px 0px",
  outline: 0,
  "&:disabled": {
    cursor: "not-allowed",
  },
}));

export type TTextAreaProps = {
  rows?: string | number;
};

export const StyledTextAreaElement = styled("textarea")<TTextAreaProps>(({ theme }) => ({
  ...themeFonts.textLg,
  flexGrow: 1,
  color: "inherit",
  background: "inherit",
  border: "none",
  borderRadius: "inherit",
  outline: 0,
  padding: theme.spacing(1.5,2),
  "&:disabled": {
    cursor: "not-allowed",
  },
}));
