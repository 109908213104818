import * as React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetDocuments } from "../hooks/useGetDocuments";
import type { TDocumentEnvelope } from "../types";

export type TDocumentsViewProps = {
  isError: boolean;
  isLoading: boolean;
  documentEnvelopes: TDocumentEnvelope[];
};

type DocumentsControllerProps = {
  component: React.FC<TDocumentsViewProps>;
};

export const DocumentsController: React.FC<DocumentsControllerProps> = ({
  component: Component,
}) => {
  const { user } = useGlobalContext();

  // both contracted and prospective buyers may have documents
  const shouldGetDocuments = !!user;

  const {
    data,
    isLoading,
    isError,
  } = useGetDocuments({ enabled: shouldGetDocuments });

  const documentEnvelopes = React.useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    return data;
  }, [data]);

  return (
    <Component
      documentEnvelopes={documentEnvelopes}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
