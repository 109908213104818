import * as React from "react";

import {
  VerifiedEmailIndicator,
  ResetPasswordButton,
  ResetPasswordDisplayMessage,
  ResetPasswordSection,
  AdditionalEmailsSection,
} from "./AccountEmailForm.elements";

import { SaveButton, InputRow, RowStack, StyledTextInput } from "../components/StyledFormElements";

import { useFormState } from "_global/form/hooks/useFormState";
import { useWayfinderUserController } from "../../hooks/useWayfinderUserController";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { useResetPassword } from "features/Auth/hooks/useResetPassword";

import { FIELD_KEYS } from "./constants";
import { isBlank } from "_global/form/validation";

import type { User } from "_global/types";
import type { FieldConfig, FormConfig } from "_global/form/hooks/types";
import type { UpdateUserNameFormValues } from "features/Account/hooks/useUpdateUser";

const { firstName, lastName } = FIELD_KEYS;

const getFormConfig = (user: User | null): FormConfig => [
  {
    id: firstName,
    initialValue: user?.firstName || "",
    validations: [
      (value: string) => isBlank(value) ? "First name is required." : "",
    ],
  } as FieldConfig<string>,
  {
    id: lastName, 
    initialValue: user?.lastName || "",
    validations: [
      (value: string) => isBlank(value) ? "Last name is required." : "",
    ],
  } as FieldConfig<string>,
] as FormConfig;

export const AccountEmailForm = () => {
  const {
    auth0Email,
    displayMessage,
    isVerifiedEmail,
    canUserResetPassword,
    isResetPasswordLoading,
    onClickResetPassword,
  } = useResetPassword();

  const { showSuccessToast } = useToastRenderer();

  const {
    isLoading,
    isFetching,
    updateUser,
    wayfinderUser,
  } = useWayfinderUserController();

  const formConfig = React.useMemo(() => getFormConfig(wayfinderUser), [wayfinderUser]);

  const {
    values,
    errors,
    isDirty,
    isInvalid,
    handleBlur,
    handleChange,
  } = useFormState<UpdateUserNameFormValues>(formConfig);

  const isSaveDisabled = React.useMemo(() => (
    !isDirty || !wayfinderUser || isLoading || isFetching || isInvalid
  ), [isDirty, wayfinderUser, isLoading, isFetching, isInvalid]);

  const onSave = () => {
    const onSuccess = () => showSuccessToast({ message: "User information updated." });
    if (!isInvalid) updateUser(values, { onSuccess });
  };

  const hasAdditionalEmails = !!wayfinderUser?.additionalEmails?.length;

  if (!wayfinderUser) return null;

  return (
    <RowStack as="form">
      <InputRow>
        <StyledTextInput
          id={firstName}
          label="First Name"
          disabled={isLoading}
          invalid={!!errors[firstName]}
          value={values[firstName]}
          onChange={(e) => handleChange(firstName, e.target.value)}
          onBlur={() => handleBlur(firstName)}
          helpText={errors[firstName] || ""}
        />
        <StyledTextInput
          id={lastName}
          label="Last Name"
          disabled={isLoading}
          invalid={!!errors[lastName]}
          value={values[lastName]}
          onChange={(e) => handleChange(lastName, e.target.value)}
          onBlur={() => handleBlur(lastName)}
          helpText={errors[lastName] || ""}
        />
      </InputRow>
      <InputRow>
        <StyledTextInput
          id="email"
          readOnly
          disabled
          label="Login Email"
          value={auth0Email || ""}
        />
        {isVerifiedEmail && <VerifiedEmailIndicator />}
      </InputRow>
      {canUserResetPassword && (
        <ResetPasswordSection>
          <ResetPasswordButton
            onClick={onClickResetPassword}
            disabled={isResetPasswordLoading}
          >
           {displayMessage ? "Resend Email" : "Reset Password"}
          </ResetPasswordButton>
          <ResetPasswordDisplayMessage>
            {displayMessage}  
          </ResetPasswordDisplayMessage>
        </ResetPasswordSection>
      )}
      {hasAdditionalEmails && (
        <InputRow>
          <AdditionalEmailsSection emails={wayfinderUser.additionalEmails} />
        </InputRow>
      )}
      <SaveButton disabled={isSaveDisabled} onClick={onSave} />
    </RowStack>
  );
};
