import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { StepperLayout } from "./components/StepperLayout";
import { PageHeader } from "./components/PageHeader";
import { LogoHeader } from "./components/LogoHeader";
import { LoadingPage } from "./components/LoadingPage";
import { StepperProgressDots } from "./components/StepperProgressDots";
import {
  useOnboardingControllerContext,
  OnboardingControllerContextProvider
} from "./contexts/OnboardingControllerContext";

const OnboardingStepper = () => {
  const {
    isLoading,
    totalPages,
    onClickContinue,
    currentPage,
    currentPageIndex,
    isContinueDisabled,
  } = useOnboardingControllerContext();

  const PageContent = currentPage.component;
  const buttonText = currentPage.buttonText;

  return (
    <StepperLayout.Container>
      <StepperLayout.Header>
        <LogoHeader />
      </StepperLayout.Header>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <StepperLayout.ScrollableContent page={currentPageIndex}>
            <PageHeader
              title={currentPage.title}
              description={currentPage.description}
            />
            <PageContent />
          </StepperLayout.ScrollableContent>
          <StepperLayout.Footer>
            <PrimaryButton
              fullWidth
              disabled={isContinueDisabled}
              label={buttonText}
              onClick={onClickContinue}
            />
            <StepperProgressDots currentStep={currentPageIndex + 1} totalSteps={totalPages} />
          </StepperLayout.Footer>
        </>
      )}
    </StepperLayout.Container>
  );
};

export const Onboarding = () => {
  return (
    <OnboardingControllerContextProvider>
      <OnboardingStepper />
    </OnboardingControllerContextProvider>
  );
};

