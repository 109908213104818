import * as React from "react";
import { Badge as BaseBadge, BadgeProps as BaseBadgeProps } from "@mui/base";
import { styled } from "@mui/system";

// TODO: expand color options as needed
type BadgeColor = "primary" | "secondary" | "alert";

type BadgeProps = Omit<BaseBadgeProps, "color"> & {
  color?: BadgeColor;
  top?: string | number;
  right?: string;
};

type StyledBadgeProps = React.ComponentProps<typeof BaseBadge>;

const StyledBadge = styled(BaseBadge)(({
  theme,
  color,
  top = 0,
  right = 0,
}: StyledBadgeProps) => {
  const { palette } = theme;

  const colors = React.useMemo(() => {
    const colorVals: Record<string, Record<string, string>> = {
      primary: {
        bg: palette.primary.main,
        text: palette.primary.contrastText,
      },
      secondary: {
        bg: palette.secondary.main,
        text: palette.secondary.contrastText,
      },
      alert: {
        bg: palette.alert.main,
        text: palette.alert.contrastText,
      },
    };

    const defaultColor = colorVals.primary;
    if (!color || !colorVals[color]) return defaultColor;

    return colorVals[color];
  }, [palette, color]);

  return {
    "&.MuiBadge-root": {
      position: "relative",
      display: "inline-flex",
      verticalAlign: "middle",
      backgroundColor: "blue",
      fontSize: "0.75rem",
    },
    "& > .MuiBadge-badge": {
      position: "absolute",
      top,
      right,
      zIndex: 1,
      backgroundColor: colors.bg,
      color: colors.text,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignContent: "center",
      borderRadius: "10px",
      padding: "0 6px",
      height: "20px",
      minWidth: "20px",
      lineHeight: 1,
      transform: "scale(1) translate(50%, -50%)",
      transformOrigin: "100% 0%",
      boxSizing: "border-box",
    },
  };
});


export const Badge: React.FC<BadgeProps> = (props) => {
  return (
    <StyledBadge {...props} />
  );
};
