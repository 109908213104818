import { css } from "@mui/system";

const animationName = "pulse";
const keyframeName = `@keyframes ${animationName}` as const;

type SkeletonAnimationStyles = {
  animation: string;
  [keyframeName]: Record<string, { opacity: number }>;
};

export const getSkeletonAnimationStyles = (): SkeletonAnimationStyles => ({
  animation: `${animationName} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite`,
  [keyframeName]: {
    "0%, 100%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.5,
    },
  },
});

export const truncateText = () => css({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
