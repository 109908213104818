import * as React from "react";
import { Button as BaseButton } from "@mui/base";
import { Box, styled } from "@mui/system";

import { ReactComponent as LogoutIcon } from "_global/assets/icons/nav/logoutIcon.svg";
import { SIDEBAR_WIDTH } from "../../../util/constants";
import { NavigationSidebarContext } from "../../../contexts/NavigationSidebarContext";
import { useLogout } from "features/Auth/hooks/useLogout";
import { themeFonts } from "_global/theme/typography";

const options = {
  shouldForwardProp: (prop: string) => prop !== "expanded",
};

type StyledLinkProps = {
  expanded: boolean;
};

export const StyledLink = styled(Box, options)<StyledLinkProps>(({ theme }) => {
  const { palette } = theme;

  return {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minWidth: SIDEBAR_WIDTH.collapsed,
    padding: "0px",
    color: palette.sidebar.contrastText,
    height: "50px",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    ...themeFonts.textMd,
    letterSpacing: ".5px",
    "&:hover": {
      backgroundColor: palette.sidebar.active.main,
      color: palette.sidebar.active.contrastText,
      fontWeight: 600,
      letterSpacing: "0px",
    },
    ".skeleton": {
      width: SIDEBAR_WIDTH.collapsed * 0.75,
      height: 10,
      backgroundColor: palette.grey[100],
      opacity: 0.5,
      borderRadius: 4,
    },
    ".iconContainer": {
      width: SIDEBAR_WIDTH.collapsed,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "20px",
    },
  };
});

const Icon = () => (
  <Box className="iconContainer">
    <LogoutIcon />
  </Box>
);

export const LogoutButton: React.FC = () => {
  const { handleLogout } = useLogout();
  const { expanded, transitionStatus } = React.useContext(NavigationSidebarContext);

  const isTransitioning = React.useMemo(() => (
    transitionStatus.isExpanding || transitionStatus.isCollapsing
  ), [transitionStatus]);

  const innerContent = React.useMemo(() => {
    if (isTransitioning || !expanded) {
      return <Icon />;
    }

    return (
      <>
        <Icon />
        Log Out
      </>
    );
  }, [expanded, isTransitioning]);

  return (
    <StyledLink
      onClick={handleLogout}
      title="Logout"
      component={BaseButton}
      expanded={expanded}
    >
      {innerContent}
    </StyledLink>
  );
};
