import { Stack, styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Button as BaseButton } from "@mui/base/Button";
import { Modal as BaseModal } from "@mui/base/Modal";
import { themeFonts } from "_global/theme/typography";

export const StyledModal = styled(BaseModal)(() => ({
  position: "fixed",
  zIndex: 1302,
  inset: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledContentContainer = styled(Stack)(({ theme }) => ({
  ...themeFonts.textLg,
  lineHeight: "26.5px",
  letterSpacing: "0.35px",
  position: "relative",
  width: "780px",
  maxWidth: "98%",
  minHeight: "300px",
  maxHeight: "94%",
  overflow: "auto",
  outline: "none",
  borderRadius: "8px",
  backgroundColor: theme.palette.white,
  boxShadow: theme.palette.v2.shadow2,
  padding: "28px 35px",
  "&:focus": {
    outline: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "24px",
    minWidth: "300px",
    width: "92%",
  },
}));

type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({ onClose }) => (
  <BaseButton
    className="modal-close-btn"
    aria-label="Close modal"
    onClick={onClose}
  >
    <CloseIcon fontSize="inherit" />
  </BaseButton>
);

export const StyledHeaderContainer = styled(Stack)(({ theme }) => ({
  position: "relative",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  "& .modal-close-btn": {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "all .1s cubic-bezier(.4,0,.2,1)",
    borderRadius: "4px",
    justifySelf: "flex-end",
    alignSelf: "flex-start",
    border: ".5px solid transparent",
    color: theme.palette.v2.neutral[70],
    marginRight: "-4px",
    padding: "3px",
    fontSize: "32px",
    "&:hover": {
      boxShadow: `0px 0px 2px 0px ${theme.palette.v2.colorTextLink}`,
      color: theme.palette.v2.colorTextLink,
    },
  },
}));

export const MainContentContainer = styled(Stack)(() => ({
  flexGrow: 1,
  minHeight: "min-content",
  overflowY: "auto",
}));

export const FooterContainer = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
}));
