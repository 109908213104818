import type { ToastRendererOptions } from "_global/components/Toast/types";

export const VR360URL =
  "https://apps.zondavirtual.com/vr360/index.html?id=";

export const DEFAULT_CONFIG_ID = "C8B1AEF1-62B0-40E6-887D-F30373D99F13";

export const IFRAME_ID = "vr360Iframe";
export const APP_CONTAINER_ID = "vr360";

// Unused for now. Map of possible error results -- [messageId]: message
// const VR360ErrorMap = {
//   S_001: "Success",
//   S_002: "VR360+ API connected to [IFrameId]",
//   S_003: "VR360+ Loaded!",
//   E_001: "IFrame [IFrameId] doesn't exist",
//   E_002: "The connection with the VR360+ is not established",
// };
export const INVALID_CONFIG_ID_TOAST: ToastRendererOptions = {
  id: "vr360-err-config-id",
  message: "An error occurred: invalid VR360+ config id",
  variant: "warning",
};
