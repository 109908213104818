import { LogoHeader } from "./components/LogoHeader";
import { SignUp } from "./components/SignUp";
import { LoginButton } from "./components/LoginButton";
import { ImageContainer } from "./components/ImageContainer";
import { FormContainer, PageContainer } from "./Login.elements";
import { useLogin } from "features/Auth/hooks/useLogin";
import { trackGAEvent } from "../../utils/google-analytics/google-analytics";
import { useLocation, useSearchParams } from "react-router-dom";
import {DESIGN_ROUTE, HOMES_FOR_SALE, SAVED_HOMES_ROUTE} from "../../features/DesignStudio/components/constants";
import { useEffect } from "react";
import { AppState, RedirectLoginOptions, useAuth0} from "@auth0/auth0-react";
import useTabActive from "../../features/Auth/hooks/useTabActive";

export const Login = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const isRequestFromDesign = location.state?.from && location.state?.from === DESIGN_ROUTE;
  const isRequestFromHomesForSale = from && from === HOMES_FOR_SALE;
  let handleLogin: ((params?: RedirectLoginOptions<AppState>) => Promise<void>) | (() => void);
  if (isRequestFromHomesForSale) {
    handleLogin = useLogin({ returnTo: SAVED_HOMES_ROUTE }).handleLogin;
  } else if (isRequestFromDesign) {
    handleLogin = useLogin({ returnTo: DESIGN_ROUTE }).handleLogin;
  } else {
    handleLogin = useLogin().handleLogin;
  }
  const { handleSignUp } = useLogin();
  const { getAccessTokenSilently } = useAuth0();
  const isTabActive = useTabActive();

  useEffect(() => {
    const checkForExistingToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) savedTokenLogin();
      } catch (err) { /* empty */ }
    };

    checkForExistingToken();
  }, [isTabActive]);

  const savedTokenLogin = () => {
    handleLogin();
    trackGAEvent({
      category: "Wayfinder",
      action: "login",
      label: "homes_for_sale_visit"
    });
  };

  const login = () => {
    handleLogin();
    if (isRequestFromDesign) {
      trackGAEvent({
        category: "Wayfinder",
        action: "login",
        label: "design_studio_visit",
      });
    } else {
      trackGAEvent({
        category: "Wayfinder",
        action: "login",
        label: "return_visit",
      });
    }
  };

  const signup = () => {
    handleSignUp();
    trackGAEvent({
      category: "Wayfinder",
      action: "login",
      label: "new_signup",
    });
  };

  return (
    <PageContainer>
      {/* left column with backgroundImage & wavy border */}
      <ImageContainer />
      {/* right column with login form */}
      <FormContainer>
        <LogoHeader />
        <LoginButton onClick={login} />
        <SignUp onClickSignUp={signup} />
      </FormContainer>
    </PageContainer>
  );
};
