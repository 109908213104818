import * as React from "react";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";

type LoginButtonProps = {
  onClick: () => void;
};

export const LoginButton: React.FC<LoginButtonProps> = ({ onClick }) => (
  <PrimaryButton
    fullWidth
    label="Login"
    onClick={onClick}
  />
);
