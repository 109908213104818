import * as React from "react";
import { useCreateTask, type TCreateTaskMutationFunc } from "../hooks/useCreateTask";

export type TTasksViewProps = {
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  createTask: TCreateTaskMutationFunc;
}

type TasksControllerProps = {
  component: React.FC<TTasksViewProps>;
};

export const TasksController: React.FC<TasksControllerProps> = ({
  component: Component,
}) => {
  const {
    mutate: createTask,
    isLoading,
    isError,
    isSuccess,
  } = useCreateTask();

  return (
    <Component
      createTask={createTask}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
    />
  );
};
