import { Box, Stack, styled } from "@mui/system";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { Typography } from "_global/components/Typography/Typography";
import { pahlischHomesForSaleUrl } from "../constants";
import { ReactComponent as ArrowRightIcon } from "_global/assets/icons/arrow-right-link-icon.svg";

const { H4, Paragraph, Body } = Typography;

const StyledLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
  display: "flex",
  fontSize: "15px",
  fontWeight: 700,
  "&:hover": {
    color: theme.palette.primary.shade[0],
    "& svg": {
      fill: theme.palette.primary.shade[0],
    },
  },
  "svg": {
    marginLeft: theme.spacing(1),
    fill: theme.palette.primary.main,
  },
}));

const emptyStateMessage = "You have no saved homes.";

export const EmptyState = () => (
  <Stack
    direction="column"
    justifyContent="center"
    gap="20px"
  >
    <Body>{emptyStateMessage}</Body>
    <a href={pahlischHomesForSaleUrl} aria-label="Go to Pahlisch Homes For Sale site" target="_blank">
      <PrimaryButton label="View All Homes for Sale" />
    </a>
  </Stack>
);

type TSectionHeaderProps = {
  title: string;
  description?: string;
};

type ContainerProps = {
  children?: React.ReactNode;
};

export const HeaderWrapper: React.FC<ContainerProps> = ({ children }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    flexWrap="wrap"
    gap="12px"
    sx={{
      flexDirection: { xs: "column", sm: "row" },
      alignItems: { xs: "flex-start", sm: "center" },
      maxWidth: { xs: "100%", md: "70vw" }
    }}
  >
    {children}
  </Box>
); 

export const ListSectionHeader: React.FC<TSectionHeaderProps> = ({ title, description }) => (
  <Stack gap="12px">
    <H4 semanticTag="h2">{title}</H4>
    {description && <Paragraph>{description}</Paragraph>}
  </Stack>
);

export const ViewAllHomesForSaleLink = () => (
  <StyledLink href={pahlischHomesForSaleUrl} aria-label="Go to Pahlisch Homes For Sale site" target="_blank">
    View All Homes for Sale <ArrowRightIcon height="22px" width="22px" />
  </StyledLink>
);
