import * as React from "react";
import { Box, styled } from "@mui/system";
import {
  Menu,
  Dropdown,
  useMenuButton,
  menuItemClasses,
  MenuItem as BaseMenuItem,
} from "@mui/base";
import { ExpandLess, ExpandMore, SvgIconComponent } from "@mui/icons-material";
import { ButtonColorMode, StyledButton, type TButtonProps } from "./ThemeButton";
import { fontFamily } from "_global/theme/typography";

type MenuButtonCoreProps = Pick<
  TButtonProps,
  | "disabled"
  | "tabIndex"
  | "light"
  | "label"
  | "shadow"
  | "variant"
  | "light"
  | "fullWidth"
  | "size"
>;

export type TMenuItem = {
  id?: string;
  label: string;
  icon?: SvgIconComponent;
  disabled?: boolean;
  onClick: () => void;
};

type MenuButtonProps = {
  defaultOpen?: boolean;
  menuItems: TMenuItem[];
} & MenuButtonCoreProps;

export const MenuButton: React.FC<MenuButtonProps> = ({
  defaultOpen = false,
  menuItems,
  ...buttonProps
}: MenuButtonProps) => {
  return (
    <Dropdown defaultOpen={defaultOpen}>
      <ThemeButtonCore {...buttonProps} />
      <Menu slots={{ listbox: Listbox }}>
        {menuItems.map(({label, icon: Icon, ...menuProps}) => (
          <MenuItem
            key={label}
            {...menuProps}
          >
            {!!Icon && <Icon />} {label}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

const Listbox = styled("ul")(({ theme }) => ({
  fontFamily: fontFamily,
  fontWeight: 300,
  fontSize: "15px",
  letterSpacing: "0.5px",
  boxSizing: "border-box",
  padding: "2px 0px",
  margin: "6px 0",
  minWidth: "200px",
  borderRadius: "4px",
  overflow: "auto",
  outline: "0px",
  background: theme.palette.v2.neutral[15],
  border: `1px solid ${theme.palette.v2.neutral[60]}`,
  color: theme.palette.v2.neutral[90],
  boxShadow: theme.palette.v2.shadow1,
  zIndex: 1,
}));

const MenuItem = styled(BaseMenuItem)(({ theme }) => ({
  listStyle: "none",
  padding: "16px",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  lineHeight: 1,
  gap: "12px",
  cursor: "pointer",
  borderBottom: `1px solid ${theme.palette.v2.neutral[40]}`,

  "&:last-of-type": {
    borderBottom: "none",
  },

  [`&.${menuItemClasses.focusVisible}`]: {
    outline: `2px solid ${theme.palette.v2.primary.border}`,
    outlineOffset: "-3px",
    backgroundColor: theme.palette.v2.neutral[10],
    color: theme.palette.v2.neutral[90],
  },

  [`&.${menuItemClasses.disabled}`]: {
    cursor: "not-allowed",
    color: theme.palette.v2.neutral[60],
  },

  [`&:hover:not(.${menuItemClasses.disabled})`]: {
    backgroundColor: theme.palette.v2.primary.surface,
    color: theme.palette.v2.primary.pressed,
  },
}));

const ThemeButtonCore = React.forwardRef(function MenuButton(
  {
    label,
    light = false,
    shadow = true,
    fullWidth = false,
    variant = "primary",
    size = "large",
    ...props
  }: MenuButtonCoreProps,
  forwardedRef: React.ForwardedRef<HTMLButtonElement>,
) {
  const { open, getRootProps: getButtonProps } = useMenuButton({
    rootRef: forwardedRef,
    disabled: props.disabled,
  });

  const Icon = open ? ExpandLess : ExpandMore;
  const iconSize = size === "small" ? "20px" : "25px";

  return (
    <StyledButton
      {...props}
      {...getButtonProps()}
      shadow={shadow}
      colorMode={light ? ButtonColorMode.LIGHT : ButtonColorMode.DARK}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
    >
      {label}
      <Box display="flex" alignItems="center" pl="2px" sx={{ ".chevron-icon": { fontSize: iconSize }}}>
        <Icon className="chevron-icon" fontSize="inherit" />
      </Box>
    </StyledButton>
  );
});
