import { useApiClient } from "_global/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { useCreateSavedDesign } from "./useCreateSavedDesign";
import { TOAST_DATA } from "./constants";
import { getUndoDeleteToastData } from "./utils";
import type { TSavedDesign } from "../types";
import type { MutationCallbackOptions } from "_global/types";
import { QUERY_KEYS } from "_global/constants";

export type TDeleteSavedDesignMutationFunc = (
  variables: TSavedDesign,
  options?: MutationCallbackOptions,
) => void;

type DeleteDesignApiResponse = {
  affected: number;
};

export const useDeleteSavedDesign = (renderToast = true) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { mutate: saveDesign } = useCreateSavedDesign();
  const { showToast, showErrorToast, cancelToast } = useToastRenderer();

  const deleteSavedDesign = async (
    savedDesign: TSavedDesign,
  ): Promise<DeleteDesignApiResponse> => {
    return await apiClient.delete(`/saved-designs/${savedDesign.id}`);
  };

  const {
    isPending: isLoading,
    isError,
    mutate,
  } = useMutation({
    mutationFn: deleteSavedDesign,
    onSuccess: (res, variables) => {
      const { id, name, configId } = variables;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.savedDesigns] });

      if (renderToast) {
        /* Render toast allowing user to "undo" deletion of the saved design */
        const undoId = `dx-${id}`;
        const onClickUndo = () => {
          // create new saved design with same name and configId
          saveDesign({ name, configId, isUndoDelete: true });
          cancelToast({ id: undoId });
        };

        showToast(getUndoDeleteToastData({ id: undoId, name, onClickUndo }));
      }
    },
    onError: (err) => {
      console.error("useDeleteSavedDesign: onError", err);
      if (renderToast) showErrorToast(TOAST_DATA.deleteDesignErr);
    },
  });

  return {
    isLoading,
    isError,
    mutate,
  };
};
