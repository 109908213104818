import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetNotificationPrefs } from "./useGetNotificationPrefs";
import { useUpdateNotificationPrefs } from "./useUpdateNotificationPrefs";

export const useNotificationPrefsApiController = () => {
  const { user } = useGlobalContext();

  const {
    data,
    isError,
    isLoading,
    isFetching,
  } = useGetNotificationPrefs({ enabled: !!user });

  const {
    mutate: updateNotificationPrefs,
    isError: isUpdateError,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdateNotificationPrefs();

  return {
    data,
    updateNotificationPrefs,
    isUpdateSuccess,
    isError: isError || isUpdateError,
    isFetching,
    isLoading,
    isUpdateLoading,
  };
};
