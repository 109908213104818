import { Box } from "@mui/system";
import { Tile } from "_global/components/Containers";
import { Typography } from "_global/components/Typography/Typography";
import { TextInput, RadioGroup } from "_global/components/Inputs";
import { PageContainer } from "../components/PageContainer";
import { StyledInputContainer } from "./UserInfoForm.elements";
import { useOnboardingControllerContext } from "../contexts/OnboardingControllerContext";
import { FIELD_KEYS, HOMEBUYER_JOURNEY_STAGES_OPTIONS } from "./constants";

export const UserInfoForm = () => {
  const { userInfoForm, isLoading } = useOnboardingControllerContext();
  const { values, handleChange, handleBlur, errors } = userInfoForm;
  const { firstName, lastName } = values;

  return (
    <PageContainer>
      <Tile variant="tile" maxWidth="1125px" gap="16px" alignItems="center">
        <Typography.Body semanticTag="h3">
          What is your name?
        </Typography.Body>
        <Box display="flex" gap="20px" rowGap="18px" flexWrap="wrap">
          <StyledInputContainer>
            <TextInput
              id={FIELD_KEYS.firstName}
              required
              label="First Name"
              disabled={isLoading}
              invalid={!!errors[FIELD_KEYS.firstName]}
              tileVariant="tile"
              value={firstName}
              onChange={(e) => handleChange(FIELD_KEYS.firstName, e.target.value)}
              onBlur={() => handleBlur(FIELD_KEYS.firstName)}
              helpText={errors[FIELD_KEYS.firstName] || ""}
            />
          </StyledInputContainer>
          <StyledInputContainer>
            <TextInput
              id={FIELD_KEYS.lastName}
              required
              label="Last Name"
              disabled={isLoading}
              invalid={!!errors[FIELD_KEYS.lastName]}
              tileVariant="tile"
              value={lastName}
              onChange={(e) => handleChange(FIELD_KEYS.lastName, e.target.value)}
              onBlur={() => handleBlur(FIELD_KEYS.lastName)}
              helpText={errors[FIELD_KEYS.lastName] || ""}
            />
          </StyledInputContainer>
        </Box>
      </Tile>
      <Tile variant="tile" maxWidth="1125px" gap="16px" alignItems="center">
        <Typography.Body semanticTag="h3">
          Where are you in your home buyer journey?
        </Typography.Body>
        <RadioGroup
          id={FIELD_KEYS.homebuyerJourneyStage}
          required
          hideLegend
          legend="Describe your relationship with Pahlisch Homes"
          options={HOMEBUYER_JOURNEY_STAGES_OPTIONS}
          value={values[FIELD_KEYS.homebuyerJourneyStage]}
          onChange={(val) => handleChange(FIELD_KEYS.homebuyerJourneyStage, val)}
        />
      </Tile>
    </PageContainer>
  );
};
