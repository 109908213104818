import * as React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { DesktopSherpaContent, MobileSherpaContent } from "./SherpaContentDetails.elements";
import { BottomDrawerModal } from "_global/components/Drawers/BottomDrawer";
import type { TPhaseInfo } from "../constants";

type SherpaContentProps = {
  selectedPhase: TPhaseInfo | null;
};

export const SherpaContentDetails: React.FC<SherpaContentProps> = ({
  selectedPhase,
}) => {
  const { user } = useGlobalContext();
  const isContractedBuyer = user?.isContractedInHubspot;
  const shouldHideStatus = !isContractedBuyer;

  if (!selectedPhase) return null;

  return (
    <DesktopSherpaContent.Container>
      {(selectedPhase.status && !shouldHideStatus) && (
        <DesktopSherpaContent.StatusChip status={selectedPhase.status}>
          {selectedPhase.status}
        </DesktopSherpaContent.StatusChip>
      )}
      <DesktopSherpaContent.Title>
        {selectedPhase.title}
      </DesktopSherpaContent.Title>
      {!!selectedPhase.videoEmbedSrc && (
        <DesktopSherpaContent.VideoIFrame
          height="320px"
          src={selectedPhase.videoEmbedSrc}
          title={selectedPhase.title}
        />
      )}
      {!!selectedPhase.heroImgUrl && (
        <DesktopSherpaContent.HeroImage
          src={selectedPhase.heroImgUrl}
          alt={selectedPhase.title}
        />
      )}
      <DesktopSherpaContent.Description>
        {selectedPhase.description}
      </DesktopSherpaContent.Description>
    </DesktopSherpaContent.Container>
  );
};

type SherpaContentModalProps = SherpaContentProps & {
  onClose: () => void;
};

export const SherpaContentBottomDrawerModal: React.FC<SherpaContentModalProps> = ({
  selectedPhase,
  onClose,
}) => {
  if (!selectedPhase) return null;
  return (
    <BottomDrawerModal
      hideTitle
      title={selectedPhase.title}
      onClose={onClose}
      open={!!selectedPhase}
    >
      <MobileSherpaContent.Container>
        <MobileSherpaContent.Title>
          {selectedPhase.title}
        </MobileSherpaContent.Title>
        {!!selectedPhase.videoEmbedSrc && (
          <MobileSherpaContent.VideoIFrame
            src={selectedPhase.videoEmbedSrc}
            height="280px"
            title={selectedPhase.title}
          />
        )}
        {!!selectedPhase.heroImgUrl && (
          <MobileSherpaContent.HeroImage
            height="280px"
            src={selectedPhase.heroImgUrl}
            alt={selectedPhase.title}
          />
        )}
        <MobileSherpaContent.Description>
          {selectedPhase.description}
        </MobileSherpaContent.Description>
      </MobileSherpaContent.Container>
    </BottomDrawerModal>
  );
};
