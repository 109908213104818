import * as React from "react";
import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { routes } from "features/Navigation/util/routes";

interface IUseLoginParams {
  returnTo?: string;
}

export const useLogin = ({
  returnTo = routes.root.path,
}: IUseLoginParams = {}) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = React.useCallback(
    async (params: RedirectLoginOptions = {}) => {
      try {
        await loginWithRedirect({
          appState: {
            returnTo,
          },
          ...params,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [loginWithRedirect, returnTo],
  );

  const handleSignUp = React.useCallback(() => {
    handleLogin({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  }, [handleLogin]);

  return { handleLogin, handleSignUp };
};
