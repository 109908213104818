import { PhoneChip, EmailChip, SMSChip } from "./ContactChip";
import { StyledChipGrid } from "./ContactChips.elements";

type ContactChipListProps = {
  sms?: string;
  phone?: string;
  email?: string;
};

export const ContactChipList = ({
  sms,
  phone,
  email,
}: ContactChipListProps) => {
  return (
    <StyledChipGrid container columns={3}>
      <SMSChip sms={sms} />
      <PhoneChip phone={phone} />
      <EmailChip email={email} />
    </StyledChipGrid>
  );
};

