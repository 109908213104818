import * as React from "react";
import { Button as BaseButton } from "@mui/base";
import { Stack, Box, styled } from "@mui/system";
import { StatusChip } from "./StatusChip";
import { themeFonts } from "_global/theme/typography";
import { LoadingSkeleton } from "_global/components/Skeleton/LoadingSkeleton";
import { TPhaseStatuses } from "features/ContractedHomes/types";
import type { TPhaseInfo } from "../constants";

type StyledCardButtonProps = {
  active: boolean;
};

const cardSizeProperties = {
  height: "102px",
  minWidth: "372px",
  width: "100%",
  maxWidth: "100%",
};

const carouselCardSizeProperties = {
  height: "180px",
  minWidth: "344px",
  width: "344px",
};

const StyledCardButton = styled(BaseButton, {
  shouldForwardProp: (prop: string) => !["active"].includes(prop),
})<StyledCardButtonProps>(({
  theme,
  active,
}) => {
  return {
    position: "relative",
    display: "flex",
    gap: "24px",
    padding: theme.spacing(2),
    boxShadow: active ? theme.palette.v2.shadow2 : "none",
    border: "none",
    borderRadius: "16px",
    backgroundColor: theme.palette.v2.neutral[20],
    cursor: "pointer",
    transition: "box-shadow 0.1s cubic-bezier(0.4, 0, 0.2, 1)",
    ...cardSizeProperties,
    "&:hover": {
      boxShadow: active ? theme.palette.v2.shadow2 : theme.palette.v2.shadow1,
    },
    "@media screen and (max-width: 380px)": {
      minWidth: "300px",
      maxWidth: "calc(100vw - 32px)",
    },
  };
});

const StyledTitle = styled(Box)(() => ({
  ...themeFonts.textLgSemibold,
  textAlign: "left",
  textWrap: "balance",
}));

type ThumbnailContainerProps = {
  icon: TPhaseInfo["thumbnail"];
  status: TPhaseInfo["status"];
  size?: string;
};

const StyledThumbnailContainer = styled(Stack)(() => ({
  backgroundColor: "#e3e3e3",
  padding: "10px",
  borderRadius: "8px",
  alignSelf: "center",
  alignItems: "center",
}));

const Thumbnail: React.FC<ThumbnailContainerProps> = ({ status, size ="65px", icon: Icon }) => {
  const { backgroundColor, fill } = React.useMemo(() => {
    switch (status) {
      case TPhaseStatuses["Completed"]:
        return { backgroundColor: "#E3E3E3", fill: "#4C4A4A" };
      case TPhaseStatuses["In Progress"]:
        return { backgroundColor: "#DFEBE0", fill: "#287E2B" };
      default:
        return { backgroundColor: "#1349581A", fill: "#134958" };
    }
  }, [status]);

  return (
    <StyledThumbnailContainer sx={{ height: size, width: size, backgroundColor }}>
      <Icon height="100%" width="100%" fill={fill} />
    </StyledThumbnailContainer>
  );
};

const ContentContainer = styled(Stack)(() => ({
  height: "100%",
  gap: "12px",
}));

export const Card = {
  Container: StyledCardButton,
  StatusChip,
  Thumbnail,
  Content: ContentContainer,
  Title: StyledTitle,
};

const CarouselCardContainer = styled(StyledCardButton)(({ theme }) => ({
  ...carouselCardSizeProperties,
  padding: "48px 32px",
  backgroundColor: theme.palette.v2.neutral[30],
  ".title": {
    ...themeFonts.textMdBold,
    color: theme.palette.v2.primary.main,
  },
  "&:hover": {
    ".title": {
      color: theme.palette.v2.secondary.main,
    },
  },
}));

const CarouselCardThumbnail:  React.FC<ThumbnailContainerProps> = ({ size = "88px", ...props }) => (
  <Thumbnail {...props} size={size} />
);

const CarouselCardStatusChip = styled(StatusChip)(() => ({
  position: "absolute",
  top: "0px",
  left: "29px",
  transform: "translateY(-50%)",
}));

export const CarouselCard = {
  Container: CarouselCardContainer,
  StatusChip: CarouselCardStatusChip,
  Thumbnail: CarouselCardThumbnail,
  Content: ContentContainer,
  Title: StyledTitle,
};

export const TimelineCardSkeleton = ({ carousel = false} : { carousel?: boolean }) => (
  <LoadingSkeleton
    borderRadius="16px"
    sx={{ ...(carousel ? carouselCardSizeProperties : cardSizeProperties) }}
  />
);
