import * as React from "react";
import { useWayfinderUserController } from "features/Account/hooks/useWayfinderUserController";
import { useUpdateHomebuyerJourneyStage } from "../hooks/useUpdateHomebuyerJourneyStage";
import { useUpdateUserIsOnboarded } from "../hooks/useUpdateUserIsOnboarded";
import { useNotificationPrefsApiController } from "features/Notifications/NotificationPrefs/hooks";

import type { MutationCallbackOptions } from "_global/types";
import type { UserInfoFormValues } from "../types";

export const useOnboardingApiController = () => {
  const {
    updateUser,
    isUpdateSuccess: isUpdateUserSuccess,
    isLoading: isWayfinderUserLoading,
    isError: hasWayfinderUserError,
  } = useWayfinderUserController();

  const {
    isLoading: isUpdateHomebuyerJourneyLoading,
    isSuccess: isUpdateHomebuyerJourneySuccess,
    isError: hasUpdateHomebuyerJourneyError,
    mutate: updateHomebuyerJourneyStage,
  } = useUpdateHomebuyerJourneyStage();

  const {
    data: notificationPrefsDTO,
    updateNotificationPrefs,
    ...notificationPrefs
  } = useNotificationPrefsApiController();

  const {
    mutate: updateOnboardedStatus,
    isLoading: isOnboardedStatusLoading,
    isError: hasOnboardedStatusError,
  } = useUpdateUserIsOnboarded();

  /**
   * SUBMIT USER INFO FORM -- first page of onboarding
   */
  const onSubmitUserInfoForm = React.useCallback((values: UserInfoFormValues, mutationCallbacks: MutationCallbackOptions) => {
    const { firstName, lastName, homebuyerJourneyStage } = values;
    const variables = { firstName, lastName };
  
    // After updating user name, update homebuyer journey stage & pass mutationCallback.onSuccess (ie, increment page);
    const onUserInfoUpdateSuccess = () => updateHomebuyerJourneyStage({ homebuyerJourneyStage}, mutationCallbacks);

    // updateUser is called first, then updateHomebuyerJourneyStage is called on success
    updateUser(variables, { onSuccess: onUserInfoUpdateSuccess });
  }, [updateUser, updateHomebuyerJourneyStage]);

  const isUserInfoFormSuccess = isUpdateHomebuyerJourneySuccess && isUpdateUserSuccess;

  /**
   * SUBMIT Notifications OptIn Form -- second page of onboarding (for "in contract" users)
   */
  const onSubmitNotificationsOptInForm = React.useCallback(updateNotificationPrefs, [updateNotificationPrefs]);

  /**
   * SUBMIT onCompleteOnboarding -- second to last page of onboarding, calls API setting user.isOnboarded to true
   */
  const onCompleteOnboarding = React.useCallback(({ onSuccess }: MutationCallbackOptions) => {
    updateOnboardedStatus({ isOnboarded: true }, { onSuccess });
  }, [updateOnboardedStatus]);

  const isLoading = isWayfinderUserLoading || isUpdateHomebuyerJourneyLoading || isOnboardedStatusLoading || notificationPrefs.isUpdateLoading;
  const isError = hasWayfinderUserError || hasUpdateHomebuyerJourneyError || hasOnboardedStatusError || notificationPrefs.isError;
  return {
    isUserInfoFormSuccess,
    notificationPrefsDTO,
    isLoading,
    isError,
    onSubmitUserInfoForm,
    onSubmitNotificationsOptInForm,
    onCompleteOnboarding,
  };
};
