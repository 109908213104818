import { useGlobalContext } from "_global/contexts/GlobalContext";
import { InfoTileWrapper } from "./UserInfoTile.elements";
import { HomeAddressTile } from "./HomeInfo/HomeAddressTile";
import { UserProfileTile } from "./UserInfo/UserProfileTile";
import { shouldUserSeeNotificationPrefsWarning } from "features/Account/utils";

export const UserInfoTile = () => {
  const { user } = useGlobalContext();
  if (!user) return null;

  const isContractedBuyer = user.isContractedInHubspot;
  const shouldShowNotificationWarning = shouldUserSeeNotificationPrefsWarning(user);

  return (
      <InfoTileWrapper>
        <UserProfileTile
          user={user}
          standalone={!isContractedBuyer}
          withNotifications={isContractedBuyer}
          shouldShowNotificationWarning={shouldShowNotificationWarning}
        />
        {isContractedBuyer && (
          <HomeAddressTile />
        )}
      </InfoTileWrapper>
  );
};
