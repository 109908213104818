import * as React from "react";
import { BottomDrawerModal } from "_global/components/Drawers/BottomDrawer";
import { HeroImageButtonTile, HeroImageButtonTileLoading, HomeAddressModalContent } from "./HomeAddressTileMobile.elements";
import { ContractedHomesController, type TContractedHomesViewProps } from "features/ContractedHomes/controllers/ContractedHomesController";
import type { THomeDetail } from "features/ContractedHomes/types";

const HomeTileWithModal: React.FC<THomeDetail> = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <HeroImageButtonTile
        src={props.heroImage}
        onClick={() => setShowModal(true)}
      />
      <BottomDrawerModal
        open={showModal}
        id="home-address-modal"
        onClose={() => setShowModal(false)}
        title="Home Address"
        hideTitle
      >
        <HomeAddressModalContent {...props} />
      </BottomDrawerModal>
    </>
  );
};

export const TileRenderer: React.FC<TContractedHomesViewProps> = ({
  isError,
  isLoading,
  contractedHome,
}) => {
  const didErrOut = isError && !contractedHome?.homeDetail;

  if (didErrOut) return null;

  if (isLoading) return (
    <HeroImageButtonTileLoading />
  );

  if (!contractedHome?.homeDetail|| (!contractedHome.homeDetail.heroImage && !contractedHome.homeDetail.address && !contractedHome.homeDetail.name)) return null;

  return (
    <HomeTileWithModal {...contractedHome.homeDetail} />
  );
};

export const HomeAddressTileMobileWithModal: React.FC = () => (
  <ContractedHomesController component={TileRenderer} />
);
