import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import { Button as BaseButton } from "@mui/base";
import { TextInput } from "_global/components/Inputs";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { ReactComponent as ArrowRightIcon } from "_global/assets/icons/arrow-right-link-icon.svg";
import type { TSavedDesign } from "features/DesignStudio/types";

/** This component is designed to fit into both a BottomDrawer modal and a pop-up modal */

export type EditDesignNameFormProps = {
  savedDesign: TSavedDesign;
  isLoading?: boolean;
  onSave: (name: string) => void;
  onCancel?: () => void;
  onClickBack?: () => void;
};

export const EditDesignNameForm: React.FC<EditDesignNameFormProps> = ({
  savedDesign,
  isLoading,
  onSave,
  onCancel,
  onClickBack,
}) => {
  const [newName, setNewName] = React.useState<string>(savedDesign.name || "");
  const isDirty = newName !== savedDesign.name;
  const isEmpty = newName === "";

  React.useEffect(() => {
    setNewName(savedDesign.name || "");
  }, [savedDesign]);

  const handleSave = () => {
    onSave(newName);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const shouldShowBackButton = !!onClickBack;

  return (
    <Stack gap="16px">
      {shouldShowBackButton && (
        <StyledBackButton onClick={onClickBack}>
          <ArrowLeftIcon height="22px" width="22px" />
          Back
        </StyledBackButton>
      )}
      <TextInput
        id="design-name"
        required
        label="Edit Name"
        disabled={isLoading}
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
      />
      <Stack direction={{ md: "row-reverse" }} gap="16px">
        <Box minWidth="200px">
          <PrimaryButton
            disabled={!isDirty || isEmpty}
            onClick={handleSave}
            fullWidth
            label="Save"
          />
        </Box>
        <Box minWidth="200px">
          <PrimaryButton
            light
            onClick={handleCancel}
            fullWidth
            label="Cancel"
          />
        </Box>
      </Stack>
    </Stack>
  );
};

const ArrowLeftIcon = styled(ArrowRightIcon)(() => ({
  transform: "rotate(180deg)",
}));

const StyledBackButton = styled(BaseButton)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.v2.primary.main,
  marginBottom: "16px",
  display: "flex",
  gap: "8px",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: 700,
  transition: "all 0.2s ease-in-out",
  border: "none",
  padding: "4px",
  cursor: "pointer",
  backgroundColor: "transparent",
  "&:hover": {
    color: theme.palette.v2.primary.hover,
    gap: "9px",
    "& svg": {
      fill: theme.palette.v2.primary.hover,
    },
  },
  "svg": {
    fill: theme.palette.primary.main,
  },
}));
