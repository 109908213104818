import { Stack, styled } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { Tile } from "_global/components/Containers";
import { Typography } from "_global/components/Typography/Typography";
import { CAROUSEL_ITEM_CLASSNAME } from "_global/components/Carousel";

const { H4, Paragraph } = Typography;

const StyledCTACard = styled(Tile)(({ theme }) => ({
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "343px",
  height: "100%",
  minHeight: "370px",
  marginTop: "6px",
  marginBottom: "-6px",
  padding: theme.spacing(4, 2.5),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3, 1.75),
  },
}));

export const SoldHomesListCTACard = () => (
  <StyledCTACard gap="36px">
    <Stack gap="36px">
      <H4 semanticTag="h3">Missed your saved home?</H4>
      <Stack gap="15px">
        <Paragraph>
          Oftentimes, the home you love is sold quickly.
        </Paragraph>
        <Paragraph>
          Our New Homes Specialists can let you know when the next one is going to be available.
        </Paragraph>
      </Stack>
    </Stack>
    <a
      href="https://pahlischhomes.com/newhomespecialists"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none", width: "100%" }}
    >
      <PrimaryButton fullWidth label="Contact a New Homes Specialist" />
    </a>
  </StyledCTACard>
);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(4,1.75),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(4),
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const CardListGrid = ({ children }: ContainerProps) => (
  <StyledGrid container columns={{ xs: 12 }}>
    {children}
    {/* {skeleton ? <LoadingSkeletons /> : children} */}
  </StyledGrid>
);

export const GridItem: React.FC<ContainerProps> = ({ children }) => (
  <Grid xs={12} sm={6} md={3} minWidth="min-content">
    {children}
  </Grid>
);

type ContainerProps = {
  children?: React.ReactNode;
  skeleton?: boolean;
};

export const CardCarouselInnerContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  flexDirection: "row",
  gap: "18px",
  [`& .${CAROUSEL_ITEM_CLASSNAME}`]: {
    scrollSnapAlign: "start", // snap to start of carousel
    scrollSnapStop: "always", // stop on each item
  },
  [`& .${CAROUSEL_ITEM_CLASSNAME}:last-child`]: {
    scrollSnapAlign: "end", // prevents a weird scroll bug (janky scroll animation loop)
    scrollSnapStop: "normal",
  },
}));
