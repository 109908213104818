import * as React from "react";
import { useDrawerTransition, DrawerTransitionStatus } from "../hooks/useDrawerTransition";

export interface IDrawerContextValue {
  open: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  transitionStatus: DrawerTransitionStatus;
}

export const DrawerContext = React.createContext<IDrawerContextValue>({
  open: true,
  openDrawer: () => undefined,
  closeDrawer: () => undefined,
  transitionStatus: {} as DrawerTransitionStatus,
});

type DrawerContextProviderProps = {
  children: React.ReactNode;
};

export const DrawerContextProvider: React.FC<DrawerContextProviderProps> = ({
  children,
}) => {
  const {
    open,
    openDrawer,
    closeDrawer,
    transitionStatus,
  } = useDrawerTransition();

  return (
    <DrawerContext.Provider
      value={{
        open,
        openDrawer,
        closeDrawer,
        transitionStatus,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
