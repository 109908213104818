import * as React from "react";
import { ClickAwayListener } from "@mui/base";
import { useDrawerTransition } from "../hooks/useDrawerTransition";
import { Backdrop } from "_global/components/Backdrop/Backdrop";
import { StyledDrawerWrapper, StyledModal } from "./BottomDrawer.elements";
import { DrawerContent, DrawerHeader } from "./components/DrawerContent";
import { CloseButton } from "./components/CloseButton";
import { TRANSITION_DURATION } from "../util/constants";

type BottomDrawerCoreProps = {
  id?: string;
  open: boolean;
  children: React.ReactNode;
  title: string;
  hideTitle?: boolean;
  bottom?: string | number;
  maxHeight?: string;
  zIndex?: string | number;
  onClose: () => void;
};

type BottomDrawerContentProps = BottomDrawerCoreProps & {
  isClosing?: boolean;
};

const getHeaderId = (id?: string) => `${id || "drawer"}-header`;

export const DrawerContents = React.forwardRef<HTMLDivElement, BottomDrawerContentProps>(({
  id,
  children,
  title,
  hideTitle,
  onClose = () => undefined,
  ...wrapperProps
}, ref) => {
  return (
    <StyledDrawerWrapper ref={ref} {...wrapperProps}>
      <CloseButton onClick={onClose} />
      <DrawerContent>
        <DrawerHeader title={title} id={getHeaderId(id)} hideTitle={hideTitle} />
        {children}
      </DrawerContent>
    </StyledDrawerWrapper>
  );
});

// For "Nav Bottom Bar" --> wraps DrawerContents in Backdrop & ClickAwayListener for rendering a pop up menu
export const BottomDrawerWrapper: React.FC<BottomDrawerContentProps> = (props) => {
  const { open, onClose } = props;

  React.useEffect(() => {
    // adapted from MUI's Modal component implementation
    // locks body scroll & prevents bounce effect when drawer is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    };

    // cleanup function on unmount
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [open]);

  return (
    <>
      <Backdrop open={open} transitionDuration={TRANSITION_DURATION} />
      <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
        <DrawerContents {...props} />
      </ClickAwayListener>
    </>
  );
};

// For "Modal" --> wraps DrawerContents in Modal
export const BottomDrawerModal: React.FC<BottomDrawerCoreProps> = ({
  id = "modal-drawer",
  open: isModalMounted = false,
  children,
  onClose,
  ...wrapperProps
}) => {
  const [didContentMount, setDidContentMount] = React.useState(false);
  const [willDrawerExit, setWillDrawerExit] = React.useState(false);
  const isContentMounted = didContentMount && isModalMounted;

  const {
    open: isDrawerOpen,
    openDrawer,
    closeDrawer,
    transitionStatus,
  } = useDrawerTransition();

  const needsUpdate = isContentMounted && !isDrawerOpen;
  const didDrawerExit = willDrawerExit && transitionStatus.didCompleteClose;
  const shouldCloseModal = needsUpdate && didDrawerExit;
  const shouldDrawerEnter = needsUpdate && !willDrawerExit;

  React.useEffect(() => {
    if (shouldCloseModal) {
      onClose();
      setWillDrawerExit(false);
    }
    else if (shouldDrawerEnter) {
      openDrawer();
    }
  }, [shouldCloseModal, shouldDrawerEnter]);

  const handleDidContentMount = React.useCallback(() => {
    // allows content to render to DOM before transitioning in for smoother animation
    setDidContentMount(true);
  }, []);

  const handleStartClose = () => {
    setWillDrawerExit(true);
    closeDrawer();
  };

  return (
    <StyledModal
      open={isModalMounted}
      aria-labelledby={getHeaderId(id)}
      onClose={handleStartClose}
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: () => ({ open: isDrawerOpen, transitionDuration: TRANSITION_DURATION }) }}
    >
      <DrawerContents
        ref={handleDidContentMount}
        id={id}
        open={isDrawerOpen}
        isClosing={transitionStatus.isClosing}
        onClose={handleStartClose}
        {...wrapperProps}
      >
        {children}
      </DrawerContents>
    </StyledModal>
  );
};

