import * as React from "react";
import { Stack, styled } from "@mui/system";
import { ReactComponent as MapMarkerIcon } from "_global/assets/icons/map-marker.svg";
import type { TLocationDetails } from "../../types";

const StyledLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 400,
  textDecoration: "underline",
  textUnderlineOffset: "1px",
  cursor: "pointer",
  marginLeft: "8px",
  letterSpacing: "0.25px",
  "&:hover": {
    color: theme.palette.primary.shade[0],
  },
}));

export const LocationRow = ({ latLong, formattedAddress }: TLocationDetails) => {
  if (!latLong && !formattedAddress) return null;

  const mapLink = `https://www.google.com/maps/search/?api=1&query=${latLong}`;

  const { iconSize, marginLeft } = React.useMemo(() => {
    const size = 18;
    const iconBoundingBoxOffset = -(size / 3);
    return {
      iconSize: `${size}px`,
      marginLeft: `${iconBoundingBoxOffset}px`,
    };
  }, []);

  return (
    <Stack direction="row" alignItems="center" sx={{ marginLeft }}>
      <MapMarkerIcon height={iconSize} width={iconSize} />
      {!!mapLink && (
        <StyledLink title="Go to map" href={mapLink} target="_blank" rel="noreferrer">
          {formattedAddress}
        </StyledLink>
      )}
    </Stack>
  );
};
