import * as React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetSavedHomes } from "./hooks/useGetSavedHomes";
import { useDeleteSavedHome } from "./hooks/useDeleteSavedHomes";
import type { TCardListProps } from "./components/HomeCardLists/CardLists";

export type TSavedHomesViewProps = TCardListProps & {
  isError?: boolean;
  isLoading: boolean;
};

type SavedHomesListingControllerProps = {
  component: React.FC<TSavedHomesViewProps>;
};

export const SavedHomesListingController: React.FC<
  SavedHomesListingControllerProps
> = ({ component: Component }) => {
  const { user } = useGlobalContext();

  const {
    data: savedHomesData,
    isLoading,
    isError: hasFetchError,
  } = useGetSavedHomes({ enabled: !!user });

  const { isError: hasDeleteError, mutate: deleteHome } = useDeleteSavedHome();

  const savedHomes = React.useMemo(
    () => savedHomesData || [],
    [savedHomesData],
  );

  return (
    <Component
      isError={hasFetchError || hasDeleteError}
      isLoading={isLoading}
      savedHomes={savedHomes}
      deleteSavedHome={deleteHome}
    />
  );
};
