import { Stack } from "@mui/system";
import { StatusIndicator } from "_global/components/StatusIndicator/StatusIndicator";

/**
 * Property Status
 * ie, "Lot Sale","Move in ready", "Sold" etc
 */ 

type StatusRowProps = {
  propertyStatus?: string; // ie "Lot Sale", etc
};

const formatName = (name: string) => {
  if (name.toLowerCase() === "moveinready") {
    return "Move-In Ready";
  };
  if (name.toLowerCase() === "notreleased") {
    return "Not Released";
  }
  return name;
};

export const StatusRow = ({ propertyStatus }: StatusRowProps) => {
  if (!propertyStatus) return null;

  const shouldShowIndicator = propertyStatus.toLowerCase() !== "sold";
  const formattedPropertyStatus = formatName(propertyStatus);

  return (
    <Stack direction="row" alignItems="center" gap="6px" sx={{ textTransform: "capitalize" }}>
      {shouldShowIndicator && <StatusIndicator diameter="6px" variant="warning" />}
      {formattedPropertyStatus}
    </Stack>
  );
};
