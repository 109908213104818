import { PageContainer } from "../components/PageContainer";
import { Tile } from "_global/components/Containers";
import { SubscribeToNewsletterCTACard } from "features/Notifications/Marketing/SubscribeToNewsletterCTACard";

export const SubscribeToNewsletterPage = () => {
  return (
    <PageContainer>
      <Tile>
        <SubscribeToNewsletterCTACard />
      </Tile>
    </PageContainer>
  );
};
