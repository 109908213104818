import type { TCardSectionProps } from "./types";
import { routes } from "features/Navigation/util/routes";
import VR360Thumbnail from "_global/assets/images/vr360-card-thumbnail.webp";

export const SECTION_VALS: TCardSectionProps = {
  sectionTitle: "Explore Here!",
  cardText: "With a commitment to quality craftsmanship and unique design, Pahlisch Homes invites you to explore the Digital Design Studio—an unparalleled tool to bring your dream home to life. Unleash your creativity, tailor every detail, and embark on the journey of crafting a home that reflects your individual style and aspirations.",
  thumbnailUrl: VR360Thumbnail,
  thumbnailAlt: "The Pahlisch Homes VR360 Digital Design Studio",
  buttonLabel: "Design Your Home",
  linkTo: routes.designStudio.publicPath,
};
