import { styled, Stack } from "@mui/system";
import {
  MAIN_CONTENT_PADDING_LEFT_SM,
  MAIN_CONTENT_PADDING_Y_SM,
  MAIN_CONTENT_PADDING_RIGHT_SM,
  MAIN_CONTENT_PADDING_Y_XS,
  MAIN_CONTENT_PADDING_X_XS,
} from "_global/views/Layout/Layout";

export const StyledStack = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(
    -MAIN_CONTENT_PADDING_Y_SM,
    -MAIN_CONTENT_PADDING_RIGHT_SM,
    MAIN_CONTENT_PADDING_Y_SM,
    -MAIN_CONTENT_PADDING_LEFT_SM,
  ),
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(
      -MAIN_CONTENT_PADDING_Y_XS,
      -MAIN_CONTENT_PADDING_X_XS,
      MAIN_CONTENT_PADDING_Y_XS,
      -MAIN_CONTENT_PADDING_X_XS,
    ),
  },
}));
