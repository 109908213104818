import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box, styled, type BoxTypeMap, type GridTypeMap } from "@mui/system";
import { List, ListItem } from "../List";
import { themeFonts } from "_global/theme/typography";

const BORDER_RADIUS = "16px";
const BORDER_RADIUS_TOP = "16px 16px 0px 0px";
const BORDER_RADIUS_BOTTOM = "0px 0px 16px 16px";
const MAX_COLS = 20;

const OuterContainer = styled(Box)(({ theme }) => ({ 
  borderRadius: BORDER_RADIUS,
  border: `1px solid ${theme.palette.v2.neutral[50]}`,
  backgroundColor: theme.palette.v2.lightTile,
  [theme.breakpoints.down("sm")]: {
    border: "none",
    borderRadius: "0px",
    backgroundColor: "transparent",
  },
}));

const GridRowCore: React.FC<GridTypeMap["props"]> = (overrideProps) => (
  <Grid
    container
    columns={{
      xs: MAX_COLS,
    }}
    {...overrideProps}
  />
);

type StyledGridRowProps = {
  isEven?: boolean;
  isLast?: boolean;
} & GridTypeMap["props"];

const options = {
  shouldForwardProp: (prop: string) => !["isEven", "isLast"].includes(prop),
};

const StyledGridRow = styled(GridRowCore, options)<StyledGridRowProps>(({
  theme,
  isEven,
  isLast,
}) => ({
  padding: "20px 32px",
  alignItems: "center",
  backgroundColor: isEven ? "transparent" : theme.palette.v2.neutral[20],
  ".hide-in-large": {
    display: "none",
  },
  borderRadius: isLast ? BORDER_RADIUS_BOTTOM : "unset",
  [theme.breakpoints.down("md")]: {
    padding: "20px 24px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 5px 20px 20px",
    ".hide-in-small": {
      display: "none",
    },
    ".hide-in-large": {
      display: "revert",
    },
    borderTop: isEven ? "1px solid #E1E7EF" : "none",
    borderBottom: (isEven || isLast) ? "1px solid #E1E7EF" : "none",
    backgroundColor: isEven ? theme.palette.v2.neutral[20] : "transparent",
    borderRadius: "0px",
  },
}));

const StyledHeaderRow = styled(StyledGridRow)(({ theme }) => ({
  backgroundColor: theme.palette.v2.neutral[30],
  color: "#4C4A4A",
  borderRadius: BORDER_RADIUS_TOP,
  [theme.breakpoints.down("sm")]: {
    color: theme.palette.black,
    backgroundColor: "transparent",
  },
}));

const StyledCell = styled(Grid)<GridTypeMap["props"]>(() => ({
  position: "relative", // if a child has absolute positioning, it will be relative to this cell.  Also, MUI's 'visuallyHidden' style util uses absolute positioning and it will mess with the scroll position of the grid if it's not relative.
  ...themeFonts.textLg,
}));

const RowListContainer: React.FC<BoxTypeMap["props"]> = (boxProps) => (
  <Box {...boxProps} component={List} />
);

const GridRowContainer: React.FC<StyledGridRowProps> = (rowProps) => (
  <StyledGridRow {...rowProps} as={ListItem} />
);

export const GridList = {
  OuterContainer,
  HeaderRowContainer: StyledHeaderRow,
  RowListContainer,
  GridRowContainer,
  Cell: StyledCell,
};
