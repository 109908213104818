import * as React from "react";
import { TRANSITION_DURATION } from "../util/constants";

export type TransitionStatus = {
  isCollapsing: boolean;
  isExpanding: boolean;
  didCompleteExpand: boolean;
  didCompleteCollapse: boolean;
}

interface IUseSidebarTransition {
  expanded: boolean;
  toggleExpanded: () => void;
  transitionStatus: TransitionStatus;
}

export const useSidebarTransition = (): IUseSidebarTransition => {
  const [expanded, setExpanded] = React.useState(true);
  const [isTransitioning, setIsTransitioning] = React.useState(false);

  // TODO: add test, only one of these properties should be true at a time
  const transitionStatus: TransitionStatus = React.useMemo(() => (
    {
      isCollapsing: !expanded && isTransitioning,
      isExpanding: expanded && isTransitioning,
      didCompleteExpand: expanded && !isTransitioning,
      didCompleteCollapse: !expanded && !isTransitioning,
    }
  ), [expanded, isTransitioning]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
  
    if (isTransitioning) {
      timeout = setTimeout(() => {
        setIsTransitioning(false);
      }, TRANSITION_DURATION);
    }
    // cleanup function
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isTransitioning]);


  const toggleExpanded = React.useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
    setIsTransitioning(true);
  }, [expanded]);

  return {
    expanded,
    toggleExpanded,
    transitionStatus,
  };
};
