import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import { Button as BaseButton } from "@mui/base";
import CircularProgress from "@mui/material/CircularProgress";

import { ReactComponent as MapMarkerIcon } from "_global/assets/icons/map-marker.svg";
import { Tile } from "_global/components/Containers";
import { HeroImage } from "./HomeAddressTile.elements";
import { themeFonts } from "_global/theme/typography";
import type { THomeDetail } from "features/ContractedHomes/types";

const StyledTitle = styled(Box)(({ theme }) => ({
  ...themeFonts.textLgBold,
  color: theme.palette.black,
}));

const StyledAddressText = styled(Box)(({ theme }) => ({
  ...themeFonts.subtitle,
  fontSize: "19px",
  color: theme.palette.v2.neutral[100],
}));

const StyledModelText = styled(Box)(({ theme }) => ({
  ...themeFonts.headingH4,
  fontSize: "22px",
  color: theme.palette.black,
}));

const StyledLink = styled("a")(({ theme }) => ({
  textDecoration: "unset",
  ...themeFonts.textSmSemibold,
  color: theme.palette.v2.neutral[90],
  display: "flex",
  gap: "8px",
  alignItems: "start",
  "&:hover": {
    color: theme.palette.v2.secondary.main,
    "svg #Shape": { // specific to MapMarkerIcon (#Shape is the id of the path element)
      fill: theme.palette.v2.secondary.main,
    },
  },
}));

type AddressLinkProps = {
  address: string;
  coordinates: string;
};

const AddressLink: React.FC<AddressLinkProps> = ({ address, coordinates }) => (
  <StyledLink
    href={`https://www.google.com/maps/search/?api=1&query=${coordinates}`}
    target="_blank"
    title="Open in Google Maps"
  >
    <Box component={MapMarkerIcon} ml="-10px" mt="2.5px" sx={{ height: "24px"}} />
    <StyledAddressText>{address}</StyledAddressText>
  </StyledLink>
);

const AbsolutelyPositionMapMarker: React.FC = () => (
  <Tile position="absolute" right="10%" top="10%" sx={{ padding: { xs: "0px" }, borderRadius: "50%" }}>
    <MapMarkerIcon height="24px" width="24px" />
  </Tile>
);

const StyledTile = styled(Tile)(({ theme }) => ({
  display: "none",
  flexShrink: 0,
  cursor: "pointer",
  padding:  "12px",
  height: "106px",
  width: "106px",
  minHeight: "106px",
  alignItems: "center",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    padding: "12px",
  },
}));

type TileImageButtonProps = {
  onClick: () => void;
  src: string;
};

export const HeroImageButtonTile: React.FC<TileImageButtonProps> = ({ onClick, src }) => (
  <StyledTile as={BaseButton} onClick={onClick} aria-label="See home address">
    <AbsolutelyPositionMapMarker />
    <HeroImage src={src} width="100%" />
  </StyledTile>
);

export const HeroImageButtonTileLoading = () => (
  <StyledTile>
    <Stack alignItems="center" justifyContent="center" sx={{ height: "100%", width: "100%", color: "#D9D9D9" }}>
      <CircularProgress color="inherit" size="24px" thickness={5} />
    </Stack>
  </StyledTile>
);

export const HomeAddressModalContent: React.FC<THomeDetail> = ({
  name,
  model,
  address,
  coordinates,
}) => (
  <Stack gap="28px" py="30px">
    <StyledTitle>Home Address</StyledTitle>
    {/* TODO: replace with home name when available */}
    <StyledModelText>{name ? name : `Model #${model}`}</StyledModelText>
    <AddressLink address={address} coordinates={coordinates} />
  </Stack>
);
