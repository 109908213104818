import { useMutation } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import type { MutationCallbackOptions } from "_global/types";

export type TCreateTaskDTO = {
  unverifiedEmail: string;
};

export type TCreateTaskMutationFunc = (
  variables: TCreateTaskDTO,
  options?: MutationCallbackOptions,
) => void;

export const useCreateTask = () => {
  const apiClient = useApiClient();
  const { showErrorToast } = useToastRenderer();

  const createTask = async ({ unverifiedEmail }: TCreateTaskDTO) => {
    return await apiClient.post("/tasks", { unverifiedEmail });
  };

  const {
    isPending: isLoading,
    isSuccess,
    isError,
    mutate,
  } = useMutation({
    mutationFn: createTask,
    onError: (err) => {
      console.error("useCreateTask: onError", err);
      showErrorToast({ message: "Could not send notification to Pahlisch." });
    },
  });

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};
