import * as React from "react";
import { Button as BaseButton } from "@mui/base";
import { styled } from "@mui/system";
import { SvgIconComponent } from "@mui/icons-material";
import { getIconButtonThemeColors } from "./util/getButtonThemeColors";

type Size = "xs" | "small" | "medium" | "large";

type StyledIconButtonProps = {
  size: Size;
} & React.ComponentProps<typeof BaseButton>;

const options = {
  shouldForwardProp: (prop: string) => !["size"].includes(prop),
};

const StyledIconButton = styled(BaseButton, options)(({
  theme,
  size,
}: StyledIconButtonProps) => {

  const colors = React.useMemo(() => (
    getIconButtonThemeColors({ theme })
  ), [theme]);

  const width = React.useMemo(() => {
    const widths: Record<Size, number> = {
      xs: 28,
      small: 38,
      medium: 50,
      large: 64,
    };
    return `${widths[size]}px`;
  }, [size]);

  return {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.bg,
    borderRadius: "50%",
    border: "none",
    width,
    minWidth: width,
    height: width,
    minHeight: width,
    fontFamily: theme.typography.fontFamily,
    color: colors.text,
    cursor: "pointer",
    "&:hover&:not(:disabled)": {
      backgroundColor: colors.bgHover,
      color: colors.textHover,
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: .3,
    },
  };
});

export type IconButtonProps = {
  size?: Size;
  tabIndex?: number;
  ariaLabel: string;
  icon: SvgIconComponent;
  disabled?: boolean;
  tooltip?: string;
  onClick?: () => void;
};

export const IconButton: React.FC<IconButtonProps> = ({
  size = "medium",
  icon,
  tabIndex,
  ariaLabel,
  disabled = false,
  tooltip = "",
  onClick,
}) => {

  const Icon = icon;
  const fontSize = size === "xs" ? "inherit" : size;

  const tabIndexProp = React.useMemo(() => {
    if (disabled) return { tabIndex: -1 };
    if (typeof tabIndex === "number") return { tabIndex };
    return {};
  }, [disabled, tabIndex]);

  return (
    <StyledIconButton
      aria-label={ariaLabel}
      size={size}
      title={tooltip}
      disabled={disabled}
      onClick={onClick}
      {...tabIndexProp}
    >
      <Icon fontSize={fontSize} />
    </StyledIconButton>
  );
};
