import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { PageHeader as PageTitle } from "_global/components/Headers/Headers";
import { ClosingDatePageDescription } from "./ClosingDatePageDescription";
import { UserInfoTile } from "./UserInfoTile/UserInfoTile";
import { HomeAddressTileMobileWithModal } from "./UserInfoTile/HomeInfo/HomeAddressTileMobile";

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap-reverse",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-start",
  },
}));

type PageHeaderProps = {
  title: string;
  description?: string | React.ReactNode;
};

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { user } = useGlobalContext();
  if (!user) return null;

  const isContractedBuyer = user.isContractedInHubspot;

  if (!isContractedBuyer) return (
    <HeaderContainer gap="30px" rowGap="20px">
      <PageTitle {...props} />
      <UserInfoTile />
    </HeaderContainer>
  );

  return (
    <HeaderContainer gap="30px" rowGap="20px">
      <Stack gap="10px" flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
        <PageTitle title={props.title} description={<ClosingDatePageDescription />} />
        <HomeAddressTileMobileWithModal />
      </Stack>
      <UserInfoTile />
    </HeaderContainer>
  );
};
