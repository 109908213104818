import * as React from "react";
import { useLocalStorage } from "_global/hooks/useLocalStorage";
import { handleGetConfigIdResults } from "../VR360IframeApp/utils";
import type { TCreateSavedDesignMutationFunc } from "./useCreateSavedDesign";

interface IUseVR360LocalStorage {
  isAuthed: boolean;
  vr360Api?: ZondaVR360WebAppApi | null;
  debugMode?: boolean;
};

const VR360_CONFIG_STORAGE_KEY = "vr360ConfigId";

type TCachedConfigState = {
  configId: string;
};

/**
 * PARENT HOOK FOR INTERACTING WITH LOCAL STORAGE
 * */

export const useVR360Cache = () => {
  const [cachedConfig, setCachedConfig] = useLocalStorage<TCachedConfigState | null>(VR360_CONFIG_STORAGE_KEY, null);
  
  const cachedConfigId = cachedConfig?.configId || null;

  const updateCachedConfigId = React.useCallback((configId: string) => {
    setCachedConfig({ configId });
  }, [setCachedConfig]);

  const clearCachedConfig = React.useCallback(() => {
    setCachedConfig(null);
  }, [setCachedConfig]);

  return {
    cachedConfigId,
    hasUnsavedChanges: !!cachedConfigId,
    clearCachedConfig,
    updateCachedConfigId,
  };
};

/**
 * VR360_VIEW HOOK FOR CACHING SAVED DESIGN DATA
 * */

export const useCreateVR360Cache = ({
  vr360Api,
  isAuthed,
  debugMode,
}: IUseVR360LocalStorage) => {
  const { cachedConfigId, updateCachedConfigId, clearCachedConfig } = useVR360Cache();

  // cache is for unauthed users
  const shouldCacheConfigState = !isAuthed && !!vr360Api;
  const shouldDeleteCache = isAuthed && !!cachedConfigId;
  
  const cacheConfigId = () => {
    if (vr360Api) {
      vr360Api.getConfigId((result: VR360Result) => {
        const { configId, isInvalid } = handleGetConfigIdResults(result, debugMode);
        if (configId && !isInvalid) {
          updateCachedConfigId(configId);
        }
      });
    };
  };

  // cache configId on beforeunload (ie, when user leaves the page to login)
  React.useEffect(() => {
    if (shouldCacheConfigState) {
      window.addEventListener("beforeunload", cacheConfigId);
      return () => {
        window.removeEventListener("beforeunload", cacheConfigId);
      };
    };
  }), [shouldCacheConfigState];

  React.useEffect(() => {
    if (shouldDeleteCache) {
      clearCachedConfig();
    };
  }, [shouldDeleteCache]);

  return {
    cachedConfigId,
  };
};

/**
 * SAVED_DESIGNS_LIST_VIEW HOOK FOR CREATING SAVED DESIGN FROM CACHE DATA
 * */

interface IUseSaveDesignFromVR360Cache {
  createSavedDesign?: TCreateSavedDesignMutationFunc
};

export const useCreateSavedDesignFromVR360Cache = ({
  createSavedDesign,
}: IUseSaveDesignFromVR360Cache) => {
  const { cachedConfigId, clearCachedConfig } = useVR360Cache();
  
  const didRunCreateFn = React.useRef<boolean>(false);
  const [willCreateSavedDesign, setWillCreateSavedDesign] = React.useState<boolean>(false);
  const [configIdToSave, setConfigIdToSave] = React.useState<string | null>(null);
  
  const shouldCreateSavedDesign = !!cachedConfigId && !didRunCreateFn.current;
  const isCreatingSavedDesign = shouldCreateSavedDesign || willCreateSavedDesign;

  React.useEffect(() => {
    // CHECK FOR CONFIG_ID IN CACHE TO RUN CREATE_FN -- createFn works best as a side effect of a state setting operation like this
    if (shouldCreateSavedDesign) {
      setConfigIdToSave(cachedConfigId);
    };
  }, [cachedConfigId, shouldCreateSavedDesign]);

  const onSuccess = () => {
    setWillCreateSavedDesign(false);
    setConfigIdToSave(null);
    clearCachedConfig(); // delete the cache
  };

  React.useEffect(() => {
    // CREATE SAVED DESIGN FROM CACHE
    if (configIdToSave && !didRunCreateFn.current) {
      setWillCreateSavedDesign(true);
      didRunCreateFn.current = true;
  
      if (createSavedDesign) {
        createSavedDesign({ configId: configIdToSave, isSavedFromCache: true }, { onSuccess });
      }
    };
  }, [configIdToSave, createSavedDesign]);

  return {
    isCreatingSavedDesign,
  };
};
