import { Theme } from "@mui/system";
import type { TTileVariants } from "./Tile";

interface IGetTileThemeColors {
  theme: Theme;
  variant: TTileVariants;
};

export const getTileBackgroundColor = ({ theme, variant }: IGetTileThemeColors): string => {
  const { palette } = theme;

  const colorMap: Record<TTileVariants, string> = {
    tile: palette.v2.tile,
    lightTile: palette.v2.lightTile,
    neutral: palette.v2.neutral[10],
    info: palette.v2.info.surface,
  };

  return colorMap[variant];
};
