import * as React from "react";
import { EmptyState, LoadingState, CarouselCTACard } from "./SavedHomesContent.elements";
import { HomeCard } from "features/SavedHomesListing/components/HomeCard";
import { CardCarouselInnerContainer } from "features/SavedHomesListing/components/HomeCardLists/CardLists.elements";
import type { TSavedHome } from "features/SavedHomesListing/types";

const MAX_CAROUSEL_ITEMS = 6;

type CardListProps = {
  savedHomes: TSavedHome[];
};

const CardList = ({ savedHomes }: CardListProps) => {
  const shouldShowCTACard = savedHomes.length > MAX_CAROUSEL_ITEMS;
  const cardList = React.useMemo(() => savedHomes.slice(0, MAX_CAROUSEL_ITEMS), [savedHomes]);

  return (
    <CardCarouselInnerContainer>
      {cardList.map((home) => (
        <HomeCard key={home.id} {...home} />
      ))}
      {shouldShowCTACard && <CarouselCTACard />}
    </CardCarouselInnerContainer>
  );
};

export const SavedHomesContent = {
  EmptyState,
  LoadingState,
  CardList,
};
