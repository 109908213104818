import { Box, styled } from "@mui/system";
import { ReactComponent as VerticalWaveSVG } from "_global/assets/vertical-wave.svg";

const SVG_MAX_HEIGHT = 1080;

const WavyBorder = styled(VerticalWaveSVG)(({ theme }) => ({
  fill: theme.palette.primary.dark,
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  flex: 3,
  height: "100vh",
  display: "flex",
  backgroundImage: "url(/assets/login-background.webp)",
  backgroundSize: "cover",
  backgroundPosition: "39% center",
  justifyContent: "end",
  alignItems: "center",
  overflow: "hidden",
  "svg": {
    transform: "translateX(85%)",
    [`@media screen and (min-height: ${SVG_MAX_HEIGHT}px)`]: {
      transform: "translateX(85%) scale(1, 1.5)",
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.md}px)`]: {
    display: "none",
  },
}));

export const ImageContainer = () => {
  return (
    <StyledContainer>
      <WavyBorder aria-hidden="true" height="100vh" />
    </StyledContainer>
  );
};
