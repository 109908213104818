import { Box, styled } from "@mui/system";

export const StyledInputContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "48%",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

