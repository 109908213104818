import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { TOAST_DATA } from "./constants";
import type { TSavedDesign } from "../types";
import { QUERY_KEYS } from "_global/constants";

type UseGetSavedDesignsOptions = {
  enabled?: boolean;
};

export const useGetSavedDesigns = (options?: UseGetSavedDesignsOptions) => {
  const { enabled = true } = options || {};
  const { showErrorToast } = useToastRenderer();

  const client = useApiClient();

  const { data, isStale, isFetching, isLoading, isError } = useQuery<
    TSavedDesign[],
    Error
  >({
    enabled,
    queryKey: [QUERY_KEYS.savedDesigns, "all"],
    queryFn: async () => {
      const response = await client.get<TSavedDesign[]>("/saved-designs");
      return response.data;
    },
  });

  React.useEffect(() => {
    if (isError) showErrorToast(TOAST_DATA.fetchDesignsErr);
  }, [isError]);

  return {
    data,
    isStale,
    isLoading,
    isFetching,
    isError,
  };
};
