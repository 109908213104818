import * as React from "react";
import { useNotificationPrefsApiController } from "../hooks";
import type { TNotificationPrefsDTO, UpdateNotificationPrefsMutationFunc } from "../types";

export type TNotificationPrefViewProps = {
  data?: TNotificationPrefsDTO;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isUpdateSuccess: boolean;
  isUpdateLoading: boolean;
  updateNotificationPrefs: UpdateNotificationPrefsMutationFunc;
};

type NotificationPrefsControllerProps = {
  component: React.FC<TNotificationPrefViewProps>;
};

export const NotificationPrefsController: React.FC<NotificationPrefsControllerProps> = ({
  component: Component,
}) => {

  const {
    data,
    isError,
    isLoading,
    isFetching,
    isUpdateLoading,
    isUpdateSuccess,
    updateNotificationPrefs,
  } = useNotificationPrefsApiController();

  return (
    <Component
      data={data}
      updateNotificationPrefs={updateNotificationPrefs}
      isError={isError}
      isLoading={isLoading}
      isFetching={isFetching}
      isUpdateLoading={isUpdateLoading}
      isUpdateSuccess={isUpdateSuccess}
    />
  );
};
