import { Image } from "./Image";
import type { ImageProps } from "./Image";

export const Avatar = (props: ImageProps) => {
  const {
    height = "80px",
    width = "80px",
    borderRadius = "50%",
    ...imgProps
  } = props;
  return (
    <Image
      height={height}
      width={width}
      borderRadius={borderRadius}
      {...imgProps}
    />
  );
};
