import { ReactComponent as DashboardIcon } from "_global/assets/icons/nav/dashboardIcon.svg";
import { ReactComponent as DesignIcon } from "_global/assets/icons/nav/designIcon.svg";
import { ReactComponent as DocumentsIcon } from "_global/assets/icons/nav/documentsIcon.svg";
import { ReactComponent as ProfileIcon } from "_global/assets/icons/nav/profileIcon.svg";
import { ReactComponent as TeamIcon } from "_global/assets/icons/nav/teamIcon.svg";
import { ReactComponent as TimelineIcon } from "_global/assets/icons/nav/timelineIcon.svg";

export type Route = {
  path: string;
  protectedPath?: string;
  publicPath?: string;
  subRoutes?: Record<string, Route>;
};

export type NavRoute = Route & {
  shortLabel?: string;
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const routes = {
  root: {
    path: "/",
  },
  error: {
    path: "/support",
  },
  dashboard: {
    path: "/dashboard",
  },
  login: {
    path: "/login",
  },
  logout: {
    path: "/logout",
  },
  onboarding: {
    path: "/onboarding",
  },
  timeline: {
    path: "/timeline",
  },
  team: {
    path: "/team",
  },
  messages: {
    path: "/messages",
  },
  // design and design-studio are public/private version of same page,
  // TODO: adjust this config schema as needed, it's preliminary
  designStudio: {
    path: "/design-studio",
    publicPath: "/design",
  },
  documents: {
    path: "/documents",
  },
  savedHomesFrame: {
    path: "/saved-homes/:wpId",
  },
  profile: {
    path: "/profile",
    subroutes: {
      savedHomes: {
        path: "/profile/saved-homes",
      },
      notifications: {
        path: "/profile/notifications",
      },
    },
  },
};

export const navRoutes: Record<string, NavRoute> = {
  dashboard: {
    path: routes.dashboard.path,
    label: "Dashboard",
    icon: DashboardIcon,
  },
  timeline: {
    path: routes.timeline.path,
    label: "Your Way Home",
    icon: TimelineIcon,
  },
  team: {
    path: routes.team.path,
    label: "Team",
    icon: TeamIcon,
  },
  designStudio: {
    path: routes.designStudio.path,
    label: "Design Studio",
    shortLabel: "Design",
    icon: DesignIcon,
  },
  documents: {
    path: routes.documents.path,
    label: "Documents",
    icon: DocumentsIcon,
  },
  profile: {
    path: routes.profile.path,
    label: "Profile",
    icon: ProfileIcon,
  },
};

export const navRouteList: NavRoute[] = [
  navRoutes.dashboard,
  navRoutes.timeline,
  navRoutes.team,
  navRoutes.designStudio,
  navRoutes.documents,
  navRoutes.profile,
];

export const bottomNavRouteList: NavRoute[] = [
  navRoutes.dashboard,
  navRoutes.timeline,
  navRoutes.profile,
  navRoutes.designStudio,
];
