import { Box, styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { Tile } from "_global/components/Containers";
import { themeFonts } from "_global/theme/typography";

const StyledSubtitle = styled("span")(({ theme }) => ({
  ...themeFonts.subtitle,
  color: theme.palette.v2.neutral[70],
}));

export const LoadingPage = () => (
  <Tile
    flexGrow={1}
    justifyContent="center"
    alignItems="center"
    maxWidth="1125px"
    gap="16px"
    sx={{
      width: {
        xs: "100%",
        sm: "80%"
      }       
    }}
  >
    <StyledSubtitle>Please Wait...</StyledSubtitle>
    <Box sx={{ display: "flex", color: "#C9C9C9" }}>
      <CircularProgress color="inherit" />
    </Box>
  </Tile>
);
