import * as React from "react";
import { Stack, Box, styled } from "@mui/system";
import { visuallyHidden } from "@mui/utils";

const StyledStack = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2, 4),
  gap: theme.spacing(2),
  overflowY: "auto",
  height: "100%",
  maxHeight: "100%",
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "'Open Sans', Arial, sans-serif",
  lineHeight: 1,
  fontWeight: 400,
  fontSize: "22px",
  color: theme.palette.black,
}));

type DrawerContentProps = {
  children: React.ReactNode;
};

export const DrawerContent: React.FC<DrawerContentProps> = ({ children }) => {
  return (
    <StyledStack>
      <Stack minHeight="max-content">
        {children}
      </Stack>
    </StyledStack>
  );
};

type DrawerHeaderProps = {
  id: string;
  title: string;
  hideTitle?: boolean;
};

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({ title, id, hideTitle }) => {
  const attrs = hideTitle ? { style: { ...visuallyHidden } } : {};
  return (
    <StyledHeader id={id} component="h3" {...attrs}>
      {title}
    </StyledHeader>
  );
};
