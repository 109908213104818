import { Box, styled } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { BrandLogo } from "_global/components/Logo";

const LogoContainer = styled(Box)(() => ({
  maxWidth: "260px",
  width: "100%",
}));

export const LogoHeader = () => (
  <LogoContainer>
    <h1 style={visuallyHidden}>Pahlisch Homes</h1>
    <BrandLogo.White />
  </LogoContainer>
);
