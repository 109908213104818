import { useApiClient } from "_global/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { TOAST_DATA } from "./constants";
import { QUERY_KEYS } from "_global/constants";
import type { TSavedDesign } from "../types";
import type { MutationCallbackOptions } from "_global/types";

interface UpdateDesignDTO {
  name: TSavedDesign["name"];
  configId: TSavedDesign["configId"];
};

interface UpdateDesignMutationVariables extends UpdateDesignDTO {
  id: TSavedDesign["id"];
};

export type TUpdateSavedDesignMutationFunc = (
  variables: UpdateDesignMutationVariables,
  options?: MutationCallbackOptions,
) => void;

export const useUpdateSavedDesign = (renderToast = true) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { showSuccessToast, showErrorToast } = useToastRenderer();

  const updateSavedDesign = async ({
    id,
    ...updateDesignDTO
  }: UpdateDesignMutationVariables): Promise<TSavedDesign> => {
    return await apiClient.put(`/saved-designs/${id}`, { ...updateDesignDTO });
  };

  const {
    isPending: isLoading,
    isError,
    mutate,
  } = useMutation({
    mutationFn: updateSavedDesign,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.savedDesigns] });
      if (renderToast) showSuccessToast(TOAST_DATA.updateDesignSuccess);
    },
    onError: (err) => {
      console.error("useUpdateSavedDesign: onError", err);
      if (renderToast) showErrorToast(TOAST_DATA.updateDesignErr);
    },
  });

  return {
    isLoading,
    isError,
    mutate,
  };
};
