import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Box, styled } from "@mui/system";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { navRoutes } from "features/Navigation/util/routes";

const fontFamily = "Open Sans, Arial, sans-serif";

const StyledContainerOuter = styled(Stack)(({ theme }) => ({
  flex: 1,
  margin: theme.spacing(-7),
  padding: theme.spacing(10),
  alignContent: "center",
  justifyContent: "center",
  maxWidth: "1500px",
  height: "100%",
  minHeight: "100%",
  backgroundColor: "#EFEFEF",
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.white,
  },
}));

const StyledContainerInner = styled(Stack)(({ theme }) => ({
  fontFamily,
  padding: theme.spacing(8),
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "824px",
  maxHeight: "620px",
  flexShrink: 0,
  borderRadius: "16px",
  background: theme.palette.white,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 2),
    background: theme.palette.grey[50],
  },
}));

const StyledH1 = styled(Box)(({ theme }) => ({
  margin: 0,
  fontStyle: "normal",
  fontSize: "80px",
  fontWeight: 600,
  letterSpacing: "-1.76px",
  lineHeight: "55px",
  color: theme.palette.primary.main,
}));

const StyledH2 = styled(Box)(({ theme }) => ({
  margin: 0,
  fontSize: "32px",
  lineHeight: 1,
  fontWeight: 600,
  color: theme.palette.black,
}));

const StyledDescription = styled(Box)(({ theme }) => ({
  fontSize: "18px",
  margin: "0px 0px 16px 0px",
  lineHeight: 1,
  fontWeight: 400,
  color: theme.palette.black,
}));

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const goToDashboard = () => navigate(navRoutes.dashboard.path);

  return (
    <StyledContainerOuter>
      <StyledContainerInner gap="24px">
        <StyledH1 component="h1">
          404
        </StyledH1>
        <StyledH2 component="h2">
          Page Not Found
        </StyledH2>
        <StyledDescription component="p">
          The page you are looking for does not exist.
        </StyledDescription>
        <Box maxWidth="420px" sx={{ width: "100%" }}>
          <PrimaryButton fullWidth onClick={goToDashboard} label="Dashboard" />
        </Box>
      </StyledContainerInner>
    </StyledContainerOuter>
  );
};
