import { styled, Stack } from "@mui/system";
import { SherpaContentDesktopLoading } from "./components/SherpaContentDetails.elements";
import { LoadingListSkeleton } from "./components/TimelineCardList.elements";

export const TimelinePageContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "20px",
  overflowX: "auto",
  paddingBottom: "20px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: "-20px",
    marginRight: "-20px",
    paddingRight: "20px",
  },
}));

export const TimelinePageSkeleton = () => (
  <TimelinePageContainer>
    <LoadingListSkeleton />
    <SherpaContentDesktopLoading />
  </TimelinePageContainer>
);
