import * as React from "react";

import { TimelineCard } from "./TimelineCard";
import { ListContainer as PageListContainer } from "./TimelineCardList.elements";

import { getPhaseInfo } from "../utils";
import type { TPhaseInfo } from "../constants";
import type { TPhaseResponseDTO } from "features/ContractedHomes/types";

type TimelineCardListProps = {
  activePhase: TPhaseInfo | null;
  setActivePhase: (phase: TPhaseInfo) => void;
  constructionPhases: TPhaseResponseDTO;
};

export const TimelineCardList: React.FC<TimelineCardListProps> = ({
  activePhase,
  setActivePhase,
  constructionPhases,
}) => {
  return (
      <PageListContainer>
        {constructionPhases.map((phase) => {
          const phaseInfo = getPhaseInfo(phase.name);

          if (!phaseInfo) {
            console.warn(`No phase info for ${phase.name}`);
            return null;
          };

          const isActive = activePhase?.title === phaseInfo.title;
          const nextPhaseInfo = { ...phaseInfo, status: phase.status } as TPhaseInfo;
          const onClick = () => setActivePhase(nextPhaseInfo);

          return (
            <TimelineCard
              key={phaseInfo.title}
              active={isActive}
              onClick={onClick}
              title={phaseInfo.title}
              thumbnail={phaseInfo.thumbnail}
              status={phase.status}
            />
          );
        })}
      </PageListContainer>
  );
};
