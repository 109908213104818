import { useApiClient } from "_global/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SavedHomeDTO } from "../types";
import { useCreateSavedHomes } from "./useCreateSavedHomes";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { getUndoDeleteToastData } from "../utils";
import { QUERY_KEYS } from "_global/constants";

export type DeleteHomeMutationVariables = {
  id: string;
  wpId: number;
};

export const useDeleteSavedHome = (renderToast = true) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { mutate: saveHome } = useCreateSavedHomes();
  const { showToast, showErrorToast, cancelToast } = useToastRenderer();

  const deleteHome = async ({
    id,
  }: DeleteHomeMutationVariables): Promise<SavedHomeDTO> => {
    return await apiClient.delete(`/saved-homes/${id}`);
  };

  const {
    isPending: isLoading,
    isError,
    mutate,
  } = useMutation({
    mutationFn: deleteHome,
    onSuccess: (res, variables) => {
      const { id, wpId } = variables;
      const onClickUndo = () => {
        saveHome({ wpId, isUndoDelete: true });
        cancelToast({ id });
      };
      if (renderToast) showToast(getUndoDeleteToastData({ id, onClickUndo }));
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.savedHomes] });
    },
    onError: (err, variables) => {
      const { id } = variables;
      const message = "Could not delete saved home.";
      if (renderToast) showErrorToast({ id, message });
    },
  });

  return {
    isLoading,
    isError,
    mutate,
  };
};
