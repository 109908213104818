import { OnboardingViewContainer } from "views/Layout/FloatingViewLayout";
import { Onboarding as OnboardingFeature } from "features/Onboarding";

export const Onboarding = () => {
  return (
    <OnboardingViewContainer>
      <OnboardingFeature />
    </OnboardingViewContainer>
  );
};
