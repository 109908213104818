import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useContractedBuyerDashboardContext } from "features/Dashboard/contexts/ContractedBuyerDashboardContext";
import { ContractedHomesController, type TContractedHomesViewProps } from "features/ContractedHomes/controllers/ContractedHomesController";

import { Carousel } from "_global/components/Carousel";
import { SectionTile } from "_global/components/Containers";
import { CarouselControlsContainer } from "./TimelineSection.elements";
import { TimelineCard } from "features/Timeline/components/TimelineCard";
import { CarouselListContainer, TimelineCarouselListSkeleton } from "features/Timeline/components/TimelineCardList.elements";

import { PHASE_LIST_FOR_NONCONTRACTED_BUYER, type TPhaseInfo } from "features/Timeline/constants";
import type { TPhaseResponseDTO } from "features/ContractedHomes/types";
import { getPhaseInfo } from "features/Timeline/utils";
import { routes } from "features/Navigation/util/routes";

type TimelineCarouselList = {
  constructionPhases: TPhaseResponseDTO;
};

const TimelineCarouselList: React.FC<TimelineCarouselList> = ({
  constructionPhases,
}) => {
  const navigate = useNavigate();

  const {
    activePhase,
    setConstructionPhases,
  } = useContractedBuyerDashboardContext();

  React.useEffect(() => {
    setConstructionPhases(constructionPhases);
  }, [constructionPhases, setConstructionPhases]);

  return (
    <CarouselListContainer>
      {constructionPhases.map((phase) => {
        const phaseInfo = getPhaseInfo(phase.name);

        if (!phaseInfo) {
          console.warn(`No phase info for ${phase.name}`);
          return null;
        };

        const isActive = activePhase?.title === phaseInfo.title;
        const nextPhaseInfo = { ...phaseInfo, status: phase.status } as TPhaseInfo;
        const onClick = () => navigate(routes.timeline.path, { state: { activePhase: nextPhaseInfo } });

        return (
          <TimelineCard
            key={phaseInfo.title}
            carousel
            active={isActive}
            onClick={onClick}
            title={phaseInfo.title}
            thumbnail={phaseInfo.thumbnail}
            status={phase.status}
          />
        );
      })}
    </CarouselListContainer>
  );
};

const TITLE = "Your Home At A Glance";

export const TimelineSectionContent: React.FC<TContractedHomesViewProps> = ({
  isError,
  isLoading,
  contractedHome,
}) => {
  const constructionPhases = React.useMemo(() => (contractedHome?.constructionPhases || []), [contractedHome]);

  const didErrOut = isError && !constructionPhases.length;
  const hasNoHomeData = !isLoading && !constructionPhases.length;
  const shouldRenderStaticContent = didErrOut || hasNoHomeData;

  const phaseList = shouldRenderStaticContent ? PHASE_LIST_FOR_NONCONTRACTED_BUYER : constructionPhases;

  return (
    <SectionTile.Container gap="28px" maxWidth="100%" variant="lightTile" sx={{ overflow: "unset" }}>
        <Carousel.ContextWrapper>
          <Box display="flex" justifyContent="space-between" gap="8px">
            <SectionTile.Header title={TITLE} />
            <CarouselControlsContainer>
              <Carousel.ScrollButtons />
            </CarouselControlsContainer>
          </Box>
          <Carousel.ScrollContainer>
            {isLoading ? (
              <TimelineCarouselListSkeleton />
            ) : (
              <TimelineCarouselList constructionPhases={phaseList} />
            )}
          </Carousel.ScrollContainer>
        </Carousel.ContextWrapper>
    </SectionTile.Container>
  );
};

export const TimelineSection: React.FC = () => (
  <ContractedHomesController component={TimelineSectionContent} />
);
