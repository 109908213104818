import * as React from "react";

interface ICarouselContext {
  carouselInstance: HTMLDivElement | null;
  leftDisabled: boolean;
  rightDisabled: boolean;
  scrollLeft: () => void;
  scrollRight: () => void;
  handleScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  initCarouselInstance: (el: HTMLDivElement) => void;
}

type CarouselContextProviderProps = {
  children: React.ReactNode;
};

const CarouselContext = React.createContext<ICarouselContext>({
  carouselInstance: null,
  leftDisabled: true,
  rightDisabled: false,
  scrollLeft: () => undefined,
  scrollRight: () => undefined,
  handleScroll: () => undefined,
  initCarouselInstance: () => undefined,
});

export const CarouselContextProvider = ({ children }: CarouselContextProviderProps) => {
  const [carouselInstance, setCarouselInstance] = React.useState<HTMLDivElement | null>(null);
  const [leftDisabled, setLeftDisabled] = React.useState(true);
  const [rightDisabled, setRightDisabled] = React.useState(false);

  const didInit = React.useRef(false); // ref to track if carousel has been initialized without waiting for state re-render

  const initCarouselInstance = React.useCallback((el: HTMLDivElement) => {
    if (didInit.current) return;
    if (!carouselInstance) setCarouselInstance(el);
    didInit.current = true;
  }, [carouselInstance]);

  const handleScroll = React.useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, clientWidth } = event.currentTarget;
    setLeftDisabled(scrollLeft === 0);
    setRightDisabled(scrollLeft + clientWidth === scrollWidth);
  }, []);

  const scrollLeft = React.useCallback(() => {
    if (carouselInstance) {
      carouselInstance.scrollBy({
        left: -carouselInstance.clientWidth,
        behavior: "smooth",
      });
    }
  }, [carouselInstance]);

  const scrollRight = React.useCallback(() => {
    if (carouselInstance) {
      carouselInstance.scrollBy({
        left: carouselInstance.clientWidth,
        behavior: "smooth",
      });
    }
  }, [carouselInstance]);

  const calculateDisabled = React.useCallback(() => {
    if (carouselInstance) {
      const { scrollWidth, clientWidth, scrollLeft } = carouselInstance;
      if (scrollWidth <= clientWidth) {
        setLeftDisabled(true);
        setRightDisabled(true);
      } else {
        const isLeftDisabled = scrollLeft === 0;
        const isRightDisabled = (scrollLeft + clientWidth) === scrollWidth;
        setLeftDisabled(isLeftDisabled);
        setRightDisabled(isRightDisabled);
      }
    }
  }, [carouselInstance]);

  React.useEffect(() => {
    calculateDisabled();
    // add resize listener to enable disable buttons on resize
    const resizeListener = () => {
      calculateDisabled();
    };
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [calculateDisabled]);

  const value = React.useMemo(() => ({
    carouselInstance,
    leftDisabled,
    rightDisabled,
    scrollLeft,
    scrollRight,
    handleScroll,
    initCarouselInstance,
  }), [
    carouselInstance,
    leftDisabled,
    rightDisabled,
    scrollLeft,
    scrollRight,
    handleScroll,
    initCarouselInstance,
  ]);

  return (
    <CarouselContext.Provider value={value}>
      {children}
    </CarouselContext.Provider>
  );
};

export const useCarouselContext = () => React.useContext(CarouselContext);
