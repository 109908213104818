import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { BrandLogo } from "_global/components/Logo";

export const LogoHeader = () => (
  <>
    <h1 style={visuallyHidden}>Pahlisch Wayfinder Onboarding</h1>
    <Box aria-hidden="true" sx={{ width: "260px", minWidth: "260px" }}>
      <BrandLogo.Main />
    </Box>
  </>
);
