import { Box, styled } from "@mui/system";

export const CarouselControlsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignSelf: "flex-end",
  gap: "24px",
  alignItems: "center",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  marginRight: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

