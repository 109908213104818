import * as React from "react";
import { styled } from "@mui/system";
import { ImageContainer } from "./Images.elements";

const StyledImg = styled("img")(() => ({
  height: "100%",
  width: "100%",
}));

export type ImageProps = {
  src: string;
  alt: string;
  height?: string;
  width?: string;
  borderRadius?: string | number;
  objectFit?: "cover" | "contain";
  objectPosition?: string;
};

export const Image = ({
  src,
  alt,
  height = "auto",
  width = "auto",
  borderRadius = 0,
  objectFit = "cover",
  objectPosition = "50% 50%",
}: ImageProps) => {
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  
  return (
    <ImageContainer
      height={height}
      width={width}
      skeleton={isLoading}
      borderRadius={borderRadius}
    >
      <StyledImg
        src={src}
        alt={alt}
        onLoad={() => {
          setIsLoading(false);
        }}
        onError={() => {
          setError(true);
          setIsLoading(false);
        }}
        sx={{
          objectFit,
          objectPosition,
          display: isLoading ? "none" : "block",
          boxShadow: error ? "#8483832b -6px 3px 30px 0px inset" : "none",
          backgroundColor: error ? "#84838312" : "transparent",
        }}
      />
    </ImageContainer>
  );
};
