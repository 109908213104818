import * as React from "react";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { QUERY_KEYS } from "_global/constants";

type ResendVerificationResponseDTO = {
  id: string; // unique id for job initiated in Auth0 via Wayfinder API
};

export const useResendVerifyEmail = (): UseQueryResult<ResendVerificationResponseDTO, Error> => {
  const { showErrorToast, showSuccessToast } = useToastRenderer();

  const client = useApiClient();

  const queryState = useQuery<ResendVerificationResponseDTO, Error>({
    enabled: false, // enabled is false, so we can call this hook manually on button click via the `refetch` function
    queryKey: [QUERY_KEYS.auth, "resend-verification"],
    queryFn: async () => {
      const response = await client.get<ResendVerificationResponseDTO>("/users/resend_verification");
      if (response.status === 200) showSuccessToast({ message: "Email Verification request sent." });
      return response.data;
    },
  });

  const { isError } = queryState;

  React.useEffect(() => {
    if (queryState.isError) showErrorToast({ id: "err-get-resend-verification", message: "Could not send Email Verification request." });
  }, [isError]);

  return queryState;
};
