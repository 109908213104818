import * as React from "react";
import { styled, Stack } from "@mui/system";
import { getSkeletonAnimationStyles } from "_global/theme/styleUtils";
import { getTileBackgroundColor } from "./utils";

export const TileVariants = ["tile", "lightTile", "neutral", "info"] as const;
export type TTileVariants = typeof TileVariants[number];

export type StyledTileProps = {
  variant?: TTileVariants;
  showBorder?: boolean;
  skeleton?: boolean;
  renderIntrinsicSize?: boolean; // Let the browser determine the size of the Tile based on its content
};

const options = {
  shouldForwardProp: (prop: string) => !["variant","showBorder","renderIntrinsicSize","skeleton"].includes(prop),
};

/**
 * A Tile is a card-like container that can be used to group content.
 * It is a variant of the Stack component.
**/

export const Tile = styled(Stack, options)<StyledTileProps>(({
  theme,
  variant = "tile",
  showBorder = false,
  skeleton = false,
  renderIntrinsicSize = false,
}) => {
  const { palette } = theme;

  const backgroundColor = React.useMemo(() => (
    skeleton ? theme.palette.v2.tile : getTileBackgroundColor({ variant, theme })
  ), [theme, skeleton, variant]);

  const borderColor = React.useMemo(() => {
    if (!showBorder || skeleton) return "transparent";
    if (variant === "neutral") return palette.v2.primary.border;
    if (variant === "info") return palette.v2.info.border;
    return palette.v2.neutral[50]; // this is a guess
  }, [palette, showBorder, skeleton, variant]);

  const animationStyles = React.useMemo(() => {
    if (!skeleton) return { animation: "none" };
    return getSkeletonAnimationStyles();
  }, [skeleton]);

  // https://developer.mozilla.org/en-US/docs/Glossary/Intrinsic_Size
  const intrinsicSizeStyles = {
    minWidth: "min-content",
    maxWidth: "max-content",
    minHeight: "min-content",
    maxHeight: "max-content",
  };

  return {
    overflow: "auto",
    padding: theme.spacing(3,4),
    backgroundColor,
    borderRadius: "16px",
    border: `1px solid ${borderColor}`,
    transition: "background-color 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    ...(renderIntrinsicSize && intrinsicSizeStyles),
    ...animationStyles,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3,2),
    },
  };
});
