import { Stack } from "@mui/system";
import { PageHeader } from "features/PageHeader";
import { TimelinePage } from "features/Timeline/TimelinePage";

export const Timeline = () => {
  return (
    <Stack gap="40px">
      <PageHeader title="Your Way Home" />
      <TimelinePage />
    </Stack>
  );
};
