import { NotificationsOptInForm } from "features/Notifications/NotificationPrefs/NotificationsOptInForm";
import { PageContainer } from "../components/PageContainer";
import { useOnboardingControllerContext } from "../contexts/OnboardingControllerContext";

export const NotificationsOptInPage = () => {
const { notificationsOptInForm } = useOnboardingControllerContext();

  return (
    <PageContainer>
      <NotificationsOptInForm {...notificationsOptInForm} />
    </PageContainer>
  );
};
