import SvgIcon from "@mui/material/SvgIcon";

export const FindYourCommunityIcon = () => {
    return (
        <SvgIcon style={{marginRight: "7px"}}>
            <svg
                width={25}
                height={25}
                viewBox="0 0 25 25"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.432 4.173a6.5 6.5 0 100 13 6.5 6.5 0 000-13zm-9.5 6.5a9.5 9.5 0 1119 0 9.5 9.5 0 01-19 0z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.371 15.612a1.5 1.5 0 012.122 0l6 6a1.5 1.5 0 11-2.121 2.122l-6-6a1.5 1.5 0 010-2.122z"
                />
            </svg>
        </SvgIcon>
    );
};
