import * as React from "react";
import { styled, Box, type BoxProps } from "@mui/system";
import { themeFonts, fontFamily } from "_global/theme/typography";

export type TypographyProps = {
  children: React.ReactNode;
  semanticTag?: React.ElementType;
} & BoxProps;

const StyledH1 = styled(Box)(({ theme }) => ({
  margin: 0,
  color: theme.palette.v2.secondary.main,
  ...themeFonts.headingH1,
  [theme.breakpoints.down("sm")]: {
    ...themeFonts.headingH1Mobile,
  },
}));

const StyledH2 = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  fontFamily,
  fontSize: "20px",
  fontWeight: 300,
  margin: 0,
  lineHeight: 1,
}));

const StyledH3 = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  margin: 0,
  ...themeFonts.headingH3,
}));

const StyledH4 = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  margin: 0,
  ...themeFonts.headingH4,
}));

const StyledBody = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  margin: 0,
  ...themeFonts.textLg,
}));

const StyledParagraph = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[900],
  fontFamily,
  fontSize: "16px",
  fontWeight: 300,
  margin: 0,
  lineHeight: 1.4,
}));

const H1: React.FC<TypographyProps> = ({ children, semanticTag = "h1", ...boxProps }) => {
  return (
    <StyledH1 component={semanticTag} {...boxProps}>
      {children}
    </StyledH1>
  );
};

const H2: React.FC<TypographyProps> = ({ children, semanticTag = "h2", ...boxProps }) => {
  return (
    <StyledH2 component={semanticTag} {...boxProps}>
      {children}
    </StyledH2>
  );
};

const H3: React.FC<TypographyProps> = ({ children, semanticTag = "h3", ...boxProps }) => {
  return (
    <StyledH3 component={semanticTag} {...boxProps}>
      {children}
    </StyledH3>
  );
};

const H4: React.FC<TypographyProps> = ({ children, semanticTag = "h4", ...boxProps }) => {
  return (
    <StyledH4 component={semanticTag} {...boxProps}>
      {children}
    </StyledH4>
  );
};

const Body: React.FC<TypographyProps> = ({ children, semanticTag = "div", ...boxProps }) => {
  return (
    <StyledBody component={semanticTag} {...boxProps}>
      {children}
    </StyledBody>
  );
};

const Paragraph: React.FC<TypographyProps> = ({ children, semanticTag = "p", ...boxProps }) => {
  return (
    <StyledParagraph component={semanticTag} {...boxProps}>
      {children}
    </StyledParagraph>
  );
};

type Variant = "H1" | "H2" | "H3" | "H4" | "Body" | "Paragraph";

type TypographyType = {
  [key in Variant]: React.FC<TypographyProps>;
}

export const Typography: TypographyType = {
  H1,
  H2,
  H3,
  H4,
  Body,
  Paragraph,
};
