import { NOTIFICATION_PREF_TYPES } from "../types";
import { TNotificationOptInFieldConstants } from "./types";

export const FIELD_KEYS = NOTIFICATION_PREF_TYPES;

const phoneHelpText = "Ex: 555-123-1234";

export const NOTIFICATION_FIELDS: TNotificationOptInFieldConstants[] = [
  {
    id: FIELD_KEYS.email,
    toggleLabel: "Email",
    inputLabel: "Email",
    editable: false,
  },
  {
    id: FIELD_KEYS.sms,
    toggleLabel: "Text Message",
    inputLabel: "Mobile Number",
    helpText: phoneHelpText,
    editable: true,
  },
  {
    id: FIELD_KEYS.phone,
    toggleLabel: "Phone Call",
    inputLabel: "Phone Number",
    helpText: phoneHelpText,
    editable: true,
  },
];
