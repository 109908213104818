import { Stack } from "@mui/system";
import { SectionTile } from "_global/components/Containers";
import { List, ListItem } from "_global/components/List/List";
import { ButtonLink } from "./ButtonLink";
import { SECTION_VALS as sectionVals } from "./constants";

const { sectionTitle, description, buttonList } = sectionVals;

export const ButtonListSection = () => (
  <SectionTile.Container gap="28px" maxWidth="480px">
    <SectionTile.Header
      title={sectionTitle}
      description={description}
    />
    <Stack component={List} gap="20px" maxWidth="100%">
      {buttonList.map((buttonVals) => (
        <ListItem key={buttonVals.text}>
          <ButtonLink key={buttonVals.text} {...buttonVals} />
        </ListItem>
      ))}
    </Stack>
  </SectionTile.Container>
);
