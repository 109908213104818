import SvgIcon from "@mui/material/SvgIcon";

export const HomeOwnershipCalculatorIcon = () => {
    return (
        <SvgIcon style={{marginRight: "7px"}}>
            <svg
                width={25}
                height={25}
                viewBox="0 0 25 25"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.182 2.924h-13.5a1.502 1.502 0 00-1.5 1.5v16.5a1.502 1.502 0 001.5 1.5h13.5a1.502 1.502 0 001.5-1.5v-16.5a1.501 1.501 0 00-1.5-1.5zm-10.5 16.499a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm0-3.75a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm3.75 3.75a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm0-3.75a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm3.75 3.75a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm0-3.75a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm1.5-5.25a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-3.75a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v3.75z"
                />
            </svg>
        </SvgIcon>
    );
};
