import { Box, styled } from "@mui/system";
import { getSkeletonAnimationStyles } from "_global/theme/styleUtils";

type LoadingSkeletonProps = {
  borderRadius?: string;
};

const options = {
  shouldForwardProp: (prop: string) => !["borderRadius"].includes(prop),
};

export const LoadingSkeleton = styled(Box, options)<LoadingSkeletonProps>(({
  theme,
  borderRadius,
}) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: borderRadius || theme.shape.borderRadius,
  overflow: "hidden",
  ...getSkeletonAnimationStyles(),
}));
