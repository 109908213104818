import * as React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetContractedHomes } from "../hooks/useGetContractedHomes";
import type { TContractedHome } from "../types";

export type TContractedHomesViewProps = {
  contractedHome: TContractedHome | null;
  isError?: boolean;
  isLoading: boolean;
};

type ContractedHomesControllerProps = {
  component: React.FC<TContractedHomesViewProps>;
};

export const ContractedHomesController: React.FC<ContractedHomesControllerProps> = ({
  component: Component,
}) => {
  const { user } = useGlobalContext();
  const shouldGetContractedHomes = !!user && user.isContractedInHubspot;

  const {
    data,
    isError,
    isLoading,
  } = useGetContractedHomes({ enabled: shouldGetContractedHomes});

  const selectedContractedHome = React.useMemo(() => {
    if (!data || !Array.isArray(data)) return null;

    // TODO: selecting different 'contracted homes' will be a feature in the future as part of https://emerge.atlassian.net/browse/PAH-300
    // for now, we just take the first one
    return data[0] || null;
  }, [data]);

  return (
    <Component
      contractedHome={selectedContractedHome}
      isError={isError}
      isLoading={isLoading}
    />
  );
};
