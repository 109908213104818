import * as React from "react";
import { FocusTrap } from "@mui/base";
import { Box, styled } from "@mui/system";

import { PrimaryNavMenu } from "./components/BottomBar/PrimaryNavMenu";
import { DrawerNavMenu } from "./components/BottomBar/Drawer/DrawerNavMenu";

import { DrawerContext, DrawerContextProvider } from "_global/components/Drawers/contexts/DrawerContext";

import { BOTTOM_BAR_HEIGHT } from "./util/constants";
import { bottomNavRouteList as primaryLinks, navRouteList as allLinks } from "./util/routes";

type StyledContainerProps = {
  isDrawerVisible: boolean;
};

const options = {
  shouldForwardProp: (prop: string) => prop !== "isDrawerVisible",
};

const StyledContainer = styled(Box, options)<StyledContainerProps>(({ theme, isDrawerVisible }) => ({
  position: "relative",
  width: "100vw",
  maxWidth: "100%",
  height: BOTTOM_BAR_HEIGHT,
  backgroundColor: theme.palette.bottomBar.main,
  zIndex: isDrawerVisible ? 50 : "auto",
}));

const BottomBarContent: React.FC = () => {
  const { open, transitionStatus } = React.useContext(DrawerContext);

  const drawerLinks = React.useMemo(() => (
    allLinks.filter((link) => !primaryLinks.includes(link))
  ), []);

  const isDrawerVisible = React.useMemo(() => (
    open || transitionStatus.isClosing
  ), [open, transitionStatus.isClosing]);

  return (
    <FocusTrap open={open}>
      <StyledContainer component="nav" isDrawerVisible={isDrawerVisible}>
        <DrawerNavMenu links={drawerLinks} />
        <PrimaryNavMenu links={primaryLinks} isDrawerVisible={isDrawerVisible} />
      </StyledContainer>
    </FocusTrap>
  );
};

export const NavigationBottomBar: React.FC = () => (
  <DrawerContextProvider>
    <BottomBarContent />
  </DrawerContextProvider>
);
