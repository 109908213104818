import * as React from "react";

interface IUseMessageEventListener {
  debugMode?: boolean;
};

// Zonda VR360 Web App API is an iframe which uses the Message Event Listener API to communicate with the parent window.
// - https://developer.mozilla.org/en-US/docs/Web/API/Window/message_event
// - it may be useful to log the messages received from the iframe to the console for debugging purposes
// - this hook can also be used to access important messages, such as the VR360Result.getConfigJSON() message
// - currently it's just set up as a logger for debugging purposes

export const useMessageEventListener = ({ debugMode = false }: IUseMessageEventListener) => {
  function logEvent(event: MessageEvent) {
    if (event.origin === "https://apps.zondavirtual.com") {
      console.log("Received message:", event);
    }
  };

  React.useEffect(() => {
    if (debugMode) {
      window.addEventListener("message", logEvent);
      return () => {
        window.removeEventListener("message", logEvent);
      };
    };
  }, []);

  return;
};
