import { Stack } from "@mui/system";
import { PageHeader } from "features/PageHeader";
import { DocumentsListing } from "features/DocumentsListing/DocumentsListing";

export const Documents = () => {
  return (
    <Stack gap="40px">
      <PageHeader title="Documents" />
      <DocumentsListing />
    </Stack>
  );
};
