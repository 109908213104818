import { Box, styled } from "@mui/system";

export const StyledLinkContainer = styled(Box)(({ theme }) => {
  const { palette } = theme;
  const paddingXOffset = 0.5;

  return {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(0, paddingXOffset),
    margin: theme.spacing(0, -paddingXOffset),
    gap: theme.spacing(4),
    color: palette.v2.neutral[100],
    fontWeight: 300,
    letterSpacing: ".5px",
    fontSize: "16px",
    height: "80px",
    textDecoration: "none",
    fontFamily: "Open Sans, Arial, sans-serif",
    border: "4px solid transparent",
    "&.active": {
      backgroundColor: palette.sidebar.active.main,
      color: palette.sidebar.active.contrastText,
      fontWeight: 600,
      borderColor: palette.sidebar.active.dark,
      borderRadius: "0 1px 1px 0",
      width: `calc(100% + ${theme.spacing(paddingXOffset * 2)})`,
      boxShadow: palette.sidebar.active.shadow,
      letterSpacing: "0px",
    },
    "&:hover&:not(.active)": {
      color: palette.sidebar.active.contrastText,
      fontWeight: 600,
      letterSpacing: "0px",
    },
    ".iconContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "32px",
    },
  };
});

export const Divider = styled("li")(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.grey[300],
}));

export const StyledListItem = styled(Box)(() => ({
  "&:focus-within": {
    zIndex: 1, // keeps focused outline above sibling elements
  },
}));
