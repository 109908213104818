import { Stack, styled } from "@mui/system";

const MOBILE_WIDTH = "100%";
const DESKTOP_MIN_WIDTH = "470px";
const DESKTOP_WIDTH = "500px";

export const InfoTileWrapper = styled(Stack)(({ theme }) => ({
  minWidth: DESKTOP_MIN_WIDTH,
  maxWidth: DESKTOP_WIDTH,
  [theme.breakpoints.down("sm")]: {
    minWidth: MOBILE_WIDTH,
    width: MOBILE_WIDTH,
    maxWidth: MOBILE_WIDTH,
  },
}));
