import { VerifyEmailPage } from "features/Auth/VerifyEmailPage/VerifyEmailPage";
import { VerifyEmailViewContainer } from "views/Layout/FloatingViewLayout";

export const VerifyEmailView = () => {
  return (
    <VerifyEmailViewContainer>
      <VerifyEmailPage />
    </VerifyEmailViewContainer>
  );
};
