import * as React from "react";
import { styled } from "@mui/system";
import { getSkeletonAnimationStyles } from "_global/theme/styleUtils";

type Elevation = 0 | 1 | 2;

type StyledCardProps = {
  active: boolean;
  as: "button" | "div";
  elevation: number;
  border?: boolean;
  skeleton?: boolean;
};

const options = {
  shouldForwardProp: (prop: string) => !["active","elevation","border","as", "skeleton"].includes(prop),
};

const StyledCard = styled("div", options)<StyledCardProps>(({ theme,
  as,
  border,
  active,
  elevation,
  skeleton,
}) => {
  const { palette } = theme;

  const colors = React.useMemo(() => {
    return {
      bg: palette.white,
      bgHover: palette.primary.shade[5],
      bgActive: palette.primary.shade[3],
      text: palette.grey[800],
    };
  }, [palette]);

  const boxShadow = React.useMemo(() => ({
    main: palette.shadow[elevation],
    hover: palette.shadow[elevation + 1],
    active: palette.shadow[elevation + 2],
  }), [palette, elevation]);

  const buttonStyles = React.useMemo(() => {
    if (as !== "button" || skeleton) return {};
    return {
      cursor: "pointer",
      "&:hover&:not(:disabled)": {
        backgroundColor: active ? colors.bgActive : colors.bgHover,
        boxShadow: active ? boxShadow.active : boxShadow.hover,
      },
      "&:disabled": {
        cursor: "not-allowed",
      },
    };
  }, [as, active, colors, boxShadow, skeleton]);

  const backgroundColor = React.useMemo(() => {
    if (skeleton) return palette.grey[200];
    if (active) return colors.bgActive;
    return colors.bg;
  }, [palette, skeleton, active, colors]);

  const borderStyle = React.useMemo(() => (
    !border ? "none" : `1px solid ${palette.grey[skeleton ? 200 : 300]}`
  ), [palette, border, skeleton]);

  const animationStyles = React.useMemo(() => {
    if (!skeleton) return { animation: "none" };
    return getSkeletonAnimationStyles();
  }, [skeleton]);

  // Card container size is based on its content
  // https://developer.mozilla.org/en-US/docs/Glossary/Intrinsic_Size
  const intrinsicSizeStyles = {
    minWidth: "min-content",
    maxWidth: "max-content",
    minHeight: "min-content",
    maxHeight: "max-content",
  };

  return {
    overflow: "hidden",
    padding: 0,
    color: colors.text,
    backgroundColor,
    borderRadius: theme.shape.borderRadius,
    border: borderStyle,
    boxShadow: active ? boxShadow.active : boxShadow.main,
    transition: "box-shadow 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    ...intrinsicSizeStyles,
    ...buttonStyles,
    ...animationStyles,
  };
});

export type CardProps = {
  border?: boolean;
  button?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  ariaLabel?: string;
  onClick?: () => void;
  active?: boolean;
  tooltip?: string;
  elevation?: Elevation;
  skeleton?: boolean;
  className?: string;
};

export const Card: React.FC<CardProps> = ({
  button = false,
  disabled = false,
  border = false,
  onClick,
  children,
  ariaLabel,
  tooltip = "",
  active = false,
  elevation = 1,
  skeleton = false,
  className = "",
}) => {
  const as = button ? "button" : "div";

  const buttonProps = React.useMemo(() => {
    if (!button) return {};

    return {
      type: "button",
      disabled,
      onClick,
      ...(ariaLabel && { "aria-label": ariaLabel }),
      ...(tooltip && { title: tooltip }),
    };
  }, [button, ariaLabel, disabled, onClick, tooltip]);

  return (
    <StyledCard
      as={as}
      className={className}
      active={active}
      elevation={elevation}
      border={border}
      skeleton={skeleton}
      {...buttonProps}
    >
      {children}
    </StyledCard>
  );
};
