import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import type { TNotificationPrefsDTO } from "../types";
import { QUERY_KEYS } from "_global/constants";

type UseGetNotificationsOptions = {
  enabled?: boolean;
};

export const useGetNotificationPrefs = (options?: UseGetNotificationsOptions) => {
  const { enabled = true } = options || {};
  const client = useApiClient();

  const {
    data,
    isStale,
    isFetching,
    isLoading,
    isError,
  } = useQuery<TNotificationPrefsDTO, Error>({
    enabled,
    queryKey: [QUERY_KEYS.preferences, "get"],
    queryFn: async () => {
      const response = await client.get("/notifications");
      return response.data;
    },
  });

  return {
    data,
    isStale,
    isLoading,
    isFetching,
    isError,
  };
};
