import { styled } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";

export const StyledChipGrid = styled(Grid)(({ theme }) => ({
  marginLeft: "20%",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0",
  },
}));

export const StyledChip = styled("a")(({ theme }) => ({
  display: "inline-flex",
  fontSize: "13px",
  fontWeight: 600,
  letterSpacing: "0.25px",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
  borderRadius: "20px",
  border: "none",
  textDecoration: "none",
  height: "38px",
  minHeight: "38px",
  color: theme.palette.grey[900],
  cursor: "pointer",
  padding: theme.spacing(1, 2),
  "&:hover&:not(:disabled)": {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.dark,
  },
  "&:disabled": {
    cursor: "not-allowed",
    opacity: .3,
  },
}));
