export const TRANSITION_DURATION = 225;

export const SIDEBAR_WIDTH: Record<"expanded" | "collapsed", number> = {
  expanded: 240,
  collapsed: 88,
};

export const SIDEBAR_OVERFLOW_X_BUFFER = 10;

export const BOTTOM_BAR_HEIGHT = 78;
