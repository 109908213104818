import * as React from "react";
import { Box, styled } from "@mui/system";

const StyledOuterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  flex: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const StyledInnerContainer = styled(Box)(({ theme }) => ({
  maxWidth: "330px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(10),
  padding: theme.spacing(2),
}));

type ContainerProps = {
  children: React.ReactNode;
};

export const FormContainer: React.FC<ContainerProps> = ({ children }) => {
  return (
    <StyledOuterContainer>
      <StyledInnerContainer>
        {children}
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export const PageContainer = styled(Box)(() => ({
  height: "100vh",
  display: "flex",
}));
