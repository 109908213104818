import { Stack } from "@mui/system";
import { SectionTile } from "_global/components/Containers";
import { List, ListItem } from "_global/components/List/List";
import { CTALink } from "./CTALink";
import { SECTION_VALS as contractedSectionVals } from "./contracted-constants";
import { SECTION_VALS as prospectiveSectionVals } from "./prospective-constants";
import {LinkListProps} from "./types";

export const LinkListSection = ({ isContracted = false }: LinkListProps) => (
  <SectionTile.Container
    gap="28px"
    showBorder
    maxWidth="480px"
    variant="neutral"
  >
    { isContracted ?
        <>
          <SectionTile.Header
              title={contractedSectionVals.sectionTitle}
              description={contractedSectionVals.description}
          />
          <Stack component={List} gap="20px" maxWidth="max-content">
            {contractedSectionVals.linkList.map((link) => (
              <ListItem key={link.text}>
                <CTALink {...link} />
              </ListItem>
            ))}
          </Stack>
        </>
        :
        <>
          <SectionTile.Header
              title={prospectiveSectionVals.sectionTitle}
              description={prospectiveSectionVals.description}
          />
          <Stack component={List} gap="20px" maxWidth="max-content">
            {prospectiveSectionVals.linkList.map((link) => (
                <ListItem key={link.text}>
                  <CTALink {...link} />
                </ListItem>
            ))}
          </Stack>
        </>
    }
  </SectionTile.Container>
);
