import * as React from "react";
import { styled } from "@mui/system";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { IconButton } from "_global/components/Buttons/IconButton";
import { NavigationSidebarContext } from "../../contexts/NavigationSidebarContext";

const AbsolutePositionWrapper = styled("span")(() => ({
  position: "absolute",
  top: "34px",
  right: 0,
  transform: "translateX(44%)",
}));

export const ToggleExpandButton: React.FC = () => {
  const {
    expanded,
    toggleExpanded,
  } = React.useContext(NavigationSidebarContext);

  return (
    <AbsolutePositionWrapper>
      <IconButton
        size="small"
        onClick={toggleExpanded}
        icon={expanded ? ChevronLeftOutlined : ChevronRightOutlined}
        ariaLabel={expanded ? "Collapse sidebar" : "Expand sidebar"}
      />
    </AbsolutePositionWrapper>
  );
};
