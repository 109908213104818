import Grid from "@mui/system/Unstable_Grid/Grid";

import {
  PhoneOutlined,
  EmailOutlined,
  SmartphoneOutlined,
  SvgIconComponent
} from "@mui/icons-material";

import { StyledChip } from "./ContactChips.elements";

export type ContactChipProps = {
  icon: SvgIconComponent;
  href: string;
  label: string;
};

export const ContactChip: React.FC<ContactChipProps> = ({
  icon,
  href,
  label,
}) => {
  
  const Icon = icon;

  return (
    <Grid xs={1}>
      <StyledChip
        title={label}
        href={href}
      >
        <Icon fontSize="small" /> {label}
      </StyledChip>
    </Grid>
  );
};

type PhoneChipProps = {
  phone?: string;
}

export const PhoneChip: React.FC<PhoneChipProps> = ({ phone }) => {
  if (!phone) return null;
  return (
    <ContactChip
      icon={PhoneOutlined}
      href={`tel:${phone}`}
      label="Call"
    />
  );
};

type EmailChipProps = {
  email?: string;
}


export const EmailChip: React.FC<EmailChipProps> = ({ email }) => {
  if (!email) return null;
  return (
    <ContactChip
      icon={EmailOutlined}
      href={`mailto:${email}`}
      label="Email"
    />
  );
};

type SMSChipProps = {
  sms?: string;
}

export const SMSChip: React.FC<SMSChipProps> = ({ sms }) => {
  if (!sms) return null;
  return (
    <ContactChip
      icon={SmartphoneOutlined}
      href={`sms:${sms}`}
      label="Text"
    />
  );
};
