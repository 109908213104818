import { Theme } from "@mui/system";
import { ButtonColor } from "../types";

type ButtonColorTemplate = {
  bg: string;
  bgHover: string;
  text: string;
  textHover: string;
}

interface IGetButtonThemeColors {
  theme: Theme;
  color: ButtonColor;
  primary: boolean;
}

export const getButtonThemeColors = ({
  theme,
  color,
  primary,
}: IGetButtonThemeColors): ButtonColorTemplate => {
  const { palette } = theme;

  type ColorMap = {
    primary: Record<ButtonColor, ButtonColorTemplate>;
    secondary: ButtonColorTemplate;
  }

  const colorMap: ColorMap = {
    primary: {
      [ButtonColor.LIGHT]: {
        bg: palette.primary.main,
        bgHover: palette.primary.dark,
        text: palette.primary.contrastText,
        textHover: palette.primary.contrastText,
      },
      [ButtonColor.DARK]: {
        bg: palette.primary.main,
        bgHover: palette.primary.shade[1],
        text: palette.primary.contrastText,
        textHover: palette.primary.contrastText,
      },
    },
    secondary: {
      bg: palette.secondary.main,
      bgHover: palette.primary.main,
      text: palette.secondary.contrastText,
      textHover: palette.primary.contrastText,
    },
  };

  if (primary) return colorMap.primary[color];
  return colorMap.secondary;
};

export const getIconButtonThemeColors = ({ theme }: { theme: Theme }): ButtonColorTemplate => {
  const { palette } = theme;

  // TODO: currently unsure what variation of colors will be needed and what overlap there will be with the core button colors.
  // For now just using values for the sidebar toggle button.
  return {
    bg: palette.primary.shade[3],
    bgHover: palette.primary.shade[2],
    text: palette.primary.dark,
    textHover: palette.primary.dark,
  };
};
