import * as React from "react";
import { Box, Stack } from "@mui/system";
import type { THomeDetails } from "../../types";
import {getPluralizedLabel} from "../../utils";

export const getPropertyDetailsList = ({
  sqft, beds, baths, garageCarCapacity, propertyType
}: THomeDetails): string[] => {
  const list = [
    { label: "Sq Ft", value: Number(sqft).toLocaleString() },
    { label: getPluralizedLabel("Bed", beds), value: beds },
    { label: getPluralizedLabel("Bath", baths), value: baths },
    { label: getPluralizedLabel("Car", garageCarCapacity), value: garageCarCapacity },
    { label: "", value: propertyType ? propertyType.charAt(0).toUpperCase() + propertyType?.substring(1) : "" }
  ].filter(({ value }) => !!value);

  return list.map(({ label, value }) => `${value?.toLocaleString()} ${label}` );
};

export const DetailListRow = (props: THomeDetails) => {
  const detailsList = React.useMemo(() => getPropertyDetailsList(props), [props]);
  const separator = React.useMemo(() => <>•</>, []);

  return (
  <Stack flexDirection="row" gap="2px" divider={separator}>
    {detailsList.map((detail) => (
      <Box key={detail}>{detail}</Box>
    ))}
  </Stack>
  );
};
