import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import { Card } from "_global/components/Cards/Card";

const StyledCardContent = styled(Stack)(({ theme }) => ({
  height: "160px",
  width: "433px",
  minWidth: "340px",
  maxWidth: "433px",
  justifyContent: "space-around",
  padding: "18px",
  gap: "18px",
  fontFamily: "Open Sans, Arial, sans-serif",
  [theme.breakpoints.down("sm")]: {
    width: `calc(100vw - ${theme.spacing(4)})`,
  },
}));

const StyledName = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  color: theme.palette.black,
}));

const StyledRole = styled(Box)(({ theme }) => ({
  fontWeight: 300,
  fontSize: "16px",
  color: theme.palette.black,
}));

const StyledCardTopRow = styled(Box)(() => ({
  display: "flex",
  gap: "30px",
}));

const StyledNameContainer = styled(Stack)(() => ({
  gap: "12px",
  flexGrow: 1,
  padding: "4px 0px",
}));

type CardContainerProps = {
  children?: React.ReactNode;
  skeleton?: boolean;
};

const CardContainer: React.FC<CardContainerProps> = ({
  children = null,
  skeleton = false,
}) => (
  <Card elevation={0} border skeleton={skeleton}>
    <StyledCardContent>{children}</StyledCardContent>
  </Card>
);

export const TeamCardSkeleton = () => <CardContainer skeleton />;

export const StyledCard = {
  Container: CardContainer,
  TopRow: StyledCardTopRow,
  NameContainer: StyledNameContainer,
  Name: StyledName,
  Role: StyledRole,
};
