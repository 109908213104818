import * as React from "react";
import { Stack } from "@mui/system";
import { TextInput, ToggleSwitch } from "_global/components/Inputs";
import { StyledTile } from "./NotificationsOptInForm.elements";
import type { TTileVariants } from "_global/components/Containers/Tile/Tile";
import type { TNotificationOptInFieldConstants } from "./types";

type TNotificationOptInFieldProps = TNotificationOptInFieldConstants & {
  enabled: boolean;
  inputValue: string;
  errorMessage?: string;
  tileVariant?: TTileVariants;
  onClickToggle: () => void;
  onInputBlur: () => void;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const NotificationOptInField: React.FC<TNotificationOptInFieldProps> = ({
  id,
  enabled,
  editable,
  inputValue,
  inputLabel,
  toggleLabel,
  helpText = "",
  errorMessage,
  tileVariant = "tile",
  onInputBlur,
  onClickToggle,
  onChangeInput,
}) => {
  return (
    <StyledTile variant={tileVariant} role="group" aria-label={toggleLabel}>
      <Stack minWidth="155px">
        <ToggleSwitch
          id={`${id}-toggle`}
          label={toggleLabel}
          checked={enabled}
          onChange={onClickToggle}
        />
      </Stack>
      <Stack minWidth="260px" flexGrow={1}>
        <TextInput
          id={`${id}-input`}
          tileVariant={tileVariant}
          label={inputLabel}
          disabled={!enabled || !editable}
          value={inputValue}
          onBlur={onInputBlur}
          onChange={onChangeInput}
          invalid={!!errorMessage}
          helpText={errorMessage || (enabled && helpText) || ""}
        />
      </Stack>
    </StyledTile>
  );
};
