import * as React from "react";
import { useLocation } from "react-router-dom";
import type { TPhaseInfo } from "../constants";
import type { TPhaseResponseDTO } from "features/ContractedHomes/types";
import { getPhaseInfo, isPhaseStatusInProgress } from "../utils";

type UseTimelinePhaseStateOptions = {
  constructionPhases: TPhaseResponseDTO | null;
};

export type UseTimelinePhaseStateReturnType = {
  activePhase: TPhaseInfo | null;
  activePhaseMobile: TPhaseInfo | null;
  setActivePhase: (phase: TPhaseInfo) => void;
  clearActivePhase: () => void;
};

export const useTimelinePhaseState = ({
  constructionPhases
}: UseTimelinePhaseStateOptions): UseTimelinePhaseStateReturnType => {
  const routerState = useLocation().state; // selected phase from previous page
  
  const preselectedPhase = React.useMemo(() => (
    routerState?.activePhase || null
  ), [routerState?.activePhase]);

  const [selectedPhase, setSelectedPhase] = React.useState<TPhaseInfo | null>(null);

  React.useEffect(() => {
    // if user has selected a phase from previous page, we want to render that phase as active phase, and open mobile modal if user is on mobile
    if (preselectedPhase) setSelectedPhase(preselectedPhase);
  }, [preselectedPhase]);

  const prevSelected = React.useRef<TPhaseInfo | null>(null); // BottomDrawer Modal will clear selectedPhase, this caches the previous selectedPhase for rendering active phase UI

  const defaultActivePhase: TPhaseInfo | null = React.useMemo(() => {
    if (!constructionPhases?.length) return null;
    // first we want to set the "In Progress" phase as the default phase, there should be only one "In Progress" phase in the phases array
    const inProgressPhase = constructionPhases.find((phase) => isPhaseStatusInProgress(phase.status));
    // if there is no "In Progress" phase, we want to set the first phase as the default phase
    const defaultPhase = inProgressPhase || constructionPhases[0];
    // get the phase info (sherpa content data, etc) for the default phase
    const phaseInfo = defaultPhase ? getPhaseInfo(defaultPhase.name) : null;

    if (!phaseInfo)  null;

    // include status in phaseInfo
    return { ...phaseInfo, status: defaultPhase.status } as TPhaseInfo;
  }, [constructionPhases, preselectedPhase]);

  const activePhase = selectedPhase || prevSelected.current || defaultActivePhase; // render default phase if user has not selected a phase
  const activePhaseMobile = selectedPhase; // we do not want to render modal unless user selects a phase thru click action

  const setActivePhase = React.useCallback((phase: TPhaseInfo) => {
    setSelectedPhase(phase);
    prevSelected.current = phase;
  }, []);

  const clearActivePhase = React.useCallback(() => {
    setSelectedPhase(null);
  }, []);

  return {
    activePhase,
    activePhaseMobile,
    setActivePhase,
    clearActivePhase
  };
};
