import { Box, styled, Stack } from "@mui/system";
import { LaunchOutlined, QuestionMark } from "@mui/icons-material";

import { GridList } from "_global/components/List/GridList/GridList";
import { IconButton } from "_global/components/Buttons/IconButton";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { Button as BaseButton, type ButtonProps as BaseButtonProps } from "@mui/base";

import {
  MAIN_CONTENT_PADDING_X_XS,
  MAIN_CONTENT_PADDING_LEFT_SM,
  MAIN_CONTENT_PADDING_RIGHT_SM,
} from "_global/views/Layout/Layout";

type ContainerProps = {
  children: React.ReactNode;
};

const TOTAL_COLS = 16;
const DOCUMENT_TITLE_COLSPAN = { xs: 12, sm: 6 };
const STATUS_COLSPAN = { xs: 4, sm: 3 };
const DESKTOP_UPDATED_AT_COLSPAN = { xs: 0, sm: 4 };
const DESKTOP_VIEW_BUTTON_COLSPAN = { xs: 0, sm: 3 };

/** MAIN CONTAINER AND IT'S OVERFLOW PARENT ======================================= **/

const OverflowScrollContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  margin: theme.spacing(
    0,
    -MAIN_CONTENT_PADDING_RIGHT_SM,
    0,
    -MAIN_CONTENT_PADDING_LEFT_SM,
  ),
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0, -MAIN_CONTENT_PADDING_X_XS),
  },
}));

const InnerScrollContainer = styled(Box)(({ theme }) => ({
  display: "inline-block",
  verticalAlign: "middle",
  minWidth: "100%",
  paddingBottom: "24px",
  paddingRight: theme.spacing(MAIN_CONTENT_PADDING_RIGHT_SM),
  paddingLeft: theme.spacing(MAIN_CONTENT_PADDING_LEFT_SM),
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
  },
}));

const MainContainer: React.FC<ContainerProps> = (props) => (
  <Box display="flow-root">
    <OverflowScrollContainer>
      <InnerScrollContainer>
        <GridList.OuterContainer
          minWidth={{ xs: "300px", sm: "640px" }}
          maxWidth={{ xs: "100%", sm: "900px" }}
          sx={{ width: "100%" }}
          {...props}
        />
      </InnerScrollContainer>
    </OverflowScrollContainer>
  </Box>
);

/** ROW WRAPPERS ============================================================= **/

const HeaderRow: React.FC<ContainerProps> = (props) => (
  <GridList.HeaderRowContainer columns={{ xs: TOTAL_COLS }} {...props} />
);

const DataRow: React.FC<React.ComponentProps<typeof GridList.GridRowContainer>> = (props) => (
  <GridList.GridRowContainer columns={{ xs: TOTAL_COLS }} {...props} />
);

/** CELL WRAPPERS =========================================================== **/

const DocumentTitleCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell {...DOCUMENT_TITLE_COLSPAN}>
    <Stack gap="5px" pr="20px">
      {children}
    </Stack>
  </GridList.Cell>
);

const DesktopUpdatedAtCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell className="hide-in-small" justifySelf="end" {...DESKTOP_UPDATED_AT_COLSPAN}>
    <Stack flexDirection="row">
      {children}
    </Stack>
  </GridList.Cell>
);

const StatusCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell {...STATUS_COLSPAN}>
    <Stack flexDirection="row" pr="16px" justifyContent={{ xs: "flex-end", sm: "flex-start" }}>
      {children}
    </Stack>
  </GridList.Cell>
);

const DesktopViewButtonCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell className="hide-in-small" {...DESKTOP_VIEW_BUTTON_COLSPAN}>
    <Stack flexDirection="row" justifyContent="flex-end">
      {children}
    </Stack>
  </GridList.Cell>
);

/** Exported DocsList Interface ====================== **/

export const DataGrid = {
  MainContainer,
  HeaderRow,
  DataRow,
  DataRowList: GridList.RowListContainer,
  DocumentTitleCell,
  DesktopUpdatedAtCell,
  StatusCell,
  DesktopViewButtonCell,
};

/** OTHER COMPONENTS ================================================ **/

type ViewButtonProps = {
  href: string;
  isMobile?: boolean;
};

const ViewButtonDesktop: React.FC = () => (
  <PrimaryButton
    light
    tabIndex={-1}
    aria-label="View Document"
    size="medium"
    icon={LaunchOutlined}
    label="View"
  />
);

const ViewButtonMobile: React.FC = () => (
  <IconButton
    tabIndex={-1}
    ariaLabel="View Document"
    size="xs"
    icon={LaunchOutlined}
    tooltip="View Document"
  />
);

export const ViewButton: React.FC<ViewButtonProps> = ({ href, isMobile }) => {
  return (
    <a
      href={href}
      style={{
        textDecoration: "unset",
        color: "unset",
        borderRadius: "24px",
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {isMobile ? <ViewButtonMobile /> : <ViewButtonDesktop />}
    </a>
  );
};

export const StyledStatusText = styled("span")(({ theme }) => ({
  textTransform: "capitalize",
  "& .danger": {
    color: theme.palette.v2.danger.main,
    fontWeight: 600,
  },
}));

const StyledInfoButton = styled(BaseButton)(({ theme }) => ({
  alignSelf: "stretch",
  color: theme.palette.v2.neutral[90],
  backgroundColor: theme.palette.v2.neutral[40],
  border: `0.5px solid ${theme.palette.v2.neutral[30]}`,
  cursor: "pointer",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "26px",
  width: "26px",
  transition: "all 0.05s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.v2.primary.surface,
    border: `0.5px solid ${theme.palette.v2.primary.border}`,
    color: theme.palette.v2.primary.main,
    boxShadow: theme.palette.v2.shadow1,
  },
  "svg": {
    fontSize: "19px",
  },
}));

export const InfoButton: React.FC<BaseButtonProps> = (props) => (
  <StyledInfoButton
    title="See document information"
    {...props}
  >
    <QuestionMark fontSize="inherit" />
  </StyledInfoButton>
);
