import type { MutationCallbackOptions } from "_global/types";

export const NOTIFICATION_PREF_TYPES = {
  email: "email",
  phone: "phone",
  sms: "sms",
} as const;

type TNotificationPref = {
  type: typeof NOTIFICATION_PREF_TYPES[keyof typeof NOTIFICATION_PREF_TYPES];
  contactInfo: string;
  optedIn: boolean;
};

export type TNotificationPrefsDTO = TNotificationPref[];

export type UpdateNotificationPrefsMutationFunc = (variables: TNotificationPrefsDTO, options?: MutationCallbackOptions) => void;
