import * as React from "react";
import { Stack } from "@mui/system";
import { AccountEmailFormSection, CoBuyersSection, MatchingSupportSection } from "features/Account";

export const Main: React.FC = () => (
  <Stack gap="32px">
    <MatchingSupportSection />
    <AccountEmailFormSection />
    <CoBuyersSection />
  </Stack>
);
