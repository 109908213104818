import * as React from "react";
import { StyledTile, TILE_CLASSNAME, TILE_CLASSNAME_CONTRACTED } from "./UserProfileTile.elements";
import { routes } from "features/Navigation/util/routes";
import type { User } from "_global/types";

type ProfileTileProps = {
  user: User;
  standalone?: boolean;
  withNotifications?: boolean;
  shouldShowNotificationWarning?: boolean;
};

const getUserDisplayName = (user: User) => (
  [user.firstName, user.lastName].filter(Boolean).join(" ") || user.email
);

export const UserProfileTile: React.FC<ProfileTileProps> = ({
  user,
  standalone = true,
  withNotifications = true,
  shouldShowNotificationWarning = false,
}) => {

  const displayName = React.useMemo(() => getUserDisplayName(user), [user]);

  const className = React.useMemo(() => (
    standalone ? TILE_CLASSNAME : `${TILE_CLASSNAME} ${TILE_CLASSNAME_CONTRACTED}`
  ), [standalone]);

  return (
    <StyledTile.OuterContainer className={className} >
      <StyledTile.NameSection to={routes.profile.path} title="Go to profile page">
        {displayName}
      </StyledTile.NameSection>
      {withNotifications && (
        <StyledTile.NotificationSection
          to={routes.profile.subroutes.notifications.path}
          title="Go to notification preferences"
          shouldShowNotificationWarning={shouldShowNotificationWarning}
        />
      )}
    </StyledTile.OuterContainer>
  );
};
