import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Stack } from "@mui/system";
import { useLocation } from "react-router-dom";

type LayoutProps = {
  children: React.ReactNode;
}

const AppContainer: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Grid 
      container 
      height="100%" 
      minHeight="100%" 
      maxHeight="100%"
      width="100vw"
      wrap="nowrap"
      position="fixed"
      columns={{ xs: 12 }}
      sx={{
        overflow: "hidden",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
    >
      {children}
    </Grid>
  );
};

const Sidebar: React.FC<LayoutProps> = ({ children }) => {
  const width = "auto"; // "auto" will grow to fit children's content
  return (
    <Grid
      width={width}
      sx={{
        display: {
          xs: "none",
          sm: "block",
        },
        minWidth: width,
      }}
    >
      {children}
    </Grid>
  );
};

// offset values for BannerListRenderer, etc.
export const MAIN_CONTENT_PADDING_Y_XS = 4;
export const MAIN_CONTENT_PADDING_Y_SM = 2;
export const MAIN_CONTENT_PADDING_X_XS = 2;
export const MAIN_CONTENT_PADDING_LEFT_SM = 5;
export const MAIN_CONTENT_PADDING_RIGHT_SM = 3;

const MainContent: React.FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <Grid
      ref={ref}
      sx={{
        flex: 2,
        overflow: "auto",
        width: {
          xs: "100vw",
          sm: "auto",
        }
      }}
    >
      <Stack
        component="main"
        sx={{
          overflow: "hidden",
          width: "100%",
          minHeight: "100%",
          padding: theme => ({
            xs: theme.spacing(
              MAIN_CONTENT_PADDING_Y_XS,
              MAIN_CONTENT_PADDING_X_XS,
            ),
            sm: theme.spacing(
              MAIN_CONTENT_PADDING_Y_SM,
              MAIN_CONTENT_PADDING_RIGHT_SM,
              MAIN_CONTENT_PADDING_Y_SM,
              MAIN_CONTENT_PADDING_LEFT_SM,
            ),
          }),
        }}    
      >
        {children}
      </Stack>
    </Grid>
  );
};

const BottomBar: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Grid
      sx={{
        display: {
          xs: "block",
          sm: "none",
        },
      }}
    >
      {children}
    </Grid>
  );
};

export const Layout = {
  AppContainer,
  Sidebar,
  MainContent,
  BottomBar,
};
