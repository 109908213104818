import { Typography } from "_global/components/Typography/Typography";
import { CardListContainer } from "./TeamCardList.elements";
import { TeamCard } from "./TeamCard";
import { TTeamsViewProps } from "../controllers/TeamsController";

export const TeamCardList = ({
  isLoading,
  teamMembers,
}: TTeamsViewProps) => {
  // Loading State
  if (isLoading) {
    return <CardListContainer skeleton />;
  }

  // Empty State
  if (!teamMembers?.length) {
    return <Typography.Body>No team members found</Typography.Body>;
  }

  // Populated State
  return (
    <CardListContainer>
      {teamMembers.map((teamMember) => (
        <TeamCard key={teamMember.email || `${teamMember.firstName}_${teamMember.lastName}`} {...teamMember} />
      ))}
    </CardListContainer>
  );
};
