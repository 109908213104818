import * as React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetTeams } from "../hooks/useGetTeams";
import type { TeamMember } from "../types";

export type TTeamsViewProps = {
  teamMembers: TeamMember[];
  isError?: boolean;
  isLoading: boolean;
};

type TeamsControllerProps = {
  component: React.FC<TTeamsViewProps>;
};

export const TeamsController: React.FC<TeamsControllerProps> = ({
  component: Component,
}) => {
  const { user } = useGlobalContext();

  const {
    data,
    isError,
    isLoading,
  } = useGetTeams({ enabled: !!user });

  const teamMembers = React.useMemo(() => {
    if (!data || !Array.isArray(data) || !data.length) return [];
    // TODO: the teams array may return teams data for multiple jobs/deals.  For now, we're just going to assume the first one is the one we want.
    return data[0].teamMembers ?? [];
  }, [data]);

  return (
    <Component
      teamMembers={teamMembers}
      isError={isError}
      isLoading={isLoading}
    />
  );
};
