import * as React from "react";
import { useSidebarTransition, TransitionStatus } from "../hooks/useSidebarTransition";

export interface INavigationSidebarContextValue {
  expanded: boolean;
  toggleExpanded: () => void;
  transitionStatus: TransitionStatus;
}

export const NavigationSidebarContext = React.createContext<INavigationSidebarContextValue>({
  expanded: true,
  toggleExpanded: () => undefined,
  transitionStatus: {} as TransitionStatus,
});

type NavigationSidebarContextProviderProps = {
  children: React.ReactNode;
};

export const NavigationSidebarContextProvider: React.FC<NavigationSidebarContextProviderProps> = ({
  children,
}) => {
  const {
    expanded,
    toggleExpanded,
    transitionStatus,
  } = useSidebarTransition();

  return (
    <NavigationSidebarContext.Provider
      value={{
        expanded,
        toggleExpanded,
        transitionStatus,
      }}
    >
      {children}
    </NavigationSidebarContext.Provider>
  );
};
