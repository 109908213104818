import { TPhaseKey } from "features/ContractedHomes/types";
import { PHASE_INFO, type TPhaseInfo } from "./constants";
import { TPhaseStatuses as PHASE_STATUSES, type TPhaseStatus } from "features/ContractedHomes/types";

export const getPhaseInfo = (phaseKey: TPhaseKey): TPhaseInfo => {
  return PHASE_INFO[phaseKey];
};

export const isPhaseStatusInProgress = (phaseStatus: TPhaseStatus): boolean => (
  phaseStatus === PHASE_STATUSES["In Progress"]
);
