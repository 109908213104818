import { Stack, styled } from "@mui/system";
import { TRANSITION_DURATION } from "../util/constants";
import { Modal } from "@mui/base";

type StyledDrawerWrapperProps = {
  open: boolean;
  isClosing?: boolean;
  bottom?: string | number;
  zIndex?: string | number;
  maxHeight?: string;
};

const options = {
  shouldForwardProp: (prop: string) => ![
    "open", "isClosing", "bottom", "zIndex", "maxHeight",
  ].includes(prop),
};

export const StyledDrawerWrapper = styled(Stack, options)<StyledDrawerWrapperProps>(({
  theme,
  open,
  isClosing,
  bottom = 0,
  zIndex = 1,
  maxHeight = "96dvh",
}) => ({
  visibility: (open || isClosing) ? "visible" : "hidden", 
  position: "fixed",
  zIndex,
  bottom,
  left: 0,
  right: 0,
  width: "100vw",
  maxWidth: "100vw",
  maxHeight,
  overflow: "auto",
  backgroundColor: theme.palette.v2.neutral[10],
  borderRadius: "30px 30px 0 0",
  transformOrigin: "top",
  transform: open ? "translateY(0%)" : "translateY(100%)",
  transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0.4, 0, 0.2, 1) 10ms`,
}));

export const StyledModal = styled(Modal)(() => ({
  zIndex: 1300,
  position: "relative",
}));
