import * as React from "react";
import { Button as BaseButton } from "@mui/base";
import { styled } from "@mui/system";
import { getButtonThemeColors } from "_global/components/Buttons/util/getButtonThemeColors";
import { ButtonColor } from "_global/components/Buttons/types";
import { ReactComponent as DeleteIcon } from "_global/assets/icons/close-icon.svg";

const StyledDeleteButton = styled(BaseButton)(({ theme }) => {

  const colors = React.useMemo(() => (
    getButtonThemeColors({ theme, primary: true, color: ButtonColor.LIGHT })
  ), [theme]);

  const width = "40px";

  return {
    position: "absolute",
    top: 0,
    right: 0,
    transformOrigin: "top right",
    transform: "translate(-50%, 50%)",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.bg,
    borderRadius: "50%",
    border: "none",
    width,
    minWidth: width,
    height: width,
    minHeight: width,
    color: colors.text,
    boxShadow: "0px 2px 4px #00000080",
    cursor: "pointer",
    "&:hover&:not(:disabled)": {
      backgroundColor: colors.bgHover,
      color: colors.textHover,
      cursor: "pointer",
      "& svg": {
        fill: colors.textHover,
      },
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: .3,
    },
    "svg": {
      fill: colors.text,
    },
  };
});

export type DeleteButtonProps = {
  address: string;
  disabled?: boolean;
  tooltip?: string;
  onClick?: () => void;
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  address,
  disabled = false,
  tooltip = "Remove from saved homes",
  onClick,
}) => {

  return (
    <StyledDeleteButton
      aria-label={`Remove "${address}" from saved homes`}
      title={tooltip}
      disabled={disabled}
      onClick={onClick}
    >
      <DeleteIcon height="20px" width="20px" />
    </StyledDeleteButton>
  );
};
