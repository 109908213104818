import { useAuth0 } from "@auth0/auth0-react";
import { useGlobalContext } from "_global/contexts/GlobalContext";

export const useLogout = () => {
  const { logout } = useAuth0();
  const { setUser } = useGlobalContext();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    setUser(null);
  };

  return { handleLogout };
};
