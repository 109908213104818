import { useApiClient } from "_global/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SavedHomeDTO } from "../types";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { QUERY_KEYS } from "_global/constants";

export const useCreateSavedHomes = (renderToast = true) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { showSuccessToast, showErrorToast } = useToastRenderer();

  type SaveHomeMutationVariables = {
    wpId: number;
    isUndoDelete?: boolean;
  };

  const saveHome = async ({
    wpId,
  }: SaveHomeMutationVariables): Promise<SavedHomeDTO> => {
    return await apiClient.post("/saved-homes", { wpId });
  };

  const {
    isPending: isLoading,
    isError,
    mutate,
  } = useMutation({
    mutationFn: saveHome,
    onSuccess: (res, variables) => {
      const { isUndoDelete = false } = variables;
      const toastData = {
        message: `Home successfully ${isUndoDelete ? "restored" : "saved"}`,
      };
      if (renderToast) showSuccessToast(toastData);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.savedHomes] });
    },
    onError: (err, variables) => {
      const { isUndoDelete = false } = variables;
      const message = `Could not ${
        isUndoDelete ? "restore saved" : "save"
      } home.`;
      if (renderToast) showErrorToast({ message });
    },
  });

  return {
    isLoading,
    isError,
    mutate,
  };
};
