import * as React from "react";
import { Box, styled } from "@mui/system";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { ContractedHomesController, TContractedHomesViewProps } from "features/ContractedHomes/controllers/ContractedHomesController";
import { LoadingSkeleton } from "_global/components/Skeleton/LoadingSkeleton";

const StyledDescriptionContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

type CloseDateDescriptionProps = {
  formattedDate: string;
};

const ClosingDateInner: React.FC<CloseDateDescriptionProps> = ({
  formattedDate,
}) => (
  <StyledDescriptionContainer>
    <CalendarMonthOutlined sx={{ fontSize: "24px" }} />
    <Box display="inline-flex" alignItems="center">
      Closing Date: {formattedDate}
    </Box>
  </StyledDescriptionContainer>
);

const ClosingDateLoading = () => (
  <StyledDescriptionContainer sx={{ opacity: 0.5 }}>
    <LoadingSkeleton width="24px" height="24px" borderRadius="8px" />
    <LoadingSkeleton width="140px" height="16px" borderRadius="8px" />
  </StyledDescriptionContainer>
);

const getFormattedDate = (date: string) => {
  const d = new Date(date);
  const isInvalidDate = isNaN(d.getTime());
  return isInvalidDate ? "" : d.toLocaleDateString();
};

const ClosingDateRenderer: React.FC<TContractedHomesViewProps> = ({
  isError,
  isLoading,
  contractedHome,
}) => {
  
  const formattedDate = React.useMemo(() => {
    if (contractedHome?.closeDate) {
      return getFormattedDate(contractedHome.closeDate);
    }
    return "";
  } , [contractedHome, isError]);

  if (isLoading) return (
    <ClosingDateLoading />
  );

  if (!formattedDate) return null;
  
  return (
    <ClosingDateInner formattedDate={formattedDate} />
  );
};

export const ClosingDatePageDescription = () => (
  <ContractedHomesController component={ClosingDateRenderer} />
);
