export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const regex = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; // Matches any of the following: 555-123-1234, 5551231234, (555) 123-1234, 555 123 1234, 555.123.1234
  return regex.test(phoneNumber);
};

export const isValidEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const isBlank = (value: string): boolean => {
  return value.trim() === "";
};
