import React from "react";
import { Box } from "@mui/system";
import { SectionTile } from "_global/components/Containers";

export const CarouselControlsContainer = ({ children }: { children?: React.ReactNode }) => (
  <Box
    alignSelf="flex-end"
    gap="24px"
    alignItems="center"
    justifyContent="flex-end"
    flexWrap="wrap"
    sx={{
      marginRight: theme => theme.spacing(2),
      display: {
        xs: "none",
        sm: "flex",
      }
    }}
  >
    {children}
  </Box>
);

export const SavedHomesSectionHeader = () => (
  <SectionTile.Header
    title="Saved Homes"
    description="Your most recently saved Pahlisch homes."
  />
);
