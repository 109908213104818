import { Box, Stack, styled } from "@mui/system";
import { CheckCircle } from "@mui/icons-material";

import { Image } from "_global/components/Images";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { LoadingSkeleton } from "_global/components/Skeleton/LoadingSkeleton";

import { themeFonts } from "_global/theme/typography";
import { SUBSCRIBED_TEXT, BUTTON_LABEL, IMG_ALT } from "./constants";

import FrontPorchThumbnail from "_global/assets/images/frontporch.webp";

const StyledContainer = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  maxWidth: "750px",
  flexDirection: "row",
  gap: "24px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const StyledBody = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  gap: "20px",
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
  },
}));

const StyledThumbnailContainer = styled(Stack)(({ theme }) => ({
  borderRadius: "8px",
  alignItems: "center",
  justifyContent: "center",
  height: "200px",
  width: "200px",
  minWidth: "200px",
  [theme.breakpoints.down("md")]: {
    height: "160px",
    width: "200px",
    minWidth: "200px",
  },
}));

const StyledTitle = styled("h2")(({ theme }) => ({
  margin: 0,
  color: theme.palette.black,
  ...themeFonts.subtitle,
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));

const StyledBodyText = styled("p")(({ theme }) => ({
  margin: 0,
  color: theme.palette.black,
  ...themeFonts.textLReading,
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));

const StyledTextVariant = styled("span")(({ theme }) => ({
  color: theme.palette.black,
  ...themeFonts.textLgSemibold,
  display: "inline-flex",
  gap: "10px",
}));

const StyledTextContainer = styled(Stack)(() => ({
  gap: "10px",
  flexGrow: 1,
}));

const StyledActionContainer = styled(Stack)(() => ({
  display: "inline-flex",
  minHeight: "50px",
  justifyContent: "center",
}));

type SubscribeButtonProps = {
  onClick: () => void;
  skeleton?: boolean;
};

const SubscribeButton: React.FC<SubscribeButtonProps> = ({
  onClick,
  skeleton = false,
}) => (
  <Box minWidth="300px" maxWidth="300px">
    {skeleton ? (
      <LoadingSkeleton width="100%" height="47px" borderRadius="36px" />
    ) : (
      <PrimaryButton
        light
        fullWidth
        label={BUTTON_LABEL}
        onClick={onClick}
      />
    )}
  </Box>
);

const Thumbnail = () => (
  <StyledThumbnailContainer>
    <Image
      src={FrontPorchThumbnail}
      alt={IMG_ALT}
      height="100%"
      width="100%"
      borderRadius="16px"
    />
  </StyledThumbnailContainer>
);


const AlreadySubscribedDisplay = () => (
  <StyledTextVariant>
    <CheckCircle />
    {SUBSCRIBED_TEXT}
  </StyledTextVariant>
);

export const Card = {
  Container: StyledContainer,
  Thumbnail,
  BodyContainer: StyledBody,
  TextContainer: StyledTextContainer,
  ActionContainer: StyledActionContainer,
  Title: StyledTitle,
  BodyText: StyledBodyText,
  AlreadySubscribedDisplay,
  SubscribeButton,
};
