import { Box, styled } from "@mui/system";
import { Typography } from "_global/components/Typography/Typography";

export const SectionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
}));

export const SectionHeader = styled(Typography.H3)(({ theme }) => ({
  color: theme.palette.v2.titleText,
}));
