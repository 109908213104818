import * as React from "react";
import { useFormState } from "_global/form/hooks/useFormState";
import { useGlobalContext } from "_global/contexts/GlobalContext";

import { getEmptyFormState } from "_global/form/hooks/utils";
import { isValidPhoneNumber, isValidEmail, isBlank } from "_global/form/validation";
import { getInitFormValuesFromDTO, transformFormValuesToDTO } from "./utils";
import { FIELD_KEYS } from "../../NotificationsOptInForm/constants";

import type { TNotificationPrefsDTO } from "../../types";
import type { TNotificationOptInFormValues, TEnabledField } from "./types";
import type { UseFormStateReturnType } from "_global/form/hooks/types";
import type { MutationCallbackOptions } from "_global/types";
import type { FieldConfig, FormConfig } from "_global/form/hooks/types";

/**
 * == About this hook ======================
 * This hook is used to manage the state of the NotificationsOptInForm.
 * The same form is used in two places: 'Onboarding' and 'Profile > Notifications'.
 * It's basically a headless state manager for the form, ensuring consistency between the two views.
 * 
 * == How it works ======================
 * 1) It transforms the data from the NotificationPrefs API into the shape expected by the form.
 * 2) It wraps the `useFormState` hook and exports it's interface for consumption and interaction in other components.
 * 3) It provides a `handleSubmit` function that transforms the form state into the DTO expected by the NotificationPrefs update mutation.
 */

export type UseNotificationOptInFormStateReturnType = UseFormStateReturnType<TNotificationOptInFormValues> & {
  handleSubmit: (mutationCallbacks?: MutationCallbackOptions) => void;
};

export const emptyNotificationsFormState: UseNotificationOptInFormStateReturnType = {
  ...getEmptyFormState<TNotificationOptInFormValues>(),
  handleSubmit: () => undefined,
};

/** Form Config ====================== **/
const phoneValidations = [
  ({ enabled, val }: TEnabledField): string => ((enabled && isBlank(val)) ? "Phone number cannot be blank." : ""),
  ({ enabled, val }: TEnabledField): string => ((enabled && !isValidPhoneNumber(val)) ? "Invalid phone number." : ""),
];

const emailValidations = [
  ({ enabled, val }: TEnabledField): string => ((enabled && isBlank(val)) ? "Email cannot be blank." : ""),
  ({ enabled, val }: TEnabledField): string => ((enabled && !isValidEmail(val)) ? "Invalid email." : ""),
];

const getIsDirty = (initVal: TEnabledField, currVal: TEnabledField): boolean => (
  initVal.enabled !== currVal.enabled || initVal.val !== currVal.val
);

const getFormConfig = (initVals: TNotificationOptInFormValues): FormConfig => [
  {
    id: FIELD_KEYS.sms,
    initialValue: {...initVals[FIELD_KEYS.sms]},
    validations: phoneValidations,
    getIsDirty,
  } as FieldConfig<TEnabledField>,
  {
    id: FIELD_KEYS.phone,
    initialValue: {...initVals[FIELD_KEYS.phone]},
    validations: phoneValidations,
    getIsDirty,
  } as FieldConfig<TEnabledField>,
  {
    id: FIELD_KEYS.email,
    initialValue: {...initVals[FIELD_KEYS.email]},
    validations: emailValidations,
    getIsDirty,
  } as FieldConfig<TEnabledField>,
] as FormConfig;

/** Hook ====================== **/
interface IUseNotificationOptInFormState {
  notificationPrefsDTO?: TNotificationPrefsDTO;
  onSubmit: (variables: TNotificationPrefsDTO, mutationCallbacks?: MutationCallbackOptions) => void;
};

export const useNotificationOptInFormState = ({
  notificationPrefsDTO,
  onSubmit,
}: IUseNotificationOptInFormState): UseNotificationOptInFormStateReturnType => {
  const { user } = useGlobalContext();

  const initValues = React.useMemo(() => getInitFormValuesFromDTO(notificationPrefsDTO, user), [notificationPrefsDTO, user]);
  const formConfig = React.useMemo(() => getFormConfig(initValues), [initValues]);
  const formState = useFormState<TNotificationOptInFormValues>(formConfig);

  const handleSubmit = React.useCallback((mutationCallbacks?: MutationCallbackOptions) => {
    const variables = transformFormValuesToDTO(formState.values); 

    return onSubmit(variables, mutationCallbacks);
  }, [formState.values, onSubmit]);

  return {
    ...formState,
    handleSubmit,
  };
};
