import { useApiClient } from "_global/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { QUERY_KEYS } from "_global/constants";
import type { User } from "_global/types";
import type { TNotificationPrefsDTO } from "../types";

interface IUpdateNotificationPrefsMutationDTO {
  id: User["id"];
  preferences: TNotificationPrefsDTO;
}

export const useUpdateNotificationPrefs = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { user } = useGlobalContext();
  const { showErrorToast } = useToastRenderer();

  const updateNotificationPrefs = async (variables: TNotificationPrefsDTO) => {
    if (!user) throw new Error("No user found.");

    const dto: IUpdateNotificationPrefsMutationDTO = {
      id: user.id,
      preferences: variables,
    };

    return await apiClient.post("/notifications", dto);
  };

  const {
    mutate,
    isError,
    isSuccess,
    isPending: isLoading,
  } = useMutation({
    mutationFn: updateNotificationPrefs,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.preferences] });
    },
    onError: (err) => {
      console.error("useUpdateNotificationPrefs: onError", err);
      showErrorToast({ message: "Could not update notification preferences." });
    },
  });

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
  };
};
