import { Box, styled } from "@mui/system";
import { Notifications as BellIcon } from "@mui/icons-material";
import { StatusIndicator } from "_global/components/StatusIndicator/StatusIndicator";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { shouldUserSeeNotificationPrefsWarning } from "features/Account/utils";

const StyledAdornment = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: "6px",
  "& svg": {
    color: theme.palette.v2.primary.main,
    fontSize: "28px",
  },
}));

export const IndicatorAndIcon = () => {
  const { user } = useGlobalContext();
  const shouldShowStatusIndicator = shouldUserSeeNotificationPrefsWarning(user);
  return (
    <StyledAdornment>
      {shouldShowStatusIndicator && <StatusIndicator diameter="10px" variant="warning" />}
      <BellIcon />
    </StyledAdornment>
  );
};
