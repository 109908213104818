import * as React from "react";
import { DesignStudioController } from "../features/DesignStudio/DesignStudioController";
import { PageHeader } from "../features/PageHeader";
import { Stack } from "@mui/system";

export const DesignStudio: React.FC = () => {
  return (
    <Stack gap="40px">
      <PageHeader title="Design Studio" />
      <DesignStudioController />
    </Stack>
  );
};
