import SvgIcon from "@mui/material/SvgIcon";

export const HousingMarketReportIcon = () => {
    return (
        <SvgIcon style={{marginRight: "7px"}}>
            <svg
                width={25}
                height={25}
                viewBox="0 0 25 25"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20.941 10.395l-7.5-6.82a1.494 1.494 0 00-2.019 0l-7.499 6.82a1.503 1.503 0 00-.49 1.11v8.668a1.5 1.5 0 001.5 1.5h4.499a.75.75 0 00.75-.75v-6h4.5v6a.75.75 0 00.75.75h4.5a1.5 1.5 0 001.5-1.5v-8.668a1.502 1.502 0 00-.49-1.11z"
                />
            </svg>
        </SvgIcon>
    );
};
