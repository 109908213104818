import { LinkListSection as MoreWaysToExplore } from "../CTASections";
import { TimelineSection } from "../FeatureSections/TimelineSection/TimelineSection";
import { DashboardHeader } from "../components/Header/DashboardHeader";
import { PageLayoutGrid as Layout } from "_global/views/Layout/PageLayoutGrid";
import { SherpaContentSection } from "../FeatureSections/TimelineSection/SherpaContentSection";
import { ContractedBuyerDashboardContextProvider } from "../contexts/ContractedBuyerDashboardContext";

export const ContractedBuyerDashboard = () => (
  <ContractedBuyerDashboardContextProvider>
    <Layout.GridContainer>
      <Layout.GridItem fullRow debugId={5}>
        <DashboardHeader />
      </Layout.GridItem>
      <Layout.GridItem
        debugId={1}
        sx={{
          marginRight: { // allow carousel to express overflow
            xs: "-12px",
            sm: "-30px",
          }
        }}
      >
        <TimelineSection />
      </Layout.GridItem>
      <Layout.GridItem large debugId={2}>
        <SherpaContentSection />
      </Layout.GridItem>
      <Layout.GridItem small debugId={3}>
        <MoreWaysToExplore isContracted />
      </Layout.GridItem>
    </Layout.GridContainer>
  </ContractedBuyerDashboardContextProvider>
);
