import * as React from "react";
import { styled, Box } from "@mui/system";
import { Button as BaseButton } from "@mui/base";

const StyledBox = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  width: "100%",
  fontSize: "16px",
  fontWeight: 600,
  letterSpacing: ".25px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0, 1.5),
  flexWrap: "wrap",
}));

const StyledButton = styled(BaseButton)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none",
  textDecoration: "underline",
  fontSize: "inherit",
  fontWeight: 700,
  color: "inherit",
  cursor: "pointer",
  textUnderlineOffset: "1.5px",
  padding: 0,
  "&:hover": {
    textShadow: `0 0 0.2px ${theme.palette.primary.light}`,
  },
}));

type SignUpProps = {
  onClickSignUp: () => void;
};

export const SignUp: React.FC<SignUpProps> = ({ onClickSignUp }) => (
  <StyledBox>
    <Box component="span">
      Don't have an account?
    </Box>
    <StyledButton onClick={onClickSignUp}>
      Sign up
    </StyledButton>
  </StyledBox>
);
