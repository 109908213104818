import { DOCUMENT_HELP_INFO_MAP } from "./constants";
import type { TDocumentEnvelope, TFlatDocument, TDocumentHelpInfo } from "./types";

const getFlattenedDocumentFromEmptyEnvelope = (envelope: TDocumentEnvelope): TFlatDocument => {
  // if `envelope["documents"]` array is empty, derive a flat document from the parent envelope
  // uncertain if this is ever an actual condition, this is a precaution
  return {
    ...envelope,
    uid: envelope.documentsUri,
    documentId: "",
    docType: "envelope",
  };
};

const flattenDocumentEnvelope = (envelope: TDocumentEnvelope): TFlatDocument[] => {
  const { documents, ...rest } = envelope;

  if (!documents.length) return [getFlattenedDocumentFromEmptyEnvelope(envelope)];

  return documents.map((doc, idx) => {
    const { docType, documentId, name } = doc;
    
    // default to array index if no documentId or docType is provided - will be used as "key" prop in loops
    const uidPrefix = documentId || docType || name || idx; // uncertain if this is a valid assumption or what values are guaranteed in the DTO
    const uid = `${uidPrefix}__${envelope.documentsUri}`;
  
    return {
      ...rest,
      ...doc,
      name: name || envelope.name || "Untitled Document",
      documentId: documentId || "",
      docType: docType || "",
      uid,
    };
  });
};

export const getFlatDocuments = (envelopes: TDocumentEnvelope[]): TFlatDocument[] => {
  return envelopes.flatMap(flattenDocumentEnvelope);
};

export const formatDateDisplay = (date: string) => {
  const d = new Date(date);
  const isInvalidateDate = isNaN(d.getTime());
  if (isInvalidateDate) return date;
  return d.toLocaleDateString("en-US"); // "1/1/2021"
};

export const getDocumentHelpInfo = (document: TFlatDocument): TDocumentHelpInfo | null => {
  // a document["docType"] must match a key in the DOCUMENT_HELP_INFO_MAP to retrieve help info
  if (!document.docType) return null;
  return DOCUMENT_HELP_INFO_MAP[document.docType] || null;
};
