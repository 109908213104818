import { ToastRendererOptions } from "_global/components/Toast/types";
import { SavedHomeDTO, SavedHomeMetadata, TSavedHome } from "./types";
import DeleteIcon from "@mui/icons-material/Delete";

/**
 * Takes the metadata string from the API and JSON parses it into values,
 * mapping over and simplifying the keys for easier use
 * @param metadata
 */
export const parseMetadata = (metadata: string) => {
  const homeData = {} as SavedHomeMetadata;
  try {
    const parsed = JSON.parse(metadata);
    Object.entries(parsed).map(([k, v]) => {
      const key = k.replace("pahlisch_property_", "");
      homeData[key] = v;
    });
  } catch (e) {
    console.log(e);
  }
  return homeData;
};

/**
 * Takes a raw data transfer object from the API and turns it into a client-friendly format
 * @param home SavedHomeDTO
 * @returns TSavedHome
 */
export const transformSavedHomeData = (home: SavedHomeDTO): TSavedHome => {
  const metadata = parseMetadata(home.metadata);
  
  return {
    id: home.id.toString(),
    wpId: home.wpId,
    title: home.name,
    thumbnail: home.thumbnailSrc || "",
    isSold: home.isSold,
    link: `https://pahlischhomes.com/homes/${home.slug}`,
    slug: home.slug,
    price: metadata?.price ? parseInt(metadata.price, 10) : undefined,
    locationDetails: {
      latLong: metadata?.location,
      formattedAddress: metadata?.address || "",
      city: metadata?.city,
      state: metadata?.state,
      zip: metadata?.zip,
    },
    homeDetails: {
      beds: metadata?.beds,
      baths: metadata?.baths,
      sqft: metadata?.sqft,
      garageCarCapacity: metadata?.garages,
      propertyType: metadata?.type || ""
    },
    propertyType: metadata?.type || "",
    propertyStatus: metadata?.status,
  };
};

const isHomeActive = (home: TSavedHome) => !home.isSold;

export const getActiveSavedHomes = (homes: TSavedHome[]): TSavedHome[] => (
  homes.filter(isHomeActive)
);

type TFilteredSavedHomeLists = {
  activeSavedHomes: TSavedHome[];
  soldSavedHomes: TSavedHome[];
};

export const getFilteredSavedHomeLists = (
  homes: TSavedHome[],
): TFilteredSavedHomeLists => {
  const activeSavedHomes: TSavedHome[] = [];
  const soldSavedHomes: TSavedHome[] = [];

  homes.forEach((home) =>
    isHomeActive(home)
      ? activeSavedHomes.push(home)
      : soldSavedHomes.push(home),
  );

  return { activeSavedHomes, soldSavedHomes };
};

interface IUndoDeleteToastData {
  id: string;
  onClickUndo: () => void;
}

// Users will be prompted to "undo" the deletion of a saved home with this toast
export const getUndoDeleteToastData = ({
  id,
  onClickUndo,
}: IUndoDeleteToastData): ToastRendererOptions => ({
  id,
  variant: "info",
  icon: DeleteIcon,
  duration: 5000,
  action: {
    label: "Undo",
    onClick: onClickUndo,
  },
  message: "Home removed",
});

export const getPluralizedLabel = (label: string, value: string | undefined): string => {
  let pluralizedLabel: string;
  if (!value) {
    pluralizedLabel = label;
  } else {
    pluralizedLabel = Number(value) > 1 ? `${label}s` : label;
  }
  return pluralizedLabel;
};
