import * as React from "react";
import { Navigate } from "react-router-dom";
import { useLogout } from "./hooks/useLogout";
import { useAuth0 } from "@auth0/auth0-react";
import { FullPageLoadingSpinner } from "_global/components/Progress/LoadingSpinners";

// this component is intended as an escape hatch for dev & qa when stuck in an indeterminate state without access to a logout button.
// it will log the user out and redirect them to the root path / login page.
export const Logout = () => {
  const { isAuthenticated} = useAuth0();
  const { handleLogout } = useLogout();

  React.useEffect(() => {
    if (isAuthenticated) handleLogout();
  }, [handleLogout, isAuthenticated]);

  return isAuthenticated ? <FullPageLoadingSpinner /> : <Navigate to="/" replace />;
};
