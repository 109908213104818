import { Box, Stack, styled } from "@mui/system";
import { Button as BaseButton, type ButtonProps as BaseButtonProps } from "@mui/base";
import { Verified } from "@mui/icons-material";
import { themeFonts } from "_global/theme/typography";
import { SPACING_Y } from "../components/constants";
import { StyledTextArea, StyledTextInput } from "../components/StyledFormElements";

const StyledVerifiedEmail = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: `-${SPACING_Y}`,
  ...themeFonts.textLgBold,
  color: theme.palette.v2.success.main,
  "& svg": {
    color: theme.palette.v2.success.main,
    height: "18px",
    width: "20px",
  },
  [theme.breakpoints.up("md")]: {
    marginTop: "15px", // centers element with the email input (hidden 'helpText' occupies space and so flexbox centering looks weird)
  },
}));

export const VerifiedEmailIndicator = () => {
  return (
    <StyledVerifiedEmail>
      <Verified />
      <Box component="span">
        Email Verified!
      </Box>
    </StyledVerifiedEmail>
  );
};

export const ResetPasswordButton = styled(BaseButton)<BaseButtonProps>(({ theme }) => ({
  color: theme.palette.v2.primary.main,
  textDecoration: "underline",
  ...themeFonts.textLgSemibold,
  border: "none",
  backgroundColor: "transparent",
  textUnderlineOffset: "3px",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.v2.primary.hover,
  },
  "&:disabled": {
    color: theme.palette.v2.neutral[60],
    cursor: "not-allowed",
  },
}));

export const ResetPasswordDisplayMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.v2.neutral[90],
  ...themeFonts.textSm,
  minHeight: themeFonts.textSm.lineHeight,
  fontStyle: "italic",
  paddingLeft: "4px",
}));

export const ResetPasswordSection = styled(Stack)(() => ({
  alignItems: "flex-start",
  gap: "2px",
}));

type AdditionalEmailsSectionProps = {
  emails: string[];
};

export const AdditionalEmailsSection: React.FC<AdditionalEmailsSectionProps> = ({ emails }) => {
  const emailCount = emails.length;
  const valueWithLineBreaks: string = emails.join("\n") || "";

  if (!emailCount) return null;

  if (emailCount === 1) return (
    <StyledTextInput
      id="additionalEmails"
      readOnly
      disabled
      label="Additional Email"
      value={emails[0]}
    />
  );

  return (
    <StyledTextArea
      id="additionalEmails"
      readOnly
      disabled
      rows={emailCount}
      label="Additional Emails"
      value={valueWithLineBreaks}
      style={{
        resize: "none",
        whiteSpace: "pre-wrap",
      }}
    />
  );
};
