import * as React from "react";
import { styled, Box, Stack } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { Tile } from "_global/components/Containers";
import { StatusChip } from "features/Timeline/components/StatusChip";
import { Image } from "_global/components/Images";
import { themeFonts } from "_global/theme/typography";

const ContainerTile = styled(Tile)(({ theme }) => ({
  width: "100%",
  gap: "20px",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  padding: "20px 30px",
  maxWidth: "750px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    padding: "20px 8px",
  },
}));

const Title = styled(Box)(({ theme }) => ({
  ...themeFonts.headingH4,
  textAlign: "center",
  color: theme.palette.v2.neutral[90],
}));

const Description = styled(Box)(({ theme }) => ({
  ...themeFonts.textLReading,
  marginTop: "8px",
  color: theme.palette.v2.neutral[100],
  textAlign: "center",
}));

type VideoIFrameProps = {
  src: string;
  width?: string;
  height?: string;
  title: string;
};

const VideoIFrame: React.FC<VideoIFrameProps> = ({ src, height = undefined, width="100%", title }) => (
    <iframe
      width={width}
      height={height}
      frameBorder="0"
      src={src}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title={title}
    />
);

type HeroImageProps = {
  src: string;
  alt: string;
  height?: string;
  width?: string;
};

const HeroImage: React.FC<HeroImageProps> = ({ src, alt, height = "260px", width = "300px" }) => (
  <Image
    src={src}
    alt={alt}
    height={height}
    width={width}
    borderRadius="8px"
  />
);

const SherpaContentLoadingSkeleton = () => (
  <ContainerTile minHeight="600px">
    <Stack alignItems="center" justifyContent="center" sx={{ height: "600px", color: "#C9C9C9" }}>
      <CircularProgress color="inherit" />
    </Stack>
  </ContainerTile>
);

export const SherpaContentTile = {
  Container: ContainerTile,
  StatusChip,
  Title,
  Description,
  HeroImage,
  VideoIFrame,
  LoadingSkeleton: SherpaContentLoadingSkeleton,
};
