import DeleteIcon from "@mui/icons-material/Delete";
import { ToastRendererOptions } from "_global/components/Toast/types";

export const getDefaultDesignName = () => {
  const today = new Date();
  const mmddyy = today.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
  const date = mmddyy.split("/").join("");
  return `WayfinderModel1_${date}`;
};


function truncateName(name: string) {
  return name.length > 30 ? `${name.substring(0, 30).trim()}...` : name;
}

interface IUndoDeleteToastData {
  id: string;
  name: string;
  onClickUndo: () => void;
}

// Users will be prompted to "undo" the deletion of a saved design with this toast
export const getUndoDeleteToastData = ({
  id,
  name,
  onClickUndo,
}: IUndoDeleteToastData): ToastRendererOptions => ({
  id,
  variant: "info",
  icon: DeleteIcon,
  duration: 5000,
  action: {
    label: "Undo",
    onClick: onClickUndo,
  },
  message: `Deleted ${truncateName(name)}`,
});
