import CircularProgress from "@mui/material/CircularProgress";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { DocumentsController, type TDocumentsViewProps } from "./controllers/DocumentsController";
import { DocumentsDataGrid } from "./components/DocumentsDataGrid";
import { Tile } from "_global/components/Containers";
import { Typography } from "_global/components/Typography/Typography";


const DocumentsListRenderer = ({ documentEnvelopes, isLoading, isError }: TDocumentsViewProps) => {
  // Loading State
  if (isLoading) return (
    <Tile variant="lightTile" alignItems="center" justifyContent="center" sx={{ height: "250px", color: "#C9C9C9" }}>
      <CircularProgress color="inherit" />
    </Tile>
  );

  // Empty State / & Empty Error State
  if (!documentEnvelopes?.length) return (
    <Typography.Paragraph>
      {isError ? "We were unable to load your documents at this time." : "No documents found."}
    </Typography.Paragraph>
  );

  // Populated State
  return <DocumentsDataGrid documentEnvelopes={documentEnvelopes} />;
};

export const DocumentsListing = () => {
  const { user } = useGlobalContext();
  if (!user) return null;

  return (
    <DocumentsController component={DocumentsListRenderer} />
  );
};
