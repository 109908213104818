import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { Image } from "_global/components/Images";
import { Tile } from "_global/components/Containers";
import { themeFonts } from "_global/theme/typography";
import { ReactComponent as MapMarkerIcon } from "_global/assets/icons/map-marker.svg";
import type { THomeDetail } from "features/ContractedHomes/types";
import { ReactComponent as DefaultHomeSVG } from "_global/assets/default-home.svg";

type HeroImageProps = {
  src: string;
  width?: string;
};

export const HeroImage: React.FC<HeroImageProps> = ({
  src,
  width = "78px"
}) => (
  <Box flexShrink={0} height={width} width={width} display="flex" alignItems="center">
    <Image src={src} alt="Your home" height="100%" width="100%" borderRadius="50%" />
  </Box>
);

export const DefaultHeroImage = () => (
    <Box flexShrink={0} height={"78px"} width={"78px"} display="flex" alignItems="center" justifyContent="center" style={{backgroundColor: "#1349581A", borderRadius: "50%"}}>
        <DefaultHomeSVG height={"48px"} width={"48px"} style={{marginTop: "-5px"}}/>
    </Box>
);

const StyledTile = styled(Tile)(({ theme }) => ({
  minHeight: "80px",
  width: "100%",
  borderTop: `2px solid ${theme.palette.v2.neutral[30]}`,
  borderRadius: "0px 0px 16px 16px",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  padding: "18px 28px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const StyledText = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  color: theme.palette.v2.neutral[90],
  ...themeFonts.textLgSemibold,
}));

const StyledLink = styled("a")(({ theme }) => ({
  textDecoration: "unset",
  ...themeFonts.textSmSemibold,
  color: theme.palette.v2.neutral[90],
  display: "flex",
  "&:hover": {
    color: theme.palette.v2.secondary.main,
    "svg #Shape": { // specific to MapMarkerIcon (#Shape is the id of the path element)
      fill: theme.palette.v2.secondary.main,
    },
  },
}));

export const MainContent: React.FC<THomeDetail> = ({
  name,
  model,
  address,
  coordinates,
}) => (
  <Stack gap="10px">
    <StyledText>
      {/* TODO: replace with home name when available */}
      {name ? name : `Model #${model}`}
    </StyledText>
    <StyledLink
      href={`https://www.google.com/maps/search/?api=1&query=${coordinates}`}
      target="_blank"
      title="Open in Google Maps"
    >
      <Box component={MapMarkerIcon} ml="-10px" mt="-1.5px" sx={{ height: "20px"}} />
      <Box>{address}</Box>
    </StyledLink>
  </Stack>
);

export const DefaultContent = () => (
    <Stack gap="10px">
        <StyledText sx={{
            width: "100%",
            ...themeFonts.textMdItalic,
            fontSize: "12px",
        }}>Congratulations on your new Pahlisch home</StyledText>
    </Stack>
);

const HomeAddressTileSkeleton = () => (
  <StyledTile>
    <Stack alignItems="center" justifyContent="center" sx={{ height: "60px", width: "100%", color: "#C9C9C9" }}>
      <CircularProgress color="inherit" />
    </Stack>
  </StyledTile>
);

const HomeAddressTileEmpty = ({ message }: { message: string }) => (
  <StyledTile>
    <StyledText 
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        ...themeFonts.textMdItalic,
        fontSize: "12px",
      }}
    >
      {message}
    </StyledText>
  </StyledTile>
);

export const HomeTile = {
  Container: StyledTile,
  HeroImage,
  DefaultHeroImage,
  MainContent,
  DefaultContent,
  Loading: HomeAddressTileSkeleton,
  Empty: HomeAddressTileEmpty,
};
