import * as React from "react";
import { Link, useMatch } from "react-router-dom";
import { Box } from "@mui/system";
import { MoreHorizOutlined } from "@mui/icons-material";

import { StyledNavItem } from "./PrimaryNav.elements";

import { NavRoute } from "../../util/routes";
import { DrawerContext } from "_global/components/Drawers/contexts/DrawerContext";

export const PrimaryNavItem: React.FC<NavRoute> = ({
  path,
  icon,
  label,
  shortLabel = "",
}) => {
  const match = useMatch(path);
  const isActive = !!match;
  const { open } = React.useContext(DrawerContext);

  const Icon = icon;

  const className = isActive && !open ? "active" : "";

  return (
    <Link to={path} style={{ textDecoration: "none" }}>
      <StyledNavItem className={className}>
        <Box className="iconContainer">
          <Icon fontSize="inherit" />
        </Box>
        {shortLabel || label}
      </StyledNavItem>
    </Link>
  );
};

export const ToggleExpandButton: React.FC= () => {
  const { open, openDrawer, transitionStatus } = React.useContext(DrawerContext);

  const ariaLabel = open ? "Close menu" : "Open menu";

  const isDrawerVisible = React.useMemo(() => (
    open || transitionStatus.isClosing
  ), [open, transitionStatus.isClosing]);

  const onClick = React.useCallback(() => {
    if (!isDrawerVisible) openDrawer();
  }, [isDrawerVisible, openDrawer]);

  return (
    <StyledNavItem
      aria-label={ariaLabel}
      onClick={onClick}
      className={open ? "active" : ""}
      component="button"
      sx={{ borderColor: "unset" }}
    >
      <Box className="iconContainer">
        <MoreHorizOutlined fontSize="inherit" />
      </Box>
      More
    </StyledNavItem>
  );
};
