import * as React from "react";
import { styled } from "@mui/system";
import { themeFonts } from "_global/theme/typography";

export const DISABLED_CLASS = "disabled";

export const StyledLabel = styled("label")(({ theme }) => {
  const { palette } = theme;

  const colors = React.useMemo(() => ({
    text: palette.v2.neutral[90],
    textDisabled: palette.v2.neutral[60],
  }), [palette]);

  return {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    cursor: "pointer",
    color: colors.text,
    ...themeFonts.textMd,
    [`&.${DISABLED_CLASS}`]: {
      cursor: "not-allowed",
      color: colors.textDisabled,
    },
  };
});

export const StyledInput = styled("input")(({ theme } ) => {
  const { palette } = theme;

  const colors = React.useMemo(() => ({
    btnBorder: palette.v2.neutral[50],
    btnActive: palette.v2.primary.main,
    btnBorderDisabled: palette.v2.neutral[40],
    btnOutlineFocus: palette.v2.primary.focus,
    btnBg: palette.v2.neutral[10],
    btnBgDisabled: palette.v2.neutral[20],
    btnCheckDisabled: palette.v2.neutral[50],
  }), [palette]);

  return {
    appearance: "none",
    cursor: "pointer",
    width: "20px",
    minWidth: "20px",
    height: "20px",
    minHeight: "20px",
    borderRadius: "50%",
    border: `1px solid ${colors.btnBorder}`,
    backgroundColor: colors.btnBg,
    "&:hover:not(:disabled)": {
      borderColor: colors.btnActive,
    },
    "&:focus": {
      outline: `3px solid ${colors.btnOutlineFocus}`,
    },
    "&:checked": {
      borderColor: colors.btnActive,
      "&::after": {
        content: "''",
        display: "block",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: `3px solid ${colors.btnBg}`,
        backgroundColor: colors.btnActive,
      },
    },
    "&:disabled": {
      cursor: "not-allowed",
      borderColor: colors.btnBorderDisabled,
      backgroundColor: colors.btnBgDisabled,
      "&::after": {
        backgroundColor: colors.btnCheckDisabled,
        border: `3px solid ${colors.btnBgDisabled}`,
      },
      "&:checked": { 
        boxShadow: `0px 0px 0px 1px ${colors.btnBorderDisabled}`,
      },
    },
  };
});
