/** 
* Color values correspond to design specs
* Original name of color is provided as comment for reference
**/

export const blue = {
  50: "#E6F0F1",   // "Shade 5.5"
  100: "#E2F3F4", // "Shade 4"
  125: "#D6ECEC", // "Shade 4.5"
  200: "#C1DEE2", // "Shade 3"
  225: "#C0DCE0", // "#C0DCE0" (HomeCard - Price Chip)
  300: "#77B5C3", // "Shade 2"
  400: "#4E99AA", // "Shade 1"
  500: "#0085AD", // "Blue" (Primary)
  700: "#005E7A", // 
  900: "#134958", // "Dark Blue"
};

export const grey = {
  25: "#FBFBFB",  // "Tile light"
  50: "#F5F5F5",  // "Grey Sidebar & Card F5F5F5" // New name - "tile"
  75: "#EFF3F1",  // "Shade 5"
  85: "#EEEEEE",  // "#EEEEEE"
  100: "#E7E7E7", // "Light Grey"
  125: "#E3E3E3", // "#E3E3E3"
  200: "#DDDDDD", // "#DDDDDD"
  300: "#D9D9D9", // "Grey D9D9D9"
  400: "#BBBBBB", // "#BBBBBB"
  500: "#A9A9A9", // "Grey"
  700: "#797979", // "Placeholder"
  800: "#444445", // "#444445"
  900: "#4C4A4A", // "Dark Grey"
};

export const orange = {
  400: "#F4A523", // "Orange"
};

export const red = {
  500: "#D52B1E", // "Red"
  800: "#AE1E1E", // "AE1E1E"
};

export const green = {
  500: "#4C7F28", // "Green"
};

export const black = "#000000";
export const white = "#FFFFFF";
