import {
  TPhaseNames as PHASE_NAMES,
  TPhaseStatuses as PHASE_STATUSES,
  type TPhaseResponseDTO,
  type TPhaseKey,
  type TPhaseStatus,
} from "features/ContractedHomes/types";

import { ReactComponent as PourFoundation } from "_global/assets/images/timelineCardThumbnails/pour_foundation.svg";
import { ReactComponent as FramingAndTrusses } from "_global/assets/images/timelineCardThumbnails/framing_trusses.svg";
import { ReactComponent as PlumbingAndElectrical } from "_global/assets/images/timelineCardThumbnails/plumbing_electrical.svg";
import { ReactComponent as SheetrockTapeAndTexture } from "_global/assets/images/timelineCardThumbnails/sheetrock_tape_texture.svg";
import { ReactComponent as CountersAndFlooring } from "_global/assets/images/timelineCardThumbnails/counters_floring.svg";
import { ReactComponent as FinalFitAndFinish } from "_global/assets/images/timelineCardThumbnails/final_fit_finish.svg";
import { ReactComponent as QualityAssurance } from "_global/assets/images/timelineCardThumbnails/quality_assurance.svg";
import { ReactComponent as HomeCompletion } from "_global/assets/images/timelineCardThumbnails/home_completion.svg";

export type TPhaseInfo = {
  title: string;
  thumbnail: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  videoEmbedSrc?: string;
  heroImgUrl?: string;
  description: string;
  status?: TPhaseStatus;
};

type TPhaseInfoLookup = Record<TPhaseKey, TPhaseInfo>;

export const PHASE_INFO: TPhaseInfoLookup = {
  [PHASE_NAMES["Foundation Stage"]]: {
    title: "Pour Foundation",
    thumbnail: PourFoundation,
    videoEmbedSrc:
      "https://player.vimeo.com/video/901672082?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    description:
      "A foundation serves as the bedrock of a home, providing stability and support for the entire structure. A solid foundation is the fundamental element that anchors a home, laying the groundwork for a safe and durable living space.",
  },
  [PHASE_NAMES["Framing Stage"]]: {
    title: "Framing & Trusses",
    thumbnail: FramingAndTrusses,
    videoEmbedSrc:
      "https://player.vimeo.com/video/901672114?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    description:
      "Framing and trusses form the structural skeleton of a home, translating architectural designs into a sturdy and functional reality. The framing establishes the framework for walls, floors, and roofs, dictating the layout and ensuring the structural integrity of the entire building. Trusses, with their triangular configuration, distribute the load efficiently, providing essential support for the roof and maintaining the overall strength and resilience of the house. Together, framing and trusses are the essential building blocks that bring a blueprint to life, creating a robust and reliable structure for a home.",
  },
  [PHASE_NAMES["Rough-In Stage"]]: {
    title: "Plumbing & Electrical Rough-In",
    thumbnail: PlumbingAndElectrical,
    videoEmbedSrc:
      "https://player.vimeo.com/video/901672195?h=d4503c5efa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    description:
      "Plumbing and electrical rough-in are a home's circulatory and nervous systems, respectively. Plumbing rough-in establishes the groundwork for water supply and drainage, ensuring that essential utilities flow seamlessly throughout the structure. Simultaneously, electrical rough-in sets the foundation for a home's power network, laying the groundwork for lighting, appliances, and technology. These behind-the-scenes installations are crucial, shaping the functionality and convenience of a home, and their meticulous planning during the construction phase ensures seamless integration of essential services when a home is complete.",
  },
  [PHASE_NAMES["Drywall Stage"]]: {
    title: "Sheetrock, Tape, & Texture",
    thumbnail: SheetrockTapeAndTexture,
    videoEmbedSrc:
      "https://player.vimeo.com/video/906103558?h=c21dda1abe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    description:
      "Sheetrock, tape, and texture serve as a home's skin and finishing touches, transforming raw construction into a polished living space. Sheetrock, or drywall, provides a smooth and uniform surface, concealing the structural elements and creating a canvas for interior design. Tape reinforces seams and joints, ensuring a seamless appearance. Texture adds character and style, turning walls from mere partitions into visually appealing and tactile elements. Together, these elements enhance the aesthetics and contribute to the functionality and comfort of a home, providing the final layer that brings warmth and completeness to the living space.",
  },
  [PHASE_NAMES["Surfaces Stage"]]: {
    title: "Counters, Flooring, & Tile Install",
    thumbnail: CountersAndFlooring,
    videoEmbedSrc:
      "https://player.vimeo.com/video/908308143?h=2cdf33c9a7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    description:
      "Counters, flooring, and tile are the tactile and aesthetic elements that elevate a home's functionality and visual appeal. Countertops in kitchens and bathrooms provide essential work surfaces, while flooring establishes the foundation for a comfortable and durable living space. Tile is used in kitchens and bathrooms or as decorative accents, adding style and practicality. These elements contribute to the overall design aesthetic and play a crucial role in daily life, providing durability, ease of maintenance, and a touch of personalization that truly makes a house feel like a home.",
  },
  [PHASE_NAMES["Finishes Stage"]]: {
    title: "Finishing Touches",
    thumbnail: FinalFitAndFinish,
    videoEmbedSrc:
      "https://player.vimeo.com/video/908326316?h=04728c22c0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    description:
      "The finishing touches are the details that reflect the care and precision invested in its construction. Attention to these details ensures that doors close seamlessly, cabinets align perfectly, and surfaces boast a flawless polish. These finishing touches enhance the aesthetic appeal and contribute to the overall functionality and comfort of the living space.",
  },
  [PHASE_NAMES["Quality Assurance Stage"]]: {
    title: "Quality Assurance Approval",
    thumbnail: QualityAssurance,
    videoEmbedSrc:
      "https://player.vimeo.com/video/908327054?h=e48951eb4c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    description:
      "A quality assurance check in home construction is akin to a thorough health check-up for the building, ensuring that every aspect meets the highest standards. It identifies and rectifies potential issues and ensures that a home adheres to all building codes. Ultimately, this meticulous examination is the safeguard that assures homeowners that their investment is not just a structure but a reliable and enduring sanctuary.",
  },
  [PHASE_NAMES["Completion"]]: {
    title: "Home Completion",
    thumbnail: HomeCompletion,
    videoEmbedSrc:
      "https://player.vimeo.com/video/908329055?badge=0&autopause=0&player_id=0&app_id=58479",
    description:
      "The completion of a home marks the realization of a dream, a culmination of anticipation, planning, and hard work. For new owners, it signifies the beginning of a chapter where a physical space transforms into a personal haven, where memories will be made and milestones celebrated. The excitement stems from a finished structure and the prospect of turning a house into a home filled with warmth, comfort, and the promise of a future full of joy and shared experiences.",
  },
};

export const PHASE_LIST_FOR_NONCONTRACTED_BUYER: TPhaseResponseDTO = [
  {
    name: PHASE_NAMES["Foundation Stage"],
    status: PHASE_STATUSES["Not Started"],
  },
  { name: PHASE_NAMES["Framing Stage"], status: PHASE_STATUSES["Not Started"] },
  {
    name: PHASE_NAMES["Rough-In Stage"],
    status: PHASE_STATUSES["Not Started"],
  },
  { name: PHASE_NAMES["Drywall Stage"], status: PHASE_STATUSES["Not Started"] },
  {
    name: PHASE_NAMES["Surfaces Stage"],
    status: PHASE_STATUSES["Not Started"],
  },
  {
    name: PHASE_NAMES["Finishes Stage"],
    status: PHASE_STATUSES["Not Started"],
  },
  {
    name: PHASE_NAMES["Quality Assurance Stage"],
    status: PHASE_STATUSES["Not Started"],
  },
  { name: PHASE_NAMES["Completion"], status: PHASE_STATUSES["Not Started"] },
];
