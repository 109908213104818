import * as React from "react";
import { StyledCard } from "./HomeCard.elements";
import { PriceChip } from "./PriceChip";
import { DetailListRow } from "./DetailListRow";
import { StatusRow } from "./StatusRow";
import { LocationRow } from "./LocationRow";
import { DeleteButton } from "./DeleteButton";
import type { TSavedHome } from "../../types";

export type HomeCardProps = TSavedHome & {
  onDelete?: () => void;
};

export const HomeCard = (props: HomeCardProps) => {
  const {
    title,
    price,
    link,
    isSold,
    thumbnail,
    homeDetails,
    locationDetails,
    propertyStatus,
    onDelete,
  } = props;

  const [isDeleting, setIsDeleting] = React.useState(false);

  const { formattedAddress } = locationDetails;

  const handleDelete = () => {
    if (!onDelete) return;
    setIsDeleting(true);
    onDelete();
  };

  return (
    <StyledCard.Container isDeleting={isDeleting}>
      {onDelete && (
        <DeleteButton
          address={formattedAddress}
          onClick={handleDelete}
          disabled={isDeleting}
        />
      )}
      <StyledCard.Thumbnail src={thumbnail} alt={formattedAddress} />
      <StyledCard.Body>
        <StyledCard.TitleRow
          title={title}
          link={link}
          isSold={isSold}
          propertyType={"Home"}
        />
        {price && <PriceChip price={price} />}
        <DetailListRow {...homeDetails} />
        {propertyStatus === "Move-In Ready" && <StatusRow propertyStatus={propertyStatus} />}
        <LocationRow {...locationDetails} />
      </StyledCard.Body>
    </StyledCard.Container>
  );
};
