import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, AppState } from "@auth0/auth0-react";

type Props = {
  children: React.ReactNode;
};

export const Auth0ProviderWithNavigate: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  // ------------------------------------------------------- //
  // ------------------------------------------------------- //

  /**
   * TODO: change `window.location.origin` to an ENV variable (Dev / Staging / Prod)
   * - we don't have a 'STAGING' env yet in Auth0
   * - we only have 'DEVELOPMENT' keys
   * - we need to direct localhost back to localhost, and our temp staging site back to our temp staging site
   * 'window.location.origin' will provide the current URL
   * - both localhost and https://yellow-stone-0f656d11e.3.azurestaticapps.net (temp staging site) are registered in Auth0 as allowed callback URLs
   */

  // const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const redirectUri = window.location.origin + "/";

  // ------------------------------------------------------- //
  // ------------------------------------------------------- //

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
