import * as React from "react";
import { Box, styled } from "@mui/system";
import { useCarouselContext } from "../contexts/CarouselContext";

export const CAROUSEL_ITEM_CLASSNAME = "carousel-item";

const StyledScrollSnap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  overflowX: "auto",
  padding: theme.spacing(2, 0),
  scrollSnapType: "x mandatory",
  scrollBehavior: "auto",
  borderRadius: "4px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  [`& .${CAROUSEL_ITEM_CLASSNAME}`]: {
    scrollSnapAlign: "center", // default snap to center of carousel
    scrollSnapStop: "always", // stop on each item
  },
}));

type ScrollContainerProps = {
  children: React.ReactNode;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
};

export const ScrollContainerCore = React.forwardRef<HTMLDivElement, ScrollContainerProps>(
  ({ children, onScroll }, ref) => {
    const { rightDisabled } = useCarouselContext();
  return (
    <StyledScrollSnap
      ref={ref}
      onScroll={onScroll}
      sx={{
        // inner content shadow on scroll window
        boxShadow: rightDisabled ? "none" : "inset -20px 0px 20px -5px #7f7e7e0d",
        margin: theme => theme.spacing(-2),
      }}
    >
      {children}
    </StyledScrollSnap>
  );
});
