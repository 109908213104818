import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const PreferredLenderIcon = () => {
    return (
        <SvgIcon style={{marginRight: "7px"}}>
            <svg
                width={25}
                height={25}
                viewBox="0 0 25 25"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22.182 5.923h-19.5a1.502 1.502 0 00-1.5 1.5v10.5a1.502 1.502 0 001.5 1.5h19.5a1.502 1.502 0 001.5-1.5v-10.5a1.501 1.501 0 00-1.5-1.5zm-14.722 12l-4.778-4.095v-2.31L7.46 7.423h9.945l4.777 4.095v2.31l-4.777 4.095H7.46zm8.722-5.25a3.75 3.75 0 11-7.499 0 3.75 3.75 0 017.5 0z"
                />
            </svg>
        </SvgIcon>
    );
};
