import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "_global/views/Layout/Layout";

import { NavigationSidebar } from "features/Navigation/NavigationSidebar";
import { NavigationBottomBar } from "features/Navigation/NavigationBottomBar";

export const AppLayout = () => {
  return (
    <Layout.AppContainer>
      <Layout.Sidebar>
        <NavigationSidebar />
      </Layout.Sidebar>
      <Layout.MainContent>
        <Outlet />
      </Layout.MainContent>
      <Layout.BottomBar>
        <NavigationBottomBar />
      </Layout.BottomBar>
    </Layout.AppContainer>
  );
};
