import * as React from "react";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import type { TeamsDTO } from "../types";
import { QUERY_KEYS } from "_global/constants";

type UseGetTeamsOptions = {
  enabled?: boolean;
};

export const useGetTeams = (options?: UseGetTeamsOptions): UseQueryResult<TeamsDTO, Error> => {
  const { enabled = true } = options || {};
  const { showErrorToast } = useToastRenderer();

  const client = useApiClient();

  const queryState = useQuery<TeamsDTO, Error>({
    enabled,
    queryKey: [QUERY_KEYS.team, "all"],
    queryFn: async () => {
      const response = await client.get<TeamsDTO>("/teams");
      return response.data;
    },
  });

  const { isError } = queryState;

  React.useEffect(() => {
    if (queryState.isError) showErrorToast({ id: "err-fetch-teams", message: "Error fetching team member info" });
  }, [isError]);

  return queryState;
};
