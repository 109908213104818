import ReactGA from "react-ga4";
import { GA_KEY } from "./constants";

export const initializeGA = () => {
  ReactGA.initialize(GA_KEY);
  console.log("GA initialized");
};

export const trackGAEvent = ({
  category = "Wayfinder",
  action,
  label,
}: {
  category: string;
  action: string;
  label?: string;
}) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
