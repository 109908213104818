import * as React from "react";
import { Banner } from "../Banner/Banner";
import { StyledStack } from "./BannerListRenderer.elements";
import type { BannerData } from "../types";
import { Stack } from "@mui/system";

type BannerListRendererProps = {
  bannerList?: BannerData[];
};

export const BannerListRenderer: React.FC<BannerListRendererProps> = ({ bannerList = [] }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [boundingBoxHeight, setBoundingBoxHeight] = React.useState<number | null>(null);
  const hadBanners = React.useRef<boolean>(!!bannerList.length);

  React.useEffect(() => {
    if (!ref.current) return;
    const boundingBox = ref.current.getBoundingClientRect();
    setBoundingBoxHeight(boundingBox.height);

    if (bannerList.length) hadBanners.current = true;
  }, [bannerList.length]);

  // only render component if there are banners to render, or if banners were recently dismissed (to allow transition to complete)
  if (!bannerList?.length && !hadBanners.current) return null;

  return (
    <StyledStack sx={{ height: boundingBoxHeight, transition: "height 0.15s cubic-bezier(0.4, 0, 0.2, 1)" }}>
      <Stack ref={ref} gap="2px">
        {bannerList.map((banner) => (
          <Banner
            key={banner.id}
            {...banner}
          />
        ))}
      </Stack>
    </StyledStack>
  );
};
