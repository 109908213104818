import { MENU_ITEM_IDS } from "./constants";
import type { TMenuItem } from "_global/components/Buttons/MenuButton";
import type { VR360ComponentProps } from "features/DesignStudio/VR360IframeApp";

const mapMenuItemToAction = (item: TMenuItem, options: VR360ComponentProps) => {
  switch (item.id) {
    case MENU_ITEM_IDS.saveNew:
      return options.onSaveNewDesign;
    case MENU_ITEM_IDS.overwriteCurrent:
      return options.onOverwriteSavedDesign;
    case MENU_ITEM_IDS.downloadSnapshot:
      return options.onDownloadSnapshot;
    default:
      return () => undefined;
  }
};

export const getSavedDesignMenuItems = (options: VR360ComponentProps, menuItems: TMenuItem[]): TMenuItem[] => {
  let _menuItems = [...menuItems];

  const hasSavedDesign = !!options.savedDesign?.id;
  const shouldHideWhenNoDesign = (id?: string) => id === MENU_ITEM_IDS.overwriteCurrent || id === MENU_ITEM_IDS.manageDesign;
  if (!hasSavedDesign) _menuItems = menuItems.filter((item) => !shouldHideWhenNoDesign(item.id));

  return _menuItems.map((item) => ({
    ...item,
    onClick: mapMenuItemToAction(item, options),
    disabled: options.isLoading
  }));
};

export const formatDateTimeDisplay = (date: string) => {
  // format: "Jan 1, 2021, 12:00 PM"
  const dateObj = new Date(date);
  return dateObj.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};
