import * as React from "react";
import { Stack } from "@mui/system";
import { Switch } from "@mui/base";
import { HelpText, getAttributesForHelpText } from "../HelpText";
import { StyledLabel, StyledRoot } from "./ToggleSwitch.elements";
import type { TToggleSwitchFieldProps} from "../types";

export const ToggleSwitch: React.FC<TToggleSwitchFieldProps> = ({
  id,
  label,
  onChange,
  checked,
  helpText,
  invalid = false,
  ...inputProps
}) => {

  const helpTextDisplay = React.useMemo(() => {
    if (helpText) return helpText;
    const defaultInvalidText = "Invalid field.";
    return invalid ? defaultInvalidText : "";
  }, [helpText, invalid]);

  const { helpTextId, ariaDescribedByProps } = React.useMemo(() => (
    getAttributesForHelpText(id, helpTextDisplay)
  ), [id, helpTextDisplay]);

  return (
    <Stack gap="2px">
      <StyledLabel disabled={inputProps.disabled}>
        <Switch
          id={id}
          slots={{
            root: StyledRoot,
          }}
          {...inputProps}
          {...ariaDescribedByProps}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </StyledLabel>
      {!!helpTextDisplay && (
        <HelpText id={helpTextId} error={invalid}>
          {helpTextDisplay}
        </HelpText>
      )}
    </Stack>
  );
};
