export type TCoBuyer = {
  firstname: string;
  lastname: string;
  email: string;
};

export type THomeDetail = {
  lot: number;
  address: string;
  coordinates: string;
  model: string;
  name?: string; // TODO: update when API is updated
  heroImage: string;
};

export const TPhaseNames = {
  ["Foundation Stage"]: "Foundation Stage",
  ["Framing Stage"]: "Framing Stage",
  ["Rough-In Stage"]: "Rough-In Stage",
  ["Drywall Stage"]: "Drywall Stage",
  ["Surfaces Stage"]: "Surfaces Stage",
  ["Finishes Stage"]: "Finishes Stage",
  ["Quality Assurance Stage"]: "Quality Assurance Stage",
  ["Completion"]: "Completion",
 } as const;

export const TPhaseStatuses = {
  ["Completed"]: "Completed",
  ["In Progress"]: "In Progress",
  ["Not Started"]: "Not Started",
} as const;

export type TPhaseKey = keyof typeof TPhaseNames;
export type TPhaseStatus = keyof typeof TPhaseStatuses;

type TConstructionPhase = {
  name: TPhaseKey;
  status: TPhaseStatus;
};

export type TPhaseResponseDTO = TConstructionPhase[];

export type TContractedHome = {
  id: number;
  jobId: string;
  hubspotDealId: string;
  projectedMoveIn: string;
  closeDate: string;
  coBuyers: TCoBuyer[];
  constructionPhases: TPhaseResponseDTO;
  homeDetail: THomeDetail;
};

export type TContractedHomesDTO = TContractedHome[];
