import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetUser } from "../hooks/useGetUser";
import { useUpdateUser } from "../hooks/useUpdateUser";

import { isWayfinderGeneratedUserName } from "../utils";
import type { User } from "_global/types";
import type { UpdateUserMutationFunc } from "../hooks/useUpdateUser";
import { AxiosError } from "axios";

export const WayfinderUserControllerContext =
  React.createContext<IWayfinderUserControllerContextValue>({
    isLoading: true,
    isFetching: true,
    isError: false,
    error: null,
    isUpdateSuccess: false,
    updateUser: () => undefined,
    wayfinderUser: null,
  });

type WayfinderUserControllerContextProviderProps = {
  children: React.ReactNode;
};

interface IWayfinderUserControllerContextValue {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: AxiosError | null;
  isUpdateSuccess: boolean;
  wayfinderUser: User | null;
  updateUser: UpdateUserMutationFunc;
}

export const WayfinderUserControllerContextProvider: React.FC<
  WayfinderUserControllerContextProviderProps
> = ({ children }) => {
  const auth0 = useAuth0();
  const { setUser } = useGlobalContext();

  const shouldGetUser = auth0.isAuthenticated && !auth0.isLoading;

  const { data, isFetching, isLoading, isError, error } = useGetUser({
    enabled: shouldGetUser,
  });

  const {
    mutate: updateUser,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useUpdateUser();

  const wayfinderUser = React.useMemo(() => {
    if (!data) return null;

    const firstName = data.firstName ?? "";
    const lastName = data.lastName ?? "";
    const isWayfinderGeneratedName = isWayfinderGeneratedUserName({
      firstName,
      lastName,
    });

    if (isWayfinderGeneratedName)
      return { ...data, firstName: "", lastName: "" };

    return { ...data, firstName, lastName };
  }, [data]);

  React.useEffect(() => {
    if (wayfinderUser) setUser(wayfinderUser);
  }, [wayfinderUser, setUser]);

  return (
    <WayfinderUserControllerContext.Provider
      value={{
        wayfinderUser,
        updateUser,
        isUpdateSuccess,
        isError: isError || isUpdateError,
        error: error || null,
        isFetching,
        isLoading: isLoading || isUpdateLoading,
      }}
    >
      {children}
    </WayfinderUserControllerContext.Provider>
  );
};
