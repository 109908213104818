import * as React from "react";
import { Stack } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { useNotificationOptInFormState } from "./hooks/useNotificationOptInFormState";

import { Tile } from "_global/components/Containers";
import { SaveButton } from "features/Account/Profile/components/StyledFormElements";
import { NotificationsOptInForm } from "./NotificationsOptInForm/NotificationsOptInForm";
import { NotificationPrefsController, type TNotificationPrefViewProps } from "./controllers/NotificationPrefsController";

const NotificationsFormWrapper: React.FC<TNotificationPrefViewProps> = ({
  data: notificationPrefsDTO,
  isLoading,
  isFetching,
  isUpdateLoading,
  updateNotificationPrefs,
}) => {
  const { showSuccessToast } = useToastRenderer();
  const formState = useNotificationOptInFormState({
    notificationPrefsDTO,
    onSubmit: updateNotificationPrefs,
  });

  const { isDirty, isInvalid, handleSubmit } = formState;

  const isSubmitDisabled = !isDirty || isInvalid || isUpdateLoading || isFetching;
  const onSuccess = () => showSuccessToast({ message: "Notification Preferences Updated" });

  if (isLoading) {
    return (
      <Tile justifyContent="center" alignItems="center" sx={{ height: "200px", color: "#C9C9C9" }}>
        <CircularProgress color="inherit" />
      </Tile>
    );
  };

  return (
    <Stack component="form" gap="20px">
      <NotificationsOptInForm
        tileVariant="neutral"
        {...formState}
      />
      <SaveButton
        disabled={isSubmitDisabled}
        onClick={() => handleSubmit({ onSuccess })}
      />
    </Stack>
  );
};

export const ProfileNotificationPrefsForm = () => {
  return (
    <NotificationPrefsController component={NotificationsFormWrapper} />
  );
};
