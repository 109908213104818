import * as React from "react";
import { Box, Stack } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { DocumentHelpInfoDrawer } from "./DocumentHelpInfoDrawer";
import { DataGrid, ViewButton, InfoButton, StyledStatusText } from "./DocumentsDataGrid.elements";
import { formatDateDisplay, getFlatDocuments, getDocumentHelpInfo } from "../utils";
import type { TDocumentEnvelope, TFlatDocument, TDocumentHelpInfo } from "../types";

const {
  DocumentTitleCell,
  DesktopUpdatedAtCell,
  StatusCell,
  DesktopViewButtonCell,
} = DataGrid;

type DocumentsDataGridProps = {
  documentEnvelopes: TDocumentEnvelope[];
};

export const DocumentsDataGrid: React.FC<DocumentsDataGridProps> = ({ documentEnvelopes }) => {
  const [selectedHelpInfo, setSelectedHelpInfo] = React.useState<TDocumentHelpInfo | null>(null);

  const openHelpInfoModal = React.useCallback((helpInfo: TDocumentHelpInfo) => {
    setSelectedHelpInfo(helpInfo);
  }, []);

  const closeHelpInfoModal = () => setSelectedHelpInfo(null);

  const documentsList = React.useMemo(() => {
    return getFlatDocuments(documentEnvelopes);
  }, [documentEnvelopes]);

  return (
    <>
      <DataGrid.MainContainer>
        <HeaderRow />
        <DataGrid.DataRowList>
          {documentsList.map((document, i) => (
            <DataGrid.DataRow
              key={document.uid}
              isEven={i % 2 === 0}
              isLast={i === documentsList.length - 1}
            >
              <DocumentRowInner
                document={document}
                showHelpInfo={openHelpInfoModal}
              />
            </DataGrid.DataRow>
          ))}
        </DataGrid.DataRowList>
      </DataGrid.MainContainer>
      <DocumentHelpInfoDrawer
        open={!!selectedHelpInfo}
        onClose={closeHelpInfoModal}
        title={selectedHelpInfo?.title || ""}
        description={selectedHelpInfo?.description || ""}
      />
    </>
  );
};

type RowProps = {
  document: TFlatDocument;
  showHelpInfo: (helpInfo: TDocumentHelpInfo) => void;
};

const DocumentRowInner: React.FC<RowProps> = ({ document, showHelpInfo }) => {
  const dateDisplay = React.useMemo(() => formatDateDisplay(document.updated_at), [document.updated_at]);

  const status = `${document.status || ""}`; // precautionary string cast
  const isStatusDanger = status.toLowerCase() === "not signed";

  const documentHelpInfo = React.useMemo(() => getDocumentHelpInfo(document), [document]);

  const handleShowHelpInfo = React.useCallback(() => {
    if (documentHelpInfo) showHelpInfo(documentHelpInfo);
  }, [documentHelpInfo, showHelpInfo]);

  return (
    <>
      <DocumentTitleCell>
        <Box display="flex" alignItems="center" gap="8px">
          <Box>{document.name}</Box>
          {!!documentHelpInfo && <InfoButton onClick={handleShowHelpInfo} />}
        </Box>
        <Stack className="hide-in-large" flexDirection="row" alignItems="center">
          Updated {dateDisplay}
        </Stack>
        <Stack className="hide-in-large" flexDirection="row" alignItems="center">
          <ViewButton isMobile href={document.documentsUri} />
        </Stack>
      </DocumentTitleCell>
      <DesktopUpdatedAtCell>
        {dateDisplay}
      </DesktopUpdatedAtCell>
      <StatusCell>
        <StyledStatusText>
          <span className={isStatusDanger ? "danger" : ""}>{status}</span>
        </StyledStatusText>
      </StatusCell>
      <DesktopViewButtonCell>
        <ViewButton href={document.documentsUri} />
      </DesktopViewButtonCell>
    </>
  );
};

const HeaderRow = () => (
  <DataGrid.HeaderRow>
    <DocumentTitleCell>
      Name
    </DocumentTitleCell>
    <DesktopUpdatedAtCell>
      Updated
    </DesktopUpdatedAtCell>
    <StatusCell>
      Status
    </StatusCell>
    <DesktopViewButtonCell>
      <span style={{ ...visuallyHidden }}>View Button</span>
    </DesktopViewButtonCell>
  </DataGrid.HeaderRow>
);
