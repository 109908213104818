import * as React from "react";
import { visuallyHidden } from "@mui/utils";
import { Backdrop } from "_global/components/Backdrop/Backdrop";
import { Typography } from "../Typography/Typography";
import {
  CloseButton,
  FooterContainer,
  MainContentContainer,
  StyledContentContainer,
  StyledHeaderContainer,
  StyledModal
} from "./Modal.elements";

type HeaderProps = {
  id?: string;
  title: string;
  hideTitle?: boolean;
  onClose: () => void;
};

const Header: React.FC<HeaderProps> = ({ id, title, hideTitle, onClose }) => {
  const attrs = hideTitle ? { style: { ...visuallyHidden } } : {};
  return (
    <StyledHeaderContainer>
      <CloseButton onClose={onClose} />
      <Typography.H4 id={id} semanticTag="h3" sx={{ color: theme => theme.palette.v2.neutral[90] }} {...attrs}>
        {title}
      </Typography.H4>
    </StyledHeaderContainer>
  );
};

type ModalProps = {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  "aria-labelledby"?: string;
  size?: "small" | "medium" | "large";
};

const ModalWrapper: React.FC<ModalProps> = ({children, size = "medium", ...props}) => {
  const width = React.useMemo(() => {
    if (size === "small") return "500px";
    if (size === "medium") return "780px";
    if (size === "large") return "800px";
  }, [size]);

  return (
    <StyledModal
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: () => ({ open: props.open }) }}
      {...props}
    >
      <StyledContentContainer sx={{ width }} gap="30px">
        {children}
      </StyledContentContainer>
    </StyledModal>
  );
};

export const Modal = {
  Wrapper: ModalWrapper,
  Header,
  MainContentContainer,
  FooterContainer,
};
