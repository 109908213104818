import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useFormState } from "_global/form/hooks/useFormState";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useOnboardingApiController } from "../controllers/useOnboardingApiController";
import { useNotificationOptInFormState, emptyNotificationsFormState } from "features/Notifications/NotificationPrefs/hooks";

import { STEPPER_PAGES, STEPPER_PAGE_IDS } from "../pages/constants";
import { routes } from "features/Navigation/util/routes";
import { getUserInfoFormConfig, getShouldShowNotificationsPage } from "../util";
import { getEmptyFormState } from "_global/form/hooks/utils";
import type { UserInfoFormValues, IOnboardingControllerContextValue } from "../types";

export const OnboardingControllerContext = React.createContext<IOnboardingControllerContextValue>({
  userInfoForm: getEmptyFormState<UserInfoFormValues>(),
  notificationsOptInForm: emptyNotificationsFormState,
  currentPageIndex: 0,
  totalPages: 0,
  currentPage: STEPPER_PAGES[0],
  isLastPage: false,
  isLoading: false,
  isContinueDisabled: true,
  onClickContinue: () => undefined,
});

export const useOnboardingControllerContext = () => React.useContext(OnboardingControllerContext);

type OnboardingControllerContextProviderProps = {
  children: React.ReactNode;
};

export const OnboardingControllerContextProvider: React.FC<OnboardingControllerContextProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();

  /** Onboarding API Controller ====================== **/
  const {
    isLoading: isOnboardingLoading,
    notificationPrefsDTO,
    onSubmitUserInfoForm,
    onSubmitNotificationsOptInForm,
    onCompleteOnboarding,
  } = useOnboardingApiController();
  /** ================================================ **/

  /** Stepper Page management ======================== **/
  const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(0);
  const [shouldShowNotificationsOptInPage, setShouldShowNotificationsOptInPage] = React.useState<boolean>(false);

  const stepperPages = React.useMemo(() => {
    if (shouldShowNotificationsOptInPage) return STEPPER_PAGES;
    return STEPPER_PAGES.filter((page) => page.id !== STEPPER_PAGE_IDS.notifications);
  }, [shouldShowNotificationsOptInPage]);

  const totalPages = stepperPages.length;
  const currentPage = stepperPages[currentPageIndex];
  const isOnboardingCompletionPage = currentPageIndex === totalPages - 2;
  const isLastPage = currentPageIndex === totalPages - 1;

  const incrementPage = () => {
    if (!isLastPage) setCurrentPageIndex((prev) => prev + 1);
  };
  /** ================================================ **/

  /** User Info Form setup -- first page of onboarding **/
  const userInfoFormConfig = React.useMemo(() => getUserInfoFormConfig(user || null), [user]);
  const userInfoForm = useFormState<UserInfoFormValues>(userInfoFormConfig);
  const isUserInfoForm = React.useMemo(() => currentPage.id === STEPPER_PAGE_IDS.userInfo, [currentPage]);
  /** ================================================ **/
  
  /** Notifications OptIn Form setup -- second page of onboarding (for contracted users) **/
  const notificationsOptInForm = useNotificationOptInFormState({
    notificationPrefsDTO,
    onSubmit: onSubmitNotificationsOptInForm,
  });
  const isNotificationsOptInForm = React.useMemo(() => currentPage.id === STEPPER_PAGE_IDS.notifications, [currentPage]);
  /** ================================================ **/

  /** Handle Submit Methods, Etc ===================== **/
  const isContinueDisabled = [
    isUserInfoForm && userInfoForm.isInvalid,
    isNotificationsOptInForm && notificationsOptInForm.isInvalid,
  ].some(invalidCondition => !!invalidCondition);

  const handleSubmitUserInfoForm = React.useCallback(() => {
    const { homebuyerJourneyStage } = userInfoForm.values;
    const shouldShowNotificationsPage = getShouldShowNotificationsPage(homebuyerJourneyStage);

    const onSuccess = () => {
      setShouldShowNotificationsOptInPage(shouldShowNotificationsPage);
      incrementPage();
    };

    onSubmitUserInfoForm(userInfoForm.values, { onSuccess });
  }, [userInfoForm.values, onSubmitUserInfoForm, incrementPage]);

  const handleSubmitNotificationsOptInForm = React.useCallback(() => {
    notificationsOptInForm.handleSubmit({ onSuccess: incrementPage });
  }, [notificationsOptInForm, incrementPage]);

  const handleCompleteOnboarding = React.useCallback(() => {
    onCompleteOnboarding({ onSuccess: incrementPage });
  }, [onCompleteOnboarding, incrementPage]);

  const onClickContinue = React.useCallback(() => {
    if (isUserInfoForm) return handleSubmitUserInfoForm();
    if (isNotificationsOptInForm) return handleSubmitNotificationsOptInForm();
    if (isOnboardingCompletionPage) return handleCompleteOnboarding();
    if (isLastPage) return navigate(routes.dashboard.path);
    return incrementPage();
  }, [
    isUserInfoForm,
    isNotificationsOptInForm,
    isOnboardingCompletionPage,
    isLastPage,
    handleSubmitUserInfoForm,
    handleSubmitNotificationsOptInForm,
    handleCompleteOnboarding,
  ]);
  /** ================================================ **/

  return (
    <OnboardingControllerContext.Provider
      value={{
        userInfoForm,
        notificationsOptInForm,
        isLoading: !user || isOnboardingLoading,
        currentPage,
        currentPageIndex,
        totalPages,
        isLastPage,
        onClickContinue,
        isContinueDisabled,
      }}
    >
      {children}
    </OnboardingControllerContext.Provider>
  );
};
