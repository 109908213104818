import * as React from "react";
import { Button as BaseButton } from "@mui/base";
import { Box, styled } from "@mui/system";

const StyledCloseButton = styled(BaseButton)(({ theme }) => {


  return {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    justifySelf: "flex-end",
    backgroundColor: theme.palette.grey[200],
    borderRadius: "4px",
    border: "none",
    width: "40%",
    minWidth: "60px",
    height: "7px",
    cursor: "pointer",
    "&:hover&:not(:disabled)": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: .3,
    },
  };
});

export type CloseButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({
  disabled = false,
  onClick,
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", padding: "10px 0" }}>
      <StyledCloseButton
        aria-label="Close Drawer"
        title="Close"
        disabled={disabled}
        onClick={onClick}
      />
    </Box>
  );
};
