import * as React from "react";
import { Box } from "@mui/system";

import { useFormState } from "_global/form/hooks/useFormState";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { isBlank, isValidEmail } from "_global/form/validation";

import type { TTasksViewProps } from "features/Tasks/controllers/TasksController";
import type { TCreateTaskDTO } from "features/Tasks/hooks/useCreateTask";
import type { FieldConfig, FormConfig } from "_global/form/hooks/types";

import { TextInput } from "_global/components/Inputs";
import { FormContainer, SubmitButton, SuccessMessage } from "./MatchingSupportForm.elements";

const FIELD_KEYS = {
  emailField: "unverifiedEmail",
} as const;

const { emailField } = FIELD_KEYS;

const formConfig: FormConfig = [
  {
    id: emailField,
    initialValue: "",
    validations: [
      (value: string) => isBlank(value) ? "Email cannot be blank." : "",
      (value: string) => !isValidEmail(value) ? "Email is invalid." : "",
    ],
  } as FieldConfig<string>,
] as FormConfig;

export const MatchingSupportForm: React.FC<TTasksViewProps> = ({
  isLoading,
  isSuccess,
  createTask,
}) => {

  const { showSuccessToast } = useToastRenderer();

  const {
    values,
    errors,
    isDirty,
    isInvalid,
    handleBlur,
    handleChange,
  } = useFormState<TCreateTaskDTO>(formConfig);

  const isSaveDisabled = React.useMemo(() => (
    !isDirty || isLoading || isInvalid
  ), [isDirty, isLoading, isInvalid]);

  const onSave = () => {
    const onSuccess = () => showSuccessToast({ message: "The Pahlisch team has been notified." });
    if (!isInvalid) createTask(values, { onSuccess });
  };

  return (
    <FormContainer>
      <TextInput
        tileVariant="info"
        id={emailField}
        label="Additional Email"
        readOnly={isSuccess}
        disabled={isLoading || isSuccess}
        invalid={!!errors[emailField]}
        value={values[emailField]}
        onChange={(e) => handleChange(emailField, e.target.value)}
        onBlur={() => handleBlur(emailField)}
        helpText={errors[emailField] || ""}
      />
      <Box display="flex" alignItems="center" minHeight="55px">
        {isSuccess ? (
          <SuccessMessage />
        ) : (
          <SubmitButton disabled={isSaveDisabled} onClick={onSave} />
        )}
     </Box>
    </FormContainer>
  );
};
