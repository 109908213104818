import { NavLink } from "react-router-dom";
import { Stack, Box, styled } from "@mui/system";
import { ReactComponent as DesignIcon } from "_global/assets/icons/nav/designIcon.svg";
import { ReactComponent as ProfileIcon } from "_global/assets/icons/nav/profileIcon.svg";
import { ReactComponent as TimelineIcon } from "_global/assets/icons/nav/timelineIcon.svg";

import { PageContainer } from "../components/PageContainer";
import { themeFonts } from "_global/theme/typography";
import { routes } from "features/Navigation/util/routes";

const StyledTitle = styled("span")(() => ({
  ...themeFonts.textLgSemibold,
}));

const StyledDescription = styled("span")(() => ({
  ...themeFonts.textLg,
}));

const ListLink = styled(Box)(({ theme }) => ({
  display: "flex",
  textDecoration: "unset",
  color: theme.palette.v2.bodyText,
  padding: "10px",
  paddingLeft: "20px",
  gap: "18px",
  borderRadius: "8px",
  transition: "background-color 0.05s ease-in-out",
  "& svg": {
    marginTop: "6px",
    color: theme.palette.v2.neutral[90],
    width: "100%",
  },
  "&:hover": {
    backgroundColor: theme.palette.v2.primary.surface,
    boxShadow: theme.palette.v2.shadow1,
    "& svg": {
      color: theme.palette.v2.primary.main,
    },
  },
  "@media screen and (max-width: 450px)": {
    marginLeft: "-10px",
    marginRight: "-10px",
  },
}));

const links = [
  {
    icon: <DesignIcon />,
    linkProps: {
      to: routes.designStudio.path,
      title: "Go to Design Studio",
    },
    title: "Try the design studio",
    description: "Personalize a Pahlisch home in our design studio.",
  },
  {
    icon: <TimelineIcon />,
    isExternalLink: false,
    linkProps: {
      to: routes.timeline.path,
      title: "Go to Your Way Home timeline page",
    },
    title: "Follow along on Your Way Home",
    description: "Track the progress of the construction of your new Pahlisch home and learn more about the process.",
  },
  {
    icon: <ProfileIcon />,
    linkProps: {
      to: routes.profile.path,
      title: "Go to Profile page",
    },
    title: "View your profile",
    description: "Update your account details, access your saved homes, and more.",
  },
];

export const NextSteps = () => {
  return (
    <PageContainer maxWidth="500px" paddingX="15px">
      {links.map((action) => (
        <ListLink component={action.isExternalLink ? "a" : NavLink} key={action.title} {...action.linkProps}>
          <Stack sx={{ minWidth: "50px" }}>
            {action.icon}
          </Stack>
          <Stack gap="4px">
            <StyledTitle>{action.title}</StyledTitle>
            <StyledDescription>{action.description}</StyledDescription>
          </Stack>
        </ListLink>
      ))}
    </PageContainer>
  );
};
