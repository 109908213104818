import { Stack } from "@mui/system";
import { DesignStudioCTACard } from "./DesignStudioCTACard";
import { SavedDesignsSection } from "./components/SavedDesignsSection";

export const DesignStudioController = () => {
  return (
    <Stack gap="50px">
      <DesignStudioCTACard />
      <SavedDesignsSection />
    </Stack>
  );
};
