import { Auth0ProviderWithNavigate } from "./components/Auth0ProviderWithNavigate";

type WrapperProps = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<WrapperProps> = ({ children }) => (
  <Auth0ProviderWithNavigate>
    {children}
  </Auth0ProviderWithNavigate>
);
