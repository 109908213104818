import * as React from "react";
import { Link } from "react-router-dom";
import { Box, styled } from "@mui/system";
import { Notifications as BellIcon } from "@mui/icons-material";
import { Tile } from "_global/components/Containers";
import { StatusIndicator } from "_global/components/StatusIndicator/StatusIndicator";
import { themeFonts } from "_global/theme/typography";

export const TILE_CLASSNAME = "name-display-tile";
export const TILE_CLASSNAME_CONTRACTED = "name-display-tile--contracted";

const StyledOuterContainer = styled(Tile)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "64px",
  width: "100%",
  minWidth: "100%",
  borderRadius: "16px",
  overflow: "unset",
  [`&.${TILE_CLASSNAME_CONTRACTED}`]: {
    borderRadius: "16px 16px 0px 0px", // 'contractedBuyer' tile is connected with HomeAddressTile in desktop
  },
  [theme.breakpoints.down("sm")]: {
    [`&.${TILE_CLASSNAME_CONTRACTED}`]: {
      borderRadius: "16px",
      padding: "0px 12px",
      marginLeft: "-12px",
      backgroundColor: theme.palette.white,
    },
  },
}));

const StyledNameLink = styled(Link)(({ theme }) => ({
  display: "inline-flex",
  textDecoration: "unset",
  color: theme.palette.v2.neutral[90],
  ...themeFonts.textMdSemibold,
  padding: "16px",
  margin: "-16px",
  "&:hover": {
    color: theme.palette.v2.primary.main,
  },
}));

const StyledNotificationLink = styled(Link)(({ theme }) => ({
  display: "inline-flex",
  textDecoration: "unset",
  color: theme.palette.v2.neutral[90],
  ...themeFonts.textMdSemibold,
  padding: "16px",
  margin: "-16px",
  "&:hover": {
    "& svg": {
    color: theme.palette.v2.primary.hover,
  },
  },
}));

const StyledNotificationWrapper = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: "6px",
  "& svg": {
    color: theme.palette.v2.primary.main,
    fontSize: "28px",
  },
}));

type LinkProps = {
  to: string;
  title: string;
};

type NotificationSectionProps = LinkProps & {
  shouldShowNotificationWarning?: boolean;
};

const NotificationSection: React.FC<NotificationSectionProps> = ({
  shouldShowNotificationWarning = false,
  ...linkProps
}) => {
  return (
    <StyledNotificationLink {...linkProps}>
      <StyledNotificationWrapper>
        {shouldShowNotificationWarning && <StatusIndicator diameter="10px" variant="warning" />}
        <BellIcon />
      </StyledNotificationWrapper>
    </StyledNotificationLink>
  );
};

export const StyledTile = {
  OuterContainer: StyledOuterContainer,
  NameSection: StyledNameLink,
  NotificationSection,
};
