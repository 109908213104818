import * as React from "react";
import { styled, Box, Stack } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { Tile } from "_global/components/Containers";
import { StatusChip } from "./StatusChip";
import { Image } from "_global/components/Images";
import { themeFonts } from "_global/theme/typography";

const DesktopContainerTile = styled(Tile)(({ theme }) => ({
  width: "100%",
  gap: "20px",
  minWidth: "500px",
  maxWidth: "700px",
  height: "100%",
  minHeight: "100%",
  maxHeight: "100%",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "80px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const DesktopTitle = styled(Box)(({ theme }) => ({
  ...themeFonts.headingH3,
  fontWeight: 400,
  color: theme.palette.v2.neutral[100],
}));

const Description = styled(Box)(({ theme }) => ({
  ...themeFonts.textLReading,
  marginTop: "8px",
  color: theme.palette.v2.neutral[100],
  textAlign: "center",
}));

type DesktopVideoIFrameProps = {
  src: string;
  title: string;
  width?: string;
  height?: string;
};

const VideoIFrame: React.FC<DesktopVideoIFrameProps> = ({ src, title, width = "100%", height = "auto" }) => (
  <iframe
    width={width}
    height={height}
    frameBorder="0"
    src={src}
    allowFullScreen
    allow="autoplay; fullscreen; picture-in-picture" 
    title={title}
  />
);

type HeroImageProps = {
  src: string;
  alt: string;
  height?: string;
  width?: string;
};

const HeroImage: React.FC<HeroImageProps> = ({ src, alt, height = "280px", width = "100%" }) => (
  <Image
    src={src}
    alt={alt}
    height={height}
    width={width}
  />
);

export const DesktopSherpaContent = {
  Container: DesktopContainerTile,
  StatusChip,
  Title: DesktopTitle,
  Description,
  HeroImage,
  VideoIFrame,
};

const StyledSherpaContentMobileContainer = styled(Stack)(() => ({
  gap: "20px",
  width: "100%",
  paddingBottom: "40px",
}));

const StyledMobileTitle = styled(Box)(({ theme }) => ({
  ...themeFonts.headingH4,
  color: theme.palette.v2.neutral[90],
  textAlign: "center",
}));

export const MobileSherpaContent = {
  Container: StyledSherpaContentMobileContainer,
  Title: StyledMobileTitle,
  Description,
  HeroImage,
  VideoIFrame,
};

export const SherpaContentDesktopLoading = () => (
  <DesktopContainerTile minHeight="400px">
    <Stack alignItems="center" justifyContent="center" sx={{ height: "400px", color: "#C9C9C9" }}>
      <CircularProgress color="inherit" />
    </Stack>
  </DesktopContainerTile>
);
