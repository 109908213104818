import * as React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { CAROUSEL_ITEM_CLASSNAME } from "_global/components/Carousel";
import { Card as ListCard, CarouselCard } from "./TimelineCard.elements";
import type { TPhaseStatus } from "features/ContractedHomes/types";
import type { TPhaseInfo } from "../constants";

type TimelineCardProps = {
  title: string;
  carousel?: boolean;
  status: TPhaseStatus;
  thumbnail: TPhaseInfo["thumbnail"];
  active?: boolean;
  onClick?: () => void;
};

export const TimelineCard = ({
  title,
  status,
  thumbnail,
  active = false,
  carousel = false,
  onClick = () => undefined,
}: TimelineCardProps) => {
  const { user } = useGlobalContext();
  const isContractedBuyer = user?.isContractedInHubspot;
  const shouldHideStatus = !isContractedBuyer;
  const ref = React.useRef<HTMLButtonElement>(null);

  const Card = React.useMemo(() => (carousel ? CarouselCard : ListCard), [carousel]);

  React.useEffect(() => {
    if (active && ref.current) {
      // scroll to active card in carousel
      if (carousel) ref.current.scrollIntoView({ block: "end", inline: "center", behavior: "auto" });
    };
  }, [carousel, active]);
  
  return (
    <Card.Container
      ref={ref}
      active={active}
      aria-label={`View details for ${title}`}
      onClick={onClick}
      className={carousel ? CAROUSEL_ITEM_CLASSNAME : undefined}
    >
      <Card.Thumbnail icon={thumbnail} status={status} />
      <Card.Content>
        {!shouldHideStatus && <Card.StatusChip status={status}>{status}</Card.StatusChip>}
        <Card.Title className="title">{title}</Card.Title>
      </Card.Content>
    </Card.Container>
  );
};
