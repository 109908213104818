import * as React from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { getToastMessages } from "../constants";

/**
 * This hook triggers a password reset email to be sent to the user's email address via the Auth0 Authentication API: https://auth0.com/docs/authenticate/database-connections/password-change#authentication-api
 */

const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_DB_NAME = "Username-Password-Authentication";

export const useResetPassword = () => {
  const { user } = useGlobalContext();
  const { showErrorToast, showToast } = useToastRenderer();

  const [loading, setLoading] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState<string | React.ReactNode>("");

  const auth0 = useAuth0();
  const auth0User = auth0?.user;
  const auth0Email = auth0User?.email || "";

  const isSocialAuth = user?.isSocialAuth || false;
  
  const isVerifiedEmail = auth0User?.email_verified || false;

  const TOAST_MESSAGES = getToastMessages(auth0Email);

  const canUserResetPassword = React.useMemo(() => (
    !!user && !!auth0Email && !isSocialAuth
  ), [user, auth0User, isSocialAuth]);

  const options = {
    method: "POST",
    url: `https://${AUTH0_DOMAIN}/dbconnections/change_password`,
    headers: { "content-type": "application/json" },
    data: {
      client_id: AUTH0_CLIENT_ID,
      email: auth0Email,
      connection: AUTH0_DB_NAME,
    },
  };

  const onClickResetPassword = async () => {
    setLoading(true);
    setDisplayMessage("");
    try {
      if (!canUserResetPassword) {
        // users should not be shown 'Reset Password' link at all in this scenario, this is just a fallback protection
        throw new Error("Cannot reset password", { cause: isSocialAuth ? "social identity" : "missing email"});
      }

      const response = await axios.request(options);
      if (response) {
        showToast(TOAST_MESSAGES.sendResetEmailSuccess);
        setDisplayMessage(TOAST_MESSAGES.sendResetEmailSuccess.message);
      }
    } catch (error) {
      showErrorToast(TOAST_MESSAGES.sendResetEmailError);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    auth0Email,
    displayMessage,
    isVerifiedEmail,
    canUserResetPassword,
    isResetPasswordLoading: loading,
    onClickResetPassword,
  };
};
