import { Box, styled } from "@mui/system";
import type { ThemedTemplateVariants } from "_global/theme/types";

type StatusIndicatorProps = {
  variant?: ThemedTemplateVariants;
  diameter?: string;
};

const options = {
  shouldForwardProp: (prop: string) => !["variant"].includes(prop),
};

export const StatusIndicator = styled(Box, options)<StatusIndicatorProps>(({
  theme,
  diameter = "6px",
  variant = "warning",
}) => ({
  width: diameter,
  height: diameter,
  borderRadius: "50%",
  backgroundColor: theme.palette.v2[variant].main,
}));
