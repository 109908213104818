import type { CTALinkProps } from "./types";

import ShakingOnDeal from "_global/assets/images/Orientation-Thumbnail-2.png";
import BrickPorch from "_global/assets/images/ProDash_CTA3_FrontPorch.png";
import RunningFaucet from "_global/assets/images/Utility-Thumbnail.png";

type TLinkListSectionProps = {
  sectionTitle: string;
  description: string;
  linkList: CTALinkProps[];
};

const LINK_LIST: CTALinkProps[] = [
  {
    text: "Intro to Homeowner Orientation",
    thumbnailSrc: ShakingOnDeal,
    thumbnailAlt: "Explore Homeowner Orientation",
    isExternalLink: true,
    to: "https://pahlischhomes.com/homeowner/orientation?utm_source=wayfinder&utm_medium=contracted+dashboard&utm_campaign=wayfinder",
  },
  {
    text: "Find Your Utility Providers",
    thumbnailSrc: RunningFaucet,
    thumbnailAlt: "Find Utility Providers",
    isExternalLink: true,
    to: "https://pahlischhomes.com/utilities?utm_source=wayfinder&utm_medium=contracted+dashboard&utm_campaign=wayfinder",
  },
  {
    text: "Welcome to Our Front Porch",
    thumbnailSrc: BrickPorch,
    thumbnailAlt: "Explore Front Porch Blog",
    isExternalLink: true,
    to: "https://pahlischhomes.com/blog?utm_source=wayfinder&utm_medium=contracted+dashboard&utm_campaign=wayfinder"
  },
];

export const SECTION_VALS: TLinkListSectionProps = {
  sectionTitle: "This Way To Discover More!",
  description: "Here are some resources to help you prepare for your new Pahlisch home.",
  linkList: LINK_LIST,
};
