import { EmailOutlined } from "@mui/icons-material";
import { SectionTile } from "_global/components/Containers";
import { AccountEmailForm } from "./AccountEmailForm";

const TITLE = "Account Email";

export const AccountEmailFormSection = () => {
  return (
    <SectionTile.Container>
      <SectionTile.Header
        title={TITLE}
        icon={EmailOutlined}
      />
      <AccountEmailForm />
    </SectionTile.Container>
  );
};
