import { useApiClient } from "_global/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import type { User, MutationCallbackOptions } from "_global/types";
import { QUERY_KEYS } from "_global/constants";

export type UpdateUserNameFormValues = {
  firstName: string;
  lastName: string;
};

export type UpdateUserMutationFunc = (
  variables: UpdateUserNameFormValues,
  options?: MutationCallbackOptions,
) => void;

interface IUpdateUserMutationDTO extends UpdateUserNameFormValues {
  id: User["id"];
}

export const useUpdateUser = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { user } = useGlobalContext();
  const { showErrorToast } = useToastRenderer();

  const updateUser = async (
    variables: UpdateUserNameFormValues,
  ): Promise<User> => {
    if (!user) throw new Error("No user found.");
    const { firstName, lastName } = variables;

    const dto: IUpdateUserMutationDTO = {
      firstName,
      lastName,
      id: user.id,
    };

    return await apiClient.put("/users", dto);
  };

  const {
    mutate,
    isError,
    isSuccess,
    isPending: isLoading,
  } = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.user] });
    },
    onError: (err) => {
      console.error("useUpdateUser: onError", err);
      showErrorToast({ message: "Could not update user information." });
    },
  });

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
  };
};
