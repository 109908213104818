import * as React from "react";
import { Box, styled } from "@mui/system";
import { type TPhaseStatus, TPhaseStatuses as PHASE_STATUSES } from "features/ContractedHomes/types";
import { themeFonts } from "_global/theme/typography";

type StatusChipProps = {
  status: TPhaseStatus;
};

export const StatusChip = styled(Box, {
  shouldForwardProp: (prop: string) => !["status"].includes(prop),
})<StatusChipProps>(({ theme, status }) => {
  const { palette } = theme;

  const backgroundColor = React.useMemo(() => {
    const colors = {
      [PHASE_STATUSES["Completed"]]: palette.v2.neutral[80],
      [PHASE_STATUSES["In Progress"]]: palette.v2.success.main,
      [PHASE_STATUSES["Not Started"]]: palette.v2.secondary.main,
    };
    return colors[status] || palette.v2.neutral[80];
  }, [status]);

  return {
    padding: theme.spacing(0.2,1.5),
    borderRadius: "18px",
    maxWidth: "max-content",
    backgroundColor,
    ...themeFonts.textSmBold,
    color: palette.v2.neutral[10],
  };
});
