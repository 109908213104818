import {
  ButtonListSection as ReadyToStartYourJourney,
  DesignStudioCTACard,
  LinkListSection as MoreWaysToExplore,
} from "../CTASections";

import { SavedHomesSection } from "../FeatureSections";
import { DashboardHeader } from "../components/Header/DashboardHeader";
import { PageLayoutGrid as Layout } from "_global/views/Layout/PageLayoutGrid";

export const ProspectiveBuyerDashboard = () => (
  <Layout.GridContainer>
    <Layout.GridItem fullRow debugId={5}>
      <DashboardHeader />
    </Layout.GridItem>
    <Layout.GridItem large debugId={1}>
      <DesignStudioCTACard />
    </Layout.GridItem>
    <Layout.GridItem small debugId={2}>
      <ReadyToStartYourJourney />
    </Layout.GridItem>
    <Layout.GridItem large debugId={3}>
      <SavedHomesSection />
    </Layout.GridItem>
    <Layout.GridItem small debugId={4}>
      <MoreWaysToExplore />
    </Layout.GridItem>
  </Layout.GridContainer>
);
