import * as React from "react";
import { useCarouselContext, CarouselContextProvider } from "./contexts/CarouselContext";
import { ScrollButtonsCore } from "./components/ScrollButtonsCore";
import { ScrollContainerCore } from "./components/ScrollContainerCore";

type CarouselContainerProps = {
  children: React.ReactNode;
};

const ScrollButtons = () => {
  const { scrollLeft, scrollRight, leftDisabled, rightDisabled } = useCarouselContext();
  return (
    <ScrollButtonsCore
      scrollLeft={scrollLeft}
      scrollRight={scrollRight}
      leftDisabled={leftDisabled}
      rightDisabled={rightDisabled}
    />
  );
};

const ScrollContainer = ({ children }: CarouselContainerProps) => {
  const { carouselInstance, initCarouselInstance, handleScroll } = useCarouselContext();
  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (ref.current && !carouselInstance) {
      initCarouselInstance(ref.current);
    }
  }, [initCarouselInstance, carouselInstance]);

  return (
    <ScrollContainerCore
      ref={ref}
      onScroll={handleScroll}
    >
      {children}
    </ScrollContainerCore>
  );
};

export const Carousel = {
  ContextWrapper: CarouselContextProvider,
  ScrollButtons,
  ScrollContainer,
};
