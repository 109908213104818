import { StyledCard as Card } from "./CTACard.elements";
import { SectionTile } from "_global/components/Containers";
import { SECTION_VALS as vals } from "./constants";

export const DesignStudioCTACard: React.FC = () => {
  return (
    <SectionTile.Container gap="24px" maxWidth="800px">
      <SectionTile.Header title={vals.sectionTitle} />
      <Card.OuterContainer>
        <Card.ContentContainer>
          <Card.Thumbnail src={vals.thumbnailUrl} alt={vals.thumbnailAlt} />
          <Card.StyledText>{vals.cardText}</Card.StyledText>
        </Card.ContentContainer>
        <Card.ButtonLink to={vals.linkTo} label={vals.buttonLabel} />
      </Card.OuterContainer>
    </SectionTile.Container>
  );
};
