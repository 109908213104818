import { FIELD_KEYS } from "../../NotificationsOptInForm/constants";
import type { User } from "_global/types";
import type { TNotificationPrefsDTO } from "../../types";
import type { TNotificationOptInFormValues } from "./types";

const { email, phone, sms } = FIELD_KEYS;

export const getInitFormValuesFromDTO = (notificationPrefs: TNotificationPrefsDTO | undefined = [], user: User | null): TNotificationOptInFormValues => {
  const dto = Array.isArray(notificationPrefs) ? notificationPrefs : []; // should be an array, but just in case

  const [initEmail, initPhone, initSms] = [email, phone, sms].map((fieldKey) => (
    dto.find(({ type }) => type === fieldKey)
  ));

  return {
    [email]: {
      enabled: initEmail?.optedIn || false,
      val: initEmail?.contactInfo || user?.email || "",
    },
    [phone]: {
      enabled: initPhone?.optedIn || false,
      val: initPhone?.contactInfo || "",
    },
    [sms]: {
      enabled: initSms?.optedIn || false,
      val: initSms?.contactInfo || "",
    },
  };
};

// remove all non-numeric characters (phone number should be pre-validated)
const formatPhoneNumberForApi = (phoneNumber: string): string => phoneNumber.replace(/\D/g, "");

export const transformFormValuesToDTO = (formValues: TNotificationOptInFormValues): TNotificationPrefsDTO => ([
  {
    type: email,
    optedIn: formValues[email].enabled,
    contactInfo: formValues[email].val,
  },
  {
    type: phone,
    optedIn: formValues[phone].enabled,
    contactInfo: formatPhoneNumberForApi(formValues[phone].val),
  },
  {
    type: sms,
    optedIn: formValues[sms].enabled,
    contactInfo: formatPhoneNumberForApi(formValues[sms].val),
  },
]);
