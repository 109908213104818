import * as React from "react";
import { Box, styled } from "@mui/system";
import { getSkeletonAnimationStyles } from "_global/theme/styleUtils";

type ImageContainerProps = {
  width?: string;
  height?: string;
  skeleton?: boolean;
  borderRadius?: string | number;
};

const options = {
  shouldForwardProp: (prop: string) => !["height", "width", "skeleton"].includes(prop),
};

export const ImageContainer = styled(Box, options)<ImageContainerProps>(({
  theme,
  width = "auto",
  height = "auto",
  skeleton,
  borderRadius = 0,
}) => {

  const animationStyles = React.useMemo(() => {
    if (!skeleton) return { animation: "none" };
    return getSkeletonAnimationStyles();
  }, [skeleton]);

  return {
    width,
    height,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius,
    overflow: "hidden",
    backgroundColor: skeleton ? theme.palette.grey[200] : "transparent",
    ...animationStyles,
  };
});
