import { Button as BaseButton } from "@mui/base";
import { Stack, styled } from "@mui/system";
import { ACTIVE_CLASS } from "./constants";
import { themeFonts } from "_global/theme/typography";

// TODO: add support for icons

export const StyledTabContainer = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "3px",
  maxWidth: "max-content",
}));

const fontStyles = themeFonts.textMdSemibold;

export const StyledTab = styled(BaseButton)(({ theme }) => {
  const { palette } = theme;
  const backgroundColor = theme.palette.v2.neutral[10];
  const getActiveBoxShadow = (color: string) => `0px -3px 0px 0px ${color} inset`;

  return {
    alignSelf: "stretch",
    display: "inline-flex",
    padding: "14px 8px",
    alignItems: "center",
    gap: "10px",
    backgroundColor,
    border: "none",
    textDecoration: "none",
    cursor: "pointer",
    color: palette.v2.bodyText,
    textAlign: "center",
    ...fontStyles,
    [`&.${ACTIVE_CLASS}`]: {
      boxShadow: getActiveBoxShadow(palette.v2.primary.main),
    },
    "&:hover&:not(:disabled)": {
      color: palette.v2.primary.hover,
      [`&:not(.${ACTIVE_CLASS})`]: {
        backgroundColor: palette.v2.primary.surface,
      },
    },
    "&:focus-visible": {
      zIndex: 1,
      outline: `3px solid ${palette.v2.primary.focus}`,
    },
    "&:disabled": {
      cursor: "not-allowed",
      color: palette.v2.neutral[60],
      [`&.${ACTIVE_CLASS}`]: {
        boxShadow: getActiveBoxShadow(palette.v2.neutral[30]),
      },
    },
    [theme.breakpoints.up("sm")]: {
      padding: "14px 16px",
    },
  };
});
