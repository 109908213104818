import { Box } from "@mui/system";
import ChevronLeft from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRight from "@mui/icons-material/ChevronRightOutlined";
import { IconButton } from "../../Buttons/IconButton";

type ScrollButtonsProps = {
  scrollLeft: () => void;
  scrollRight: () => void;
  leftDisabled: boolean;
  rightDisabled: boolean;
};

export const ScrollButtonsCore = ({
  scrollLeft,
  scrollRight,
  leftDisabled,
  rightDisabled,
}: ScrollButtonsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "8px",
      }}
    >
      <IconButton
        size="small"
        onClick={scrollLeft}
        icon={ChevronLeft}
        ariaLabel="Scroll left"
        disabled={leftDisabled}
      />
      <IconButton
        size="small"
        onClick={scrollRight}
        icon={ChevronRight}
        ariaLabel="Scroll right"
        disabled={rightDisabled}
      />
    </Box>
  );
};
