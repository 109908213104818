import * as React from "react";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import type { TContractedHomesDTO } from "../types";
import { QUERY_KEYS } from "_global/constants";

type UseGetContractedHomesOptions = {
  enabled?: boolean;
};

export const useGetContractedHomes = (
  options?: UseGetContractedHomesOptions,
): UseQueryResult<TContractedHomesDTO, Error> => {
  const { enabled = true } = options || {};
  const { showErrorToast } = useToastRenderer();

  const client = useApiClient();

  const queryState = useQuery<TContractedHomesDTO, Error>({
    enabled,
    queryKey: [QUERY_KEYS.contractedHomes, "get"],
    queryFn: async () => {
      const response = await client.get<TContractedHomesDTO>(
        "/contracted_homes",
      );
      return response.data;
    },
  });

  const { isError } = queryState;

  React.useEffect(() => {
    if (queryState.isError)
      showErrorToast({
        id: "err-fetch-ctr-home",
        message: "Error fetching contracted home info",
      });
  }, [isError]);

  return queryState;
};
