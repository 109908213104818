import type { TSavedDesign } from "features/DesignStudio/types";

const formatDate = (date: Date) => {
  return date.toLocaleDateString("en-US");
};

const formatTime = (date: Date) => {
  return date.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" });
};

export const getFormattedDateTime = (date: string) => {
  const d = new Date(date);
  const isInvalidateDate = isNaN(d.getTime());
  if (isInvalidateDate) return date;
  return `${formatDate(d)} at ${formatTime(d)}`;
};

export const sortSavedDesigns = (savedDesigns: TSavedDesign[]) => {
  return [...savedDesigns].sort((a, b) => {
    const aDate = new Date(a.updated_at);
    const bDate = new Date(b.updated_at);
    return bDate.getTime() - aDate.getTime();
  });
};

export const getLoadDesignHref = (id: TSavedDesign["id"]) => `/design?designId=${id}`;
