import type { CTALinkProps } from "./types";

import FriendlySalesReps from "_global/assets/images/OSCs-Aug2024-2.png";
import BrickPorch from "_global/assets/images/ProDash_CTA3_FrontPorch.png";
import HouseSky from "_global/assets/images/ProDash_CTA2_Tour-Model.png";

type TLinkListSectionProps = {
    sectionTitle: string;
    description: string;
    linkList: CTALinkProps[];
};

const LINK_LIST: CTALinkProps[] = [
    {
        text: "Our New Home Specialists Are Here To Help",
        thumbnailSrc: FriendlySalesReps,
        thumbnailAlt: "Contact a Pahlisch Agent",
        isExternalLink: true,
        to: "https://pahlischhomes.com/newhomespecialists?utm_source=wayfinder&utm_medium=prospective+dashboard&utm_campaign=wayfinder",
    },
    {
        text: "Tour a Model Home",
        thumbnailSrc: HouseSky,
        thumbnailAlt: "Tour a Pahlisch Home",
        isExternalLink: true,
        to: "https://pahlischhomes.com/tour-a-model?utm_source=wayfinder&utm_medium=prospective+dashboard&utm_campaign=wayfinder",
    },
    {
        text: "Meet Us on Our Front Porch",
        thumbnailSrc: BrickPorch,
        thumbnailAlt: "Explore Front Porch Blog",
        isExternalLink: true,
        to: "https://pahlischhomes.com/blog?utm_source=wayfinder&utm_medium=prospective+dashboard&utm_campaign=wayfinder"
    },
];

export const SECTION_VALS: TLinkListSectionProps = {
    sectionTitle: "This Way To Discover More!",
    description: "Here are some resources to help you on your journey to find the perfect Pahlisch home.",
    linkList: LINK_LIST,
};
