import * as React from "react";
import { Box } from "@mui/system";
import { CheckOutlined } from "@mui/icons-material";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { themeFonts } from "_global/theme/typography";

export const SuccessMessage = () => (
  <Box
    display="flex"
    alignItems="center"
    gap="8px"
    sx={{
      ...themeFonts.textLgSemibold,
      color: theme => theme.palette.v2.success.main
    }}
  >
    <CheckOutlined fontSize="small" /> The Pahlisch team has been notified.
  </Box>
);

type SubmitButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

export const SubmitButton: React.FC<SubmitButtonProps> = (props) => (
  <Box sx={{ opacity: props.disabled ? 0.7 : 1, width: 300 }}>
    <PrimaryButton
      type="submit"
      fullWidth
      label="Notify Pahlisch"
      {...props}
    />
  </Box>
);

type ContainerProps = {
  children: React.ReactNode;
};

export const FormContainer: React.FC<ContainerProps> = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    gap="8px"
    sx={{ maxWidth: 700 }}
    component="form"
    autoComplete="off"
  >
    {children}
  </Box>
);
