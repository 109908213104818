import * as React from "react";
import { Stack } from "@mui/system";

import { Divider, StyledListItem } from "./DrawerNav.elements";
import { DrawerNavItem } from "./DrawerNavItem";
import { List, ListItem } from "_global/components/List/List";
import { BottomDrawerWrapper } from "_global/components/Drawers/BottomDrawer";

import { NavRoute } from "../../../util/routes";
import { BOTTOM_BAR_HEIGHT } from "../../../util/constants";

import { DrawerContext } from "_global/components/Drawers/contexts/DrawerContext";
import { LogoutButton } from "./LogoutButton";

type DrawerNavMenuProps = {
  links: NavRoute[];
}

export const DrawerNavMenu: React.FC<DrawerNavMenuProps> = ({ links }) => {
  const { closeDrawer, open, transitionStatus } = React.useContext(DrawerContext);
  
  return (
    <BottomDrawerWrapper
      id="drawer-nav-menu"
      open={open}
      isClosing={transitionStatus.isClosing}
      bottom={BOTTOM_BAR_HEIGHT}
      zIndex={1}
      title="More"
      onClose={closeDrawer}
      maxHeight={`calc(100% - ${BOTTOM_BAR_HEIGHT}px)`}
    >
      <Stack component={List} divider={<Divider aria-hidden="true" />}>
        <StyledListItem component={ListItem}>
          <LogoutButton />
        </StyledListItem>
        {links.map((link) => (
          <StyledListItem component={ListItem} key={link.path}>
            <DrawerNavItem {...link} />
          </StyledListItem>
        ))}
      </Stack>
    </BottomDrawerWrapper>
  );
};
