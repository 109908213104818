import * as React from "react";
import { Stack } from "@mui/system";
import { InputRow, RowStack, StyledTextInput, Divider } from "../components/StyledFormElements";
import { SPACING_Y } from "../components/constants";
import type { TCoBuyer } from "features/ContractedHomes/types";

type CoBuyersDisplayProps = {
  coBuyers: TCoBuyer[];
  skeleton?: boolean;
};

export const CoBuyersList: React.FC<CoBuyersDisplayProps> = ({
  coBuyers,
  skeleton,
}) => {

  const commonInputProps = { skeleton, disabled: true, readOnly: true };

  return (
    <Stack
      justifyContent="space-around"
      gap={SPACING_Y}
      divider={<Divider />}
    >
      {coBuyers.map(({ firstname, lastname, email }) => {
        const key = [firstname, lastname, email].filter(Boolean).join("-");
        return (
          <RowStack key={key}>
            <InputRow>
              <StyledTextInput
                id={`${key}-firstname`}
                label="First Name"
                value={firstname}
                {...commonInputProps}
              />
              <StyledTextInput
                id={`${key}-lastname`}
                label="Last Name"
                value={lastname}
                {...commonInputProps}
              />
            </InputRow>
            <InputRow>
              <StyledTextInput
                id={`${key}-email`}
                label="Email"
                value={email}
                {...commonInputProps}
              />
            </InputRow>
          </RowStack>
        );
      })}
    </Stack>
  );
};
