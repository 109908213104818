import { styled } from "@mui/system";
import { themeFonts } from "_global/theme/typography";

type HelpTextProps = {
  error?: boolean;
};

const options = {
  shouldForwardProp: (prop: string) => !["error"].includes(prop),
};

export const HelpText = styled("span", options)<HelpTextProps>(({ theme, error }) => ({
  ...themeFonts.textSm,
  minHeight: themeFonts.textSm.lineHeight,
  color: error ? theme.palette.v2.danger.main : theme.palette.v2.neutral[70],
}));
