import * as React from "react";
import { Box } from "@mui/system";
import { Modal } from "_global/components/Modal";
import { EditDesignNameForm, type EditDesignNameFormProps } from "./EditDesignNameForm";

type Props = {
  open: boolean;
  onClose: () => void;
} & EditDesignNameFormProps;

export const EditDesignNameModal: React.FC<Props> = (props) => {
  const { open, onClose, savedDesign, isLoading, onSave } = props;

  return (
    <Modal.Wrapper
      open={open}
      onClose={onClose}
      size="small"
      aria-labelledby="edit-design-name-modal-title"
    >
      <Modal.Header
        id="edit-design-name-modal-title"
        title="Edit Design Name"
        onClose={onClose}
      />
      <Modal.MainContentContainer alignItems="center">
        <Box py="20px" sx={{ width: "100%" }}>
          <EditDesignNameForm
            savedDesign={savedDesign}
            isLoading={isLoading}
            onSave={onSave}
            onCancel={onClose}
          />
        </Box>
      </Modal.MainContentContainer>
    </Modal.Wrapper>
  );
};
