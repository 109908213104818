import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import { Image } from "_global/components/Images";
import { CAROUSEL_ITEM_CLASSNAME } from "_global/components/Carousel";
import { getSkeletonAnimationStyles } from "_global/theme/styleUtils";

const ANIMATE_DELETING_CLASS = "is-deleting";

// Nested Link dilemma: https://css-tricks.com/nested-links/
// Nested Link Solution: https://www.sarasoueidan.com/blog/nested-links/
const StyledCardContent = styled(Stack)(({ theme }) => ({
  minHeight: "370px",
  height: "100%",
  minWidth: "340px",
  width: "340px",
  padding: "10px",
  gap: "18px",
  fontFamily: "Open Sans, Arial, sans-serif",
  position: "relative",
  boxShadow: theme.palette.v2.shadow1,
  borderRadius: "8px",
  backgroundColor: theme.palette.white,
  "a": {
    position: "relative",
    zIndex: 1,
  },
  "button": {
    zIndex: 1,
  },
  ".propertyTitle": {
    "a": {
      position: "static",
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "&::before": {
        content: "''",
        position: "absolute",
        zIndex: 0,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      },
    },
  },
  [`&.${ANIMATE_DELETING_CLASS}`]: {
    ...getSkeletonAnimationStyles(),
  },
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  color: theme.palette.black,
}));

const StyledBody = styled(Stack)(({ theme }) => ({
  fontWeight: 300,
  fontSize: "12px",
  color: theme.palette.black,
  gap: "10px",
  padding: theme.spacing(0, 0.5),
}));

const StyledTitleRow = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "30px",
}));

const Thumbnail = ({ src, alt }: { src: string, alt: string }) => (
  <Image
    src={src}
    alt={alt}
    borderRadius="8px"
    height="196px"
    width="100%"
  />
);

const PropertyTypeChip = styled(Box)(({ theme }) => ({
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "10px",
  letterSpacing: "0.5px",
  padding: theme.spacing(0, 1),
  height: "17px",
  backgroundColor: theme.palette.white,
  borderRadius: "13px",
  border: `1px solid ${theme.palette.grey[400]}`,
  color: theme.palette.black,
}));

type CardContainerProps = {
  isDeleting?: boolean;
  children?: React.ReactNode;
  skeleton?: boolean;
};

const CardContainer: React.FC<CardContainerProps> = ({
  isDeleting = false,
  children = null,
}) => {

  const classNames = React.useMemo(() => {
    const classNames = [CAROUSEL_ITEM_CLASSNAME];
    if (isDeleting) classNames.push(ANIMATE_DELETING_CLASS);
    return classNames.join(" ");
  }, [isDeleting]);

  return (
    <StyledCardContent className={classNames}>
      {children}
    </StyledCardContent>
  );
};

type TitleRowProps = {
  title: string;
  link: string;
  isSold: boolean;
  propertyType: string;
};

const TitleRow = ({ title, link, isSold, propertyType }: TitleRowProps) => {
  const titleContent = React.useMemo(() => {
    const shouldLink = !isSold && !!link;
    if (!shouldLink) return title;
    return (
      <a href={link} title="Go to Pahlisch property page" target="_blank" rel="noreferrer">
        {title}
      </a>
    );
  }, [isSold, title, link]);

  return (
    <StyledTitleRow>
      <StyledTitle className="propertyTitle">
        {titleContent}
      </StyledTitle>
      {propertyType && <PropertyTypeChip>{propertyType}</PropertyTypeChip>}
    </StyledTitleRow>
  );
};

export const HomeCardSkeleton = () => <CardContainer skeleton />;

export const StyledCard = {
  Container: CardContainer,
  Thumbnail: Thumbnail,
  TitleRow: TitleRow,
  Title: StyledTitle,
  PropertyTypeChip: PropertyTypeChip,
  Body: StyledBody,
};
