import { SectionTile } from "_global/components/Containers/SectionTile/SectionTile";
import { ProfileNotificationPrefsForm } from "features/Notifications/NotificationPrefs/ProfileNotificationPrefsForm";
import { IndicatorAndIcon } from "./NotificationPrefsSection.elements";

const TITLE = "Notification Preferences";
const DESCRIPTION = "We send ongoing updates during the construction of your home. How should we stay in contact with you?";

export const NotificationPrefsSection = () => (
  <SectionTile.Container minWidth="350px">
    <SectionTile.Header
      title={TITLE}
      adornmentLeft={<IndicatorAndIcon />}
      description={DESCRIPTION}
    />
    <ProfileNotificationPrefsForm />
  </SectionTile.Container>
);
