import * as React from "react";
import { Toast } from "../Toast/Toast";
import { StyledStack } from "./ToastListRenderer.elements";
import { useGlobalToastState } from "../hooks/useGlobalToastState";

export const ToastListRenderer: React.FC = () => {
  const { toastList } = useGlobalToastState();

  if (!toastList.length) return null;

  return (
    <StyledStack spacing={1}>
      {toastList.map((toast) => (
        <Toast
          isStaticPosition
          key={toast.id}
          {...toast}
        />
      ))}
    </StyledStack>
  );
};
