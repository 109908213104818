import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";

import { List } from "_global/components/List/List";
import { PrimaryNavItem, ToggleExpandButton } from "./PrimaryNavItem";
import { StyledGridItem } from "./PrimaryNav.elements";

import type { NavRoute } from "../../util/routes";

type PrimaryNavMenuProps = {
  links: NavRoute[];
  isDrawerVisible: boolean;
}

export const PrimaryNavMenu: React.FC<PrimaryNavMenuProps> = ({
  links,
  isDrawerVisible,
}) => {
  const zIndex = isDrawerVisible ? 2 : "auto";
  return (
    <Grid container columns={5} component={List}>
      {links.map((link) => (
        <StyledGridItem key={link.path} xs={1} zIndex={zIndex} as="li">
          <PrimaryNavItem {...link} />
        </StyledGridItem>
      ))}
      <StyledGridItem zIndex={zIndex} xs={1} as="li">
        <ToggleExpandButton />
      </StyledGridItem>
    </Grid>
  );
};
