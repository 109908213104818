import * as React from "react";
import type { User } from "../types";
import { useGlobalToastStateCore } from "_global/components/Toast/hooks/useGlobalToastState";
import type { IGlobalToastStateCore } from "_global/components/Toast/hooks/useGlobalToastState";

export interface IGlobalContextValue {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  toastState: IGlobalToastStateCore;
};

export const GlobalContext = React.createContext<IGlobalContextValue>({
  user: null,
  setUser: () => undefined,
  toastState: {
    toastList: [],
    addToast: () => undefined,
    removeToast: () => undefined,
  },
});

export const useGlobalContext = () => React.useContext(GlobalContext);

type GlobalContextProviderProps = {
  children: React.ReactNode;
};

export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = React.useState<User | null>(null);
  const toastState = useGlobalToastStateCore();

  return (
    <GlobalContext.Provider
      value={{
        user,
        setUser,
        toastState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
