import type { ToastDataMap } from "_global/components/Toast/types";
import { EmailOutlined } from "@mui/icons-material";

export const getToastMessages = (email: string): ToastDataMap => ({
  sendResetEmailSuccess: {
    id: "reset-password-email-sent-success",
    message: `An email has been sent to ${email}.  Check your email to complete password reset.`,
    variant: "info",
    icon: EmailOutlined,
  },
  sendResetEmailError: {
    id: "reset-password-err",
    message: "An error occurred requesting a password reset.",
  },
});

export const WAYFINDER_SUPPORT_EMAIL = "wayfinder@pahlisch.com";
