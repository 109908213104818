import { styled, Stack } from "@mui/system";

export const StyledStack = styled(Stack)(({ theme }) => ({
  position: "fixed",
  width: "100vw",
  alignItems: "flex-end",
  gap: theme.spacing(1),
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 9999,
  maxHeight: "100vh",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    right: 0,
    alignItems: "center",
  },
}));
