type FontStyleCSSProperties = {
  fontFamily: string;
  fontWeight: number;
  fontSize: string;
  letterSpacing: string;
  lineHeight: string;
  fontStyle?: string;
};

type Sizes = "xs" | "s" | "m" | "l" | "xl";

export const fontFamily = "Open Sans, Arial, sans-serif";

const sizeDefaults: Record<Sizes, Omit<FontStyleCSSProperties, "fontWeight" | "fontFamily">> = {
  xs: {
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "normal",
  },
  s: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "normal",
  },
  m: {
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "-0.084px",
  },
  l: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.176px",
  },
  xl: {
    fontSize: "36px",
    lineHeight: "48px",
    letterSpacing: "-0.396px",
  },
};

const fontStyles = {
  "text-xs-regular": {
    ...sizeDefaults.xs,
    fontFamily,
    fontWeight: 400,
  },
  "text-s-regular": {
    ...sizeDefaults.s,
    fontFamily,
    fontWeight: 400,
  },
  "text-s-semibold": {
    ...sizeDefaults.s,
    fontFamily,
    fontWeight: 600,
  },
  "text-s-bold": {
    ...sizeDefaults.s,
    fontFamily,
    fontWeight: 700,
  },
  "text-m-regular": {
    ...sizeDefaults.m,
    fontFamily,
    fontWeight: 300,
  },
  "text-m-medium": {
    ...sizeDefaults.m,
    fontFamily,
    fontWeight: 500,
  },
  "text-m-semibold": {
    ...sizeDefaults.m,
    fontFamily,
    fontWeight: 600,
  },
  "text-m-bold": {
    ...sizeDefaults.m,
    fontFamily,
    fontWeight: 700,
  },
  "text-m-italic": {
    ...sizeDefaults.m,
    fontFamily,
    fontWeight: 400,
    fontStyle: "italic",
  },
  "text-m-extrabold": {
    ...sizeDefaults.m,
    fontFamily,
    fontWeight: 800,
  },
  "text-l-regular": {
    ...sizeDefaults.l,
    fontFamily,
    fontWeight: 300,
  },
  "text-l-medium": {
    ...sizeDefaults.l,
    fontFamily,
    fontWeight: 500,
  },
  "text-l-semibold": {
    ...sizeDefaults.l,
    fontFamily,
    fontWeight: 600,
  },
  "text-l-bold": {
    ...sizeDefaults.l,
    fontFamily,
    fontWeight: 700,
  },
  "text-l-reading": {
    ...sizeDefaults.l,
    fontFamily,
    fontWeight: 300,
    lineHeight: "30px",
  },
  "text-xl-regular": {
    ...sizeDefaults.xl,
    fontFamily,
    fontWeight: 400,
  },
  "subtitle-regular": {
    fontFamily,
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "-0.22px",
  },
  "subtitle-semibold": {
    fontFamily,
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "-0.22px",
  },
  "heading-eyebrow": {
    fontFamily,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.176px",
  },
  "heading-h1": {
    fontFamily,
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "60px",
    letterSpacing: "-0.792px",
  },
  "heading-h1-mobile": {
    fontFamily,
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "40px",
    letterSpacing: "-0.616px",
  },
  "heading-h2": {
    fontFamily,
    fontWeight: 300,
    fontSize: "50px",
    lineHeight: "55px",
    letterSpacing: "-1.1px",
  },
  "heading-h2-mobile": {
    fontFamily,
    fontWeight: 600,
    fontSize: "34px",
    lineHeight: "40px",
    letterSpacing: "-0.748px",
  },
  "heading-h3": {
    fontFamily,
    fontWeight: 300,
    fontSize: "28px",
    lineHeight: "36px",
    letterSpacing: "-0.588px",
  },
  "heading-h4": {
    fontFamily,
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "-.306px",
  },
  "heading-h5": {
    fontFamily,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.176px",
  },
  "heading-h6": {
    fontFamily,
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "-0.176px",
  },
};

const fontStyleVariants = {
  textXs: fontStyles["text-xs-regular"],
  textSm: fontStyles["text-s-regular"],
  textSmSemibold: fontStyles["text-s-semibold"],
  textSmBold: fontStyles["text-s-bold"],
  textMd: fontStyles["text-m-regular"],
  textMdMedium: fontStyles["text-m-medium"],
  textMdSemibold: fontStyles["text-m-semibold"],
  textMdBold: fontStyles["text-m-bold"],
  textMdItalic: fontStyles["text-m-italic"],
  textMdExtrabold: fontStyles["text-m-extrabold"],
  textLg: fontStyles["text-l-regular"],
  textLgMedium: fontStyles["text-l-medium"],
  textLgSemibold: fontStyles["text-l-semibold"],
  textLgBold: fontStyles["text-l-bold"],
  textLReading: fontStyles["text-l-reading"],
  textXl: fontStyles["text-xl-regular"],
  subtitle: fontStyles["subtitle-regular"],
  subtitleSemibold: fontStyles["subtitle-semibold"],
  headingEyebrow: fontStyles["heading-eyebrow"],
  headingH1: fontStyles["heading-h1"],
  headingH1Mobile: fontStyles["heading-h1-mobile"],
  headingH2: fontStyles["heading-h2"],
  headingH2Mobile: fontStyles["heading-h2-mobile"],
  headingH3: fontStyles["heading-h3"],
  headingH4: fontStyles["heading-h4"],
  headingH5: fontStyles["heading-h5"],
  headingH6: fontStyles["heading-h6"],
};

type ThemeFontKeys = keyof typeof fontStyleVariants;
type ThemeFontMap  = Record<ThemeFontKeys, FontStyleCSSProperties>;
export const themeFonts: ThemeFontMap = {...fontStyleVariants};

const themeFontKeyList: ThemeFontKeys[] = Object.keys(themeFonts) as ThemeFontKeys[];
export const themeFontKeys= Object.fromEntries(themeFontKeyList.map(key => [key, key])) as Record<ThemeFontKeys, ThemeFontKeys>;
