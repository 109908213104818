import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { QUERY_KEYS } from "_global/constants";
import type { User } from "_global/types";

interface UpdateIsOnboardedFields {
  isOnboarded: boolean;
}

interface UpdateIsOnboardedDTO extends UpdateIsOnboardedFields {
  id: User["id"];
}

export const useUpdateUserIsOnboarded = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { user } = useGlobalContext();
  const { showErrorToast } = useToastRenderer();

  const updateIsOnboardedStatus = async ({
    isOnboarded,
  }: UpdateIsOnboardedFields) => {
    if (!user) throw new Error("No user found.");

    const dto: UpdateIsOnboardedDTO = {
      id: user.id,
      isOnboarded,
    };

    return await apiClient.put("/users/onboarding", dto);
  };

  const {
    isPending: isLoading,
    isSuccess,
    isError,
    mutate,
  } = useMutation({
    mutationFn: updateIsOnboardedStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.user] });
    },
    onError: (err) => {
      console.error("useUpdateUserIsOnboarded: onError", err);
      showErrorToast({ message: "Could not update registration status." });
    },
  });

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};
