import * as React from "react";
import { HandshakeOutlined } from "@mui/icons-material";

import { SectionTile } from "_global/components/Containers";
import { CoBuyersList } from "./CoBuyersSection.elements";

import { useGlobalContext } from "_global/contexts/GlobalContext";
import { ContractedHomesController, type TContractedHomesViewProps } from "features/ContractedHomes/controllers/ContractedHomesController";

const TITLE = "Co-Buyers";
const DESCRIPTION = "Your co-buyer will be listed here. If there are any issues or this data looks incorrect, please contact Pahlisch.";

export const CoBuyersSectionInner: React.FC<TContractedHomesViewProps> = ({
  contractedHome,
  isLoading,
  isError,
}) => {
  
  const coBuyers = React.useMemo(() => (
    contractedHome?.coBuyers || []
  ), [contractedHome]);

  if (!coBuyers.length && !isError) return null;

  return (
    <SectionTile.Container>
      <SectionTile.Header
        title={TITLE}
        icon={HandshakeOutlined}
        description={DESCRIPTION}
      />
      <CoBuyersList
        skeleton={isLoading}
        coBuyers={coBuyers}
      />
    </SectionTile.Container>
  );
};

export const CoBuyersSection = () => {
  const { user } = useGlobalContext();
  const shouldShowContractedHomes = !!user && user.isContractedInHubspot;
  if (!shouldShowContractedHomes) return null;

  return <ContractedHomesController component={CoBuyersSectionInner} />;
};
