import * as React from "react";
import { Card } from "./NewsletterCTACard.elements";
import { TITLE, MAIN_CONTENT } from "./constants";
import type { TMarketingViewProps } from "../../controllers/MarketingController";

export const NewsletterCTACard: React.FC<TMarketingViewProps> = ({
  isLoading,
  isSubscribedToNewsletter = false,
  subscribeToNewsletter,
}) => {
  return (
    <Card.Container>
      <Card.Thumbnail />
      <Card.BodyContainer>
        <Card.TextContainer>
          <Card.Title>{TITLE}</Card.Title>
          <Card.BodyText>{MAIN_CONTENT}</Card.BodyText>
        </Card.TextContainer>
        <Card.ActionContainer>
          {isSubscribedToNewsletter ? (
            <Card.AlreadySubscribedDisplay />
          ) : (
            <Card.SubscribeButton skeleton={isLoading} onClick={subscribeToNewsletter} />
          )}
        </Card.ActionContainer>
      </Card.BodyContainer>
    </Card.Container>
  );
};
