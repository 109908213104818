import { MainLogo } from "./MainLogo";
import { MainLogoWhite } from "./MainLogoWhite";
import { BadgeLogo } from "./BadgeLogo";

type BrandLogo = {
  Main: typeof MainLogo;
  Badge: typeof BadgeLogo;
  White: typeof MainLogoWhite;
}

export const BrandLogo: BrandLogo = {
  Main: MainLogo,
  Badge: BadgeLogo,
  White: MainLogoWhite,
};
