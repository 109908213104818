import * as React from "react";
import {
  SavedDesignsDataGrid,
  ErrorState,
  EmptyState,
  LoadingState,
} from "./SavedDesignsDataGrid";
import { SectionContainer, SectionHeader } from "./SavedDesignsSection.elements";
import { SavedDesignsController, type TSavedDesignsViewProps } from "features/DesignStudio/controllers/SavedDesignsController";

const SavedDesignsListRenderer: React.FC<TSavedDesignsViewProps> = ({ savedDesigns, ...props }) => {
  const { isLoading, isError } = props;

  if (isLoading) return <LoadingState />;

  const isEmpty = !savedDesigns.length;
  const shouldShowErrorState = isError && isEmpty;

  if (shouldShowErrorState) return <ErrorState />;
  if (isEmpty) return <EmptyState />;

  return (
    <SavedDesignsDataGrid savedDesigns={savedDesigns} {...props} />
  );
};

export const SavedDesignsSection: React.FC = () => {
  return (
    <SectionContainer>
      <SectionHeader>Saved Designs</SectionHeader>
      <SavedDesignsController component={SavedDesignsListRenderer} />
    </SectionContainer>
  );
};
