import { Box, Stack, styled } from "@mui/system";
import { BrandLogo } from "_global/components/Logo";
import { themeFonts } from "_global/theme/typography";
import { PAHLISCH_SITE_URL } from "../constants";

const StyledWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "16px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "4px",
  },
}));

const StyledTitle = styled("h1")(({ theme }) => ({
  margin: 0,
  color: theme.palette.v2.neutral[100],
  ...themeFonts.textLgSemibold,
}));

const Logo = () => (
  <Box position="relative">
    <a href={PAHLISCH_SITE_URL} target="_blank" aria-label="Go to Pahlisch Homes website">
      <Box aria-hidden="true" sx={{ width: ["130px", "160px", "185px"], minWidth: "130px" }}>
        <BrandLogo.Main />
      </Box>
    </a>
  </Box>
);

export const Brand = () => (
  <StyledWrapper>
    <Logo />
    <StyledTitle>Design Studio</StyledTitle>
  </StyledWrapper>
);
