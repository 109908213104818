import * as React from "react";
import { Stack } from "@mui/system";
import {
  EmptyState,
  HeaderWrapper,
  ListSectionHeader,
  ViewAllHomesForSaleLink,
} from "./SavedHomesView.elements";
import { CardList } from "./HomeCardLists/CardLists";
import { Tile } from "_global/components/Containers";
import { getFilteredSavedHomeLists } from "../utils";
import type { TSavedHomesViewProps } from "../SavedHomesListingController";

export const SavedHomesView = ({
  isLoading,
  savedHomes,
  deleteSavedHome,
}: TSavedHomesViewProps) => {
  const {
    activeSavedHomes,
    soldSavedHomes,
  } = React.useMemo(() => (
    getFilteredSavedHomeLists(savedHomes)
  ), [savedHomes]);

  // loading state
  if (isLoading) {
    return <>Loading...</>;
  }

  // empty state
  if (!savedHomes?.length) {
    return <EmptyState />;
  }

  return (
    <Tile gap="40px" variant="lightTile">
      <Stack gap="20px">
        <HeaderWrapper>
          <ListSectionHeader
            title="Saved Homes"
            description="Your most recently saved homes."
          />
          <ViewAllHomesForSaleLink />
        </HeaderWrapper>
        <CardList.ActiveHomes savedHomes={activeSavedHomes} deleteSavedHome={deleteSavedHome} />
      </Stack>
      <Stack gap="20px">
        <ListSectionHeader title="Sold Homes" />
        <CardList.InactiveHomes
          savedHomes={soldSavedHomes}
          deleteSavedHome={deleteSavedHome}
        />
      </Stack>
    </Tile>
  );
};
