import { styled } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { TeamCardSkeleton } from "./TeamCard";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  flexDirection: "row",
  gap: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(4),
    flexDirection: "column",
    alignItems: "center",
  },
}));

const LoadingSkeletons = () => {
  const listLength = 6;
  const arr: string[] = Array.from(Array(listLength)).map((_, i) => `${i}`);

  return (
    <>
      {arr.map((key) => (
        <TeamCardSkeleton key={key} />
      ))}
    </>
  );
};

type ContainerProps = {
  children?: React.ReactNode;
  skeleton?: boolean;
};

export const CardListContainer = ({ children, skeleton }: ContainerProps) => (
  <StyledGrid container columns={{ xs: 1, sm: 2, lg: 3 }}>
    {skeleton ? <LoadingSkeletons /> : children}
  </StyledGrid>
);
