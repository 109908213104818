import * as React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetMarketing, useUpdateMarketing } from "../hooks";
import { TMarketingDTO } from "../types";
import { trackGAEvent } from "../../../../utils/google-analytics/google-analytics";

export type TMarketingViewProps = {
  data?: TMarketingDTO;
  isError: boolean;
  isLoading: boolean;
  isUpdateSuccess?: boolean;
  isSubscribedToNewsletter?: boolean;
  subscribeToNewsletter: () => void;
};

type MarketingControllerProps = {
  component: React.FC<TMarketingViewProps>;
};

export const MarketingController: React.FC<MarketingControllerProps> = ({
  component: Component,
}) => {
  const { user } = useGlobalContext();

  const { data, isError, isLoading, isFetching } = useGetMarketing({
    enabled: !!user,
  });

  const {
    mutate: updateMarketing,
    isError: isUpdateError,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdateMarketing();

  const isSubscribedToNewsletter = data?.optedIn || isUpdateSuccess;

  const subscribeToNewsletter = React.useCallback(() => {
    updateMarketing();
    trackGAEvent({
      category: "Wayfinder",
      action: "frontporch_subscribe",
    });
  }, [updateMarketing]);

  return (
    <Component
      data={data}
      isError={isError || isUpdateError}
      isLoading={isLoading || isUpdateLoading || isFetching}
      isUpdateSuccess={isUpdateSuccess}
      isSubscribedToNewsletter={isSubscribedToNewsletter}
      subscribeToNewsletter={subscribeToNewsletter}
    />
  );
};
