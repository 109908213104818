import * as React from "react";
import { Stack } from "@mui/system";
import { NotificationOptInField } from "./NotificationOptInField";
import { NOTIFICATION_FIELDS } from "./constants";
import type { TNotificationsOptInFormProps, TNotificationOptInFieldId as FieldId } from "./types";

export const NotificationsOptInForm: React.FC<TNotificationsOptInFormProps> = ({
  values,
  errors,
  initialValues,
  handleChange,
  handleBlur,
  resetField,
  tileVariant,
}) => {
  const onClickToggle = (id: FieldId) => {
    const isCurrentlyEnabled = values[id].enabled;
  
    if (isCurrentlyEnabled) {
      // reset field caches to initial value
      resetField(id);
  
      // make sure field is not enabled
      const nextVal = {
        enabled: false,
        val: initialValues[id].val,
      };
      handleChange(id, nextVal);
    } else {
      // enable field
      const nextVal = {
        ...values[id],
        enabled: !values[id].enabled,
      };
      handleChange(id, nextVal);
    };
  };

  const onChangeInput = (id: FieldId, val: string) => {
    const nextVal = {
      ...values[id],
      val,
    };
    handleChange(id, nextVal);
  };

  return (
    <Stack gap="20px" minWidth="200px" width="100%">
      {NOTIFICATION_FIELDS.map(({ id, ...fieldConstants }) => (
        <NotificationOptInField
          key={id}
          id={id}
          {...fieldConstants}
          enabled={values[id].enabled}
          inputValue={values[id].val}
          tileVariant={tileVariant}
          errorMessage={errors[id]}
          onClickToggle={() => onClickToggle(id)}
          onInputBlur={() => handleBlur(id)}
          onChangeInput={(e) => onChangeInput(id, e.target.value)}
        />
      ))}
    </Stack>
  );
};
