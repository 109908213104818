import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import { Button as BaseButton } from "@mui/base";
import { useLogout } from "features/Auth/hooks/useLogout";
import { themeFonts } from "_global/theme/typography";

/**
 * This is a layout component that is used to wrap the onboarding and verify email views --> in desktop views it's a background image with a centered card, in mobile views it's a centered card with a background color
 */

const StyledPageContainer = styled(Box)(({ theme }) => ({
  height: "100vh",
  maxHeight: "100vh",
  width: "100vw",
  maxWidth: "100vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    backgroundImage: "url(/assets/login-background.webp)",
    backgroundSize: "cover",
    backgroundPosition: "33.3% bottom",
    "&::after": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.v2.overlayBlue,
      zIndex: 1,
    },
  },
}));

const StyledInnerContainer = styled(Stack)(({ theme }) => ({
  gap: "0px",
  height: "100%",
  width: "100%",
  maxWidth: "100%",
  maxHeight: "100%",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.v2.neutral[10],
  zIndex: 2,
  ["@media screen and (max-width: 400px)"]: {
    padding: theme.spacing(2, 0.5),
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3, 2, 2.5),
    width: "85%",
    maxWidth: "1040px",
    height: "95vh",
    maxHeight: "900px",
    borderRadius: "20px",
  },
  [theme.breakpoints.up("md")]: {
    height: "85%",
    maxHeight: "85%",
  },
}));

const StyledLogoutButton = styled(BaseButton)(({ theme }) => ({
  ...themeFonts.textLg,
  fontSize: "14px",
  position: "absolute",
  bottom: "10px",
  right: "8px",
  zIndex: 10,
  backgroundColor: theme.palette.white,
  borderRadius: "8px",
  color: theme.palette.v2.colorTextLink,
  border: "1px solid transparent",
  fontWeight: 400,
  letterSpacing: ".4px",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.v2.primary.hover,
    border: `1px solid ${theme.palette.v2.primary.border}`,
  },
  [theme.breakpoints.up("sm")]: {
    right: "30px",
    bottom: "15px",
    fontSize: "16px",
  },
}));

const FloatingLogoutButton: React.FC = (props) => {
  const { handleLogout } = useLogout();
  return (
    <StyledLogoutButton onClick={handleLogout} {...props}>
      Log out
    </StyledLogoutButton>
  );
};

const InnerContainerNarrow = styled(StyledInnerContainer)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    maxWidth: "500px",
  },
}));

type ContainerProps = {
  children: React.ReactNode;
};

export const OnboardingViewContainer: React.FC<ContainerProps> = ({ children }) => {
  return (
    <StyledPageContainer>
      <StyledInnerContainer>
        {children}
        <FloatingLogoutButton />
      </StyledInnerContainer>
    </StyledPageContainer>
  );
};

export const VerifyEmailViewContainer: React.FC<ContainerProps> = ({ children }) => {
  return (
    <StyledPageContainer>
      <InnerContainerNarrow>{children}</InnerContainerNarrow>
    </StyledPageContainer>
  );
};
