import * as React from "react";
import { HomeTile } from "./HomeAddressTile.elements";
import { ContractedHomesController, type TContractedHomesViewProps } from "features/ContractedHomes/controllers/ContractedHomesController";
import type { THomeDetail } from "features/ContractedHomes/types";

const HomeAddressTileContent: React.FC<THomeDetail> = (props) => (
  <HomeTile.Container>
    <HomeTile.HeroImage src={props.heroImage} />
    <HomeTile.MainContent {...props} />
  </HomeTile.Container>
);

export const HomeAddressTileRenderer: React.FC<TContractedHomesViewProps> = ({
  isError,
  isLoading,
  contractedHome,
}) => {
  if (isError && !contractedHome?.homeDetail) return (
    <HomeTile.Empty message="Could not retrieve your home information." />
  );

  if (isLoading) return (
    <HomeTile.Loading />
  );

  if (!contractedHome?.homeDetail || (!contractedHome.homeDetail.heroImage && !contractedHome.homeDetail.address && !contractedHome.homeDetail.name)) return (
      <HomeTile.Container>
        <HomeTile.DefaultHeroImage />
        <HomeTile.DefaultContent />
      </HomeTile.Container>
  );

  return (
    <HomeAddressTileContent {...contractedHome.homeDetail} />
  );
};

export const HomeAddressTile: React.FC = () => (
  <ContractedHomesController component={HomeAddressTileRenderer} />
);
