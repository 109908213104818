import * as React from "react";
import { Stack } from "@mui/system";
import { Typography } from "_global/components/Typography/Typography";

export type HeaderProps = {
  title: string;
  description?: string | React.ReactNode;
}

export const PageHeader: React.FC<HeaderProps> = ({
  title,
  description,
}) => {
  return (
    <Stack sx={{ gap: "14px" }}>
      <Typography.H1>{title}</Typography.H1>
      {!!description && <Typography.Body>{description}</Typography.Body>}
    </Stack>
  );
};
