import { Box, styled } from "@mui/system";

type GradientDividerProps = {
  hide: boolean;
};

export const GradientDivider = styled("li")<GradientDividerProps>(({
  theme,
  hide,
}) => {
  const { palette } = theme;

  const baseStyles = {
    height: "0px",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "0 0 1.5px 0",
  };

  if (hide) {
    return {
      ...baseStyles,
      borderColor: "transparent",
    };
  }
  
  return {
    ...baseStyles,
    borderImage: `linear-gradient(to left, ${palette.sidebar.main}, ${palette.grey[100]}, ${palette.grey[300]}) 1`,
  };
});

export const StyledListItem = styled(Box)(() => ({
  "&:focus-within": {
    zIndex: 1, // keeps focused outline above sibling elements
  },
}));
