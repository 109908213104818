import * as React from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { EditDesignNameModal } from "../../EditDesignName/EditDesignNameModal";
import { SavedDesignsListBottomDrawer, SAVED_DESIGNS_DRAWER_ID } from "./SavedDesignsListBottomDrawer";
import {
  DataGrid,
  EditButton,
  DeleteButton,
  LoadDesignButton,
  MenuButtonMobile,
} from "./SavedDesignsDataGrid.elements";

import { getFormattedDateTime, sortSavedDesigns } from "./utils";
import type { TSavedDesign } from "features/DesignStudio/types";
import type { TSavedDesignsViewProps } from "features/DesignStudio/controllers/SavedDesignsController";

const {
  DesignTitleCell,
  DesktopButtonCell,
  DesktopUpdatedAtCell,
  MobileMenuCell,
} = DataGrid;

export const SavedDesignsDataGrid: React.FC<TSavedDesignsViewProps> = ({
  savedDesigns,
  ...props
}) => {
  const sortedSavedDesigns = React.useMemo(() => (
    sortSavedDesigns(savedDesigns)
  ), [savedDesigns]);

  return (
    <DataGrid.MainContainer>
      <HeaderRow />
      <DataGrid.DataRowList>
        {sortedSavedDesigns.map((design, i) => (
          <SavedDesignDataRow
            key={design.id}
            isEven={i % 2 === 0}
            isLast={i === savedDesigns.length - 1}
            design={design}
            {...props}
          />
        ))}
      </DataGrid.DataRowList>
    </DataGrid.MainContainer>
  );
};

type RowProps = Omit<TSavedDesignsViewProps, "savedDesigns"> & {
  design: TSavedDesign;
  isEven?: boolean;
  isLast?: boolean;
};

const SavedDesignDataRow: React.FC<RowProps> = ({
  design,
  updateSavedDesign,
  deleteSavedDesign,
  ...props
}) => {
  const [isBeingDeleted, setIsBeingDeleted] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showBottomDrawer, setShowBottomDrawer] = React.useState(false);

  const openDrawer = () => setShowBottomDrawer(true);
  const closeDrawer = () => setShowBottomDrawer(false);
  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => setShowEditModal(false);

  const dateDisplay = React.useMemo(() => getFormattedDateTime(design.updated_at), [design.updated_at]);

  const handleDelete = () => {
    setIsBeingDeleted(true);
    const onError = () => setIsBeingDeleted(false);
    deleteSavedDesign(design, {onError});
  };

  const handleEditName = (name: string) => {
    updateSavedDesign({
      id: design.id,
      configId: design.configId,
      name,
    }, { onSuccess: closeEditModal });
  };

  return (
    <>
      <DataGrid.DataRow isEven={props.isEven} isLast={props.isLast} sx={{ opacity: isBeingDeleted ? 0.2 : 1 }}>
        <DesignTitleCell>
          <Box display="flex" gap="6px">
            <EditButton onClick={openEditModal} />
            <Box overflow="hidden" textOverflow="ellipsis" sx={{ fontWeight: { xs: 600, sm: 300 }}}>
              {design.name}
            </Box>
          </Box>
          <span className="hide-in-large">Updated {dateDisplay}</span>
        </DesignTitleCell>
        <DesktopUpdatedAtCell>
          {dateDisplay}
        </DesktopUpdatedAtCell>
        <DesktopButtonCell>
          <LoadDesignButton designId={design.id} disabled={isBeingDeleted} />
        </DesktopButtonCell>
        <DesktopButtonCell>
          <DeleteButton onClick={handleDelete} disabled={isBeingDeleted} />
        </DesktopButtonCell>
        <MobileMenuCell>
          <MenuButtonMobile
            aria-expanded={showBottomDrawer}
            aria-controls={SAVED_DESIGNS_DRAWER_ID}
            onClick={openDrawer}
          />
        </MobileMenuCell>
      </DataGrid.DataRow>
      <SavedDesignsListBottomDrawer
        open={showBottomDrawer}
        onClose={closeDrawer}
        savedDesign={design}
        onDelete={handleDelete}
        onClickEditName={() => {
          closeDrawer();
          openEditModal();
        } }
      />
      <EditDesignNameModal
        open={showEditModal}
        onClose={closeEditModal}
        onSave={handleEditName}
        savedDesign={design}
        isLoading={props.isLoading}
      />
    </>
  );
};

const HeaderRow = () => (
  <DataGrid.HeaderRow>
    <DesignTitleCell>
      <span>Name</span>
    </DesignTitleCell>
    <DesktopUpdatedAtCell>
      <span>Last Updated</span>
    </DesktopUpdatedAtCell>
    <DesktopButtonCell>
      <span style={{ ...visuallyHidden }}>Load Design</span>
    </DesktopButtonCell>
    <DesktopButtonCell>
      <span style={{ ...visuallyHidden }}>Delete Design</span>
    </DesktopButtonCell>
    <MobileMenuCell>
      <span style={{ ...visuallyHidden }}>Expand options</span>
    </MobileMenuCell>
  </DataGrid.HeaderRow>
);
