import * as React from "react";
import { NavLink, useMatch } from "react-router-dom";
import { Box } from "@mui/system";

import { StyledLinkContainer } from "./DrawerNav.elements";
import { DrawerContext } from "_global/components/Drawers/contexts/DrawerContext";

import type { NavRoute } from "../../../util/routes";

export const DrawerNavItem: React.FC<NavRoute> = ({
  path,
  label,
  icon,
}) => {
  const match = useMatch(path);
  const { closeDrawer } = React.useContext(DrawerContext);

  const className = match ? "active" : "";

  const Icon = icon;

  return (
    <NavLink
      to={path}
      aria-label={`Go to ${label}`}
      onClick={closeDrawer}
      style={{ textDecoration: "none" }}
    >
      <StyledLinkContainer className={className}>
        <Box className="iconContainer">
          <Icon fontSize="medium" />
        </Box>
        {label}
      </StyledLinkContainer>
    </NavLink>
  );
};
