import type { ToastRendererOptions } from "_global/components/Toast/types";

export const pahlischHomesForSaleUrl = "https://pahlischhomes.com/homes-for-sale?utm_source=wayfinder&utm_medium=prospective+dashboard&utm_campaign=wayfinder";

type ToastDataMap = Record<string, ToastRendererOptions>;

export const TOAST_DATA: ToastDataMap = {
 fetchError: {
    id: "svh-err-fetch",
    message: "Could not get saved homes",
  },
};
