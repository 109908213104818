import { Box, styled } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid";

export const StyledNavItem = styled(Box)(({ theme }) => {
  const { palette } = theme;
  const borderOffset = 8;

  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(0.5),
    width: "100%",  
    color: palette.v2.neutral[100],
    backgroundColor: palette.bottomBar.main,
    fontWeight: 400,
    letterSpacing: ".5px",
    fontSize: "12px",
    height: "80px",
    textDecoration: "none",
    fontFamily: "Open Sans, Arial, sans-serif",
    borderWidth: `0 0 ${borderOffset}px 0`,
    borderStyle: "solid",
    margin: 0,
    padding: 0,
    cursor: "pointer",
    textAlign: "center",
    "&.active": {
      backgroundColor: palette.sidebar.active.main,
      color: palette.sidebar.active.contrastText,
      fontWeight: 600,
      borderWidth: `0 0 ${borderOffset}px 0`,
      borderColor: palette.sidebar.active.dark,
      letterSpacing: "0px",
    },
    "&:hover&:not(.active)": {
      color: palette.sidebar.active.contrastText,
      fontWeight: 600,
      letterSpacing: "0px",
    },
    "&:not(.active)": {
      borderColor: "transparent",
    },
    ".iconContainer": {
      height: "34px",
      width: "34px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "svg": {
      fontSize: "24px",
    },
  };
});

type StyledGridItemProps = {
  zIndex: number | string;
};

const options = {
  shouldForwardProp: (prop: string) => prop !== "zIndex",
};

export const StyledGridItem = styled(Grid, options)<StyledGridItemProps>(({ zIndex }) => {
  let focusZIndex = 1;

  if (typeof zIndex === "number") {
    focusZIndex = zIndex + 1;
  }

  return {
    zIndex,
    "&:focus-within": {
      zIndex: focusZIndex,// keeps focused outline above sibling elements
    },
  };
});
