import { useMutation } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import type { User } from "_global/types";
import type { THomebuyerJourneyStage } from "../types";

export type HomebuyerJourneyStageFormValue = {
  homebuyerJourneyStage: THomebuyerJourneyStage;
};

type UpdateHomebuyerJourneyDTO = HomebuyerJourneyStageFormValue & {
  id: User["id"];
};

export const useUpdateHomebuyerJourneyStage = () => {
  const apiClient = useApiClient();
  const { user } = useGlobalContext();
  const { showErrorToast } = useToastRenderer();

  const updateHomebuyerJourneyStage = async ({
    homebuyerJourneyStage,
  }: HomebuyerJourneyStageFormValue) => {
    if (!user) throw new Error("No user found.");

    const dto: UpdateHomebuyerJourneyDTO = {
      id: user.id,
      homebuyerJourneyStage,
    };

    return await apiClient.put("/users/journey_stage", dto);
  };

  const {
    isPending: isLoading,
    isSuccess,
    isError,
    mutate,
  } = useMutation({
    mutationFn: updateHomebuyerJourneyStage,
    onError: (err) => {
      console.error("useUpdateHomebuyerJourneyStage: onError", err);
      showErrorToast({ message: "Could not update user homebuyer status." });
    },
  });

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};
