import { Stack, styled } from "@mui/system";
import { PageHeader } from "features/PageHeader";
import { TeamListingController } from "features/TeamListing/TeamListing";

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: "80px",
  [theme.breakpoints.down("sm")]: {
    gap: "40px",
  },
}));

export const Team = () => {
  return (
    <StyledStack>
      <PageHeader title="Team" />
      <TeamListingController />
    </StyledStack>
  );
};
