import * as React from "react";
import { MenuButton } from "_global/components/Buttons/MenuButton";
import { MENU_ITEMS } from "../constants";
import { getSavedDesignMenuItems } from "../utils";
import type { VR360ComponentProps } from "features/DesignStudio/VR360IframeApp";

export const SavedDesignMenuButton: React.FC<VR360ComponentProps> = (props) => {
  const menuItems = React.useMemo(() => getSavedDesignMenuItems(props, MENU_ITEMS), [props]);
  return (
    <MenuButton
      size="small"
      disabled={props.isLoading}
      label="Save Design"
      menuItems={menuItems}
    />
  );
};
