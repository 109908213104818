import { WindowSize } from "../hooks/useWindowResize";

export const breakpoints = {
  xs: 0, // phone
  sm: 600, // tablet
  md: 900, // small laptop
  lg: 1200, // desktop
  xl: 1536, // large screen
};

export const isMobile = (window: WindowSize): boolean => (window.width || 0) <= breakpoints.sm;

export const isTablet = (window: WindowSize): boolean => (window.width || 0) > breakpoints.sm && (window.width || 0) <= breakpoints.md;

export const isDesktop = (window: WindowSize): boolean => (window.width || 0) > breakpoints.md;
